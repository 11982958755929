export const GET_FEATURE_LIST_REQUEST = "GET_FEATURE_LIST_REQUEST";
export const GET_FEATURE_LIST_SUCCESS = "GET_FEATURE_LIST_SUCCESS";
export const GET_FEATURE_LIST_FAIL = "GET_FEATURE_LIST_FAIL";
export const GET_FEATURE_LIST_RESET = "GET_FEATURE_LIST_RESET";

export const SELECT_FEATURE_FROM_FEATURE_LIST = "SELECT_FEATURE_FROM_FEATURE_LIST";

export const GET_FEATURE_DETAILS_REQUEST = "GET_FEATURE_DETAILS_REQUEST";
export const GET_FEATURE_DETAILS_SUCCESS = "GET_FEATURE_DETAILS_SUCCESS";
export const GET_FEATURE_DETAILS_FAIL = "GET_FEATURE_DETAILS_FAIL";
export const GET_FEATURE_DETAILS_RESET = "GET_FEATURE_DETAILS_RESET";

export const SUBMIT_FEATURE_REQUEST = "SUBMIT_FEATURE_REQUEST";
export const SUBMIT_FEATURE_SUCCESS = "SUBMIT_FEATURE_SUCCESS";
export const SUBMIT_FEATURE_FAIL = "SUBMIT_FEATURE_FAIL";
