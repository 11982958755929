import "./style.css";

import React, { Suspense, useState } from "react";

import TasksApproveEditorModal from "../TasksApproveEditorModal";
import { replaceByStorageURL } from "../../shared/helpers";
import { useSelector } from "react-redux";

const TestListing = React.lazy(() =>
  import("../../pages/Inspection/InspectionDetails/TestRecommended/TestListing")
);

export default function ImageBoxTest({
  role,
  image_number,
  selectedTaskNumber,
  selectedTask,
  insp_id,
  imageData,
  approvedImg,
  setrefreshlinkTasks,
}) {
  const [imageEditorState, setimageEditorState] = useState(false);

  const checkDisable = useSelector((state) => state.inspectionDisable.disable);
  console.log(imageData, image_number, "==============**********");

  return (
    <div
      className={`mb-3 d-flex justify-content-between `}
      style={{
        paddingBottom: "1.6rem",
        textAlign: "center",
      }}
    >
      <div className="pt-5">
        <div className="test-img-container">
          {imageData?.image_url ? (
            <img
              src={`${replaceByStorageURL(
                imageData?.image_url
              )}?${new Date().getTime()}`}
              key={`${imageData?.image_url}?${new Date().getTime()}`}
              onClick={() => {
                !checkDisable
                  ? setimageEditorState(true)
                  : setimageEditorState(false);
              }}
              alt="test"
              onload="this.style.color='black'"
              onerror="this.style.color='black'"
              className="test-img-url"
              style={
                !checkDisable
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed" }
              }
            />
          ) : (
            <img
              alt="status test"
              src={require("../../../assets/icons/dummy-image.png").default}
              width="365px"
              className="test-img-url"
              style={{
                border:
                  imageData?.status === "pending_2" && "1px solid #fed636",
              }}
            />
          )}
        </div>

        {imageData?.image_url && (
          <span
            className="image-text"
            style={{
              cursor: imageData?.status !== "approved" && "pointer",
              marginLeft:
                imageData?.status !== "rejected" &&
                imageData?.status !== "approved"
                  ? "10px"
                  : "-15px",
            }}
          >
            View Task
            {/* ({selectedTaskNumber}
            {String.fromCharCode(65 + image_number)}) */}
            <img
              src={require("../../../assets/icons/Group_80486@2x.png").default}
              className="approved-img-logo"
              alt="icon"
            />
          </span>
        )}
      </div>

      <Suspense fallback={null}>
        <TestListing
          role={role}
          setrefreshlinkTasks={setrefreshlinkTasks}
          selectedSubmittedImage={imageData}
          insp_id={insp_id}
          image_number={image_number}
          tasks={
            selectedTask?.images[image_number]?.test?.test && {
              value: selectedTask?.images[image_number]?.test?.test?.id,
              label: selectedTask?.images[image_number]?.test?.test?.name,
            }
          }
          imageData={imageData}
        />
      </Suspense>
      <Suspense fallback={null}>
        <TasksApproveEditorModal
          selectedSubmittedImage={imageData}
          role={role}
          setnotRootLevelRefresh={setrefreshlinkTasks}
          setrefreshlinkTasks={setrefreshlinkTasks}
          selectedSubmittedImageNumber={image_number}
          show={imageEditorState}
          onHide={() => setimageEditorState(false)}
        />
      </Suspense>
    </div>
  );
}
