import React, { useEffect, useState } from "react";

import CustomTooltip from "../../../../components/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageBoxTest from "../../../../components/ImageBoxTest/ImageBoxTest";
import Paginator from "../../../../components/Paginator/Paginator";
import ScrollBar from "react-perfect-scrollbar";
import SearchDropdown from "../../../../components/SelectDropdown";
import { Spinner } from "react-bootstrap";
import TaskListing from "./TaskListing";
import { checkTasks } from "../../../../shared/helpers";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { getObservationByRoomForTests } from "../../../../../services/TestService";
import { getRoomByInspectionTest } from "../../../../../services/ObservationService";
import { useParams } from "react-router-dom";

export default function TestRecommended({ userInfo }) {
  const { insp_id } = useParams();
  const [RoomName, setRoomName] = useState();
  const [selectedRoomId, setSelectedRoomId] = useState();
  const [loader, setLoader] = useState(false);
  const [approvedImg, setapprovedImg] = useState([]);
  // edit image

  // Tasks listing
  const [tasks, settasks] = useState();
  const [selectedTask, setselectedTask] = useState();
  const [selectedTaskIndex, setselectedTaskIndex] = useState(0);
  const [tasksPage, settasksPage] = useState(1);
  const [taskpageIndex, settaskpageIndex] = useState();
  const [selectedTaskNumber, setselectedTaskNumber] = useState();
  const [activePagetasks, setActivePagetasks] = useState(1);
  const [itemsCountPerPagetasks, setItemsCountPerPagetasks] = useState(0);
  const [totalItemsCounttasks, setTotalItemsCounttasks] = useState(0);
  const [refreshlinkTasks, setrefreshlinkTasks] = useState(false);

  // left side loader
  const [leftSideLoader, setleftSideLoader] = useState(false);

  // test recommended
  const [refreshIcon, setrefreshIcon] = useState(false);

  useEffect(() => {
    if (selectedRoomId) {
      setleftSideLoader(true);
      setLoader(true);

      getObservation(tasksPage);
    }
  }, [tasksPage]);

  useEffect(() => {
    if (selectedRoomId && refreshlinkTasks) {
      getObservation(tasksPage);
    }
  }, [refreshlinkTasks]);

  useEffect(() => {
    if (selectedRoomId) {
      setLoader(true);
      setleftSideLoader(true);
      settasksPage(1);
      setActivePagetasks(1);
      getObservation(1);
    }
  }, [selectedRoomId]);

  const refreshCondition = (task) => {
    if (task?.status === "approved" && task?.images) {
      let imgData = task?.images?.filter((img) => img?.status === "approved");

      setselectedTask({
        ...task,
        images: imgData,
      });
    } else {
      setselectedTask(task);
    }
  };
  const getObservation = (page) => {
    setrefreshIcon(true);
    getObservationByRoomForTests(page, userInfo?.role, insp_id, selectedRoomId)
      .then((res) => {
        settasks(res?.data);
        setItemsCountPerPagetasks(res?.data?.per_page);
        setTotalItemsCounttasks(res?.data?.total);
        setActivePagetasks(res?.data?.current_page);

        let statusApproved = res?.data?.data[selectedTaskIndex]?.images?.filter(
          (img) => img?.status === "approved"
        );
        setapprovedImg(statusApproved);
        if (refreshlinkTasks) {
          console.log("first one");
          refreshCondition(res?.data?.data[selectedTaskIndex]);
        } else {
          console.log("2nd one");

          setselectedTaskIndex(0);
          refreshCondition(res?.data?.data[0]);
          setselectedTaskNumber(res?.data?.from);
        }
        settaskpageIndex(res?.data?.from);
        // setShowTestEditModal(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoader(false);
        setleftSideLoader(false);
        setrefreshlinkTasks(false);
        setrefreshIcon(false);
      });
  };

  return (
    <React.Fragment>
      <div className="tab-child">
        <div className="leftside" style={{ padding: "15px 5px 5px 20px" }}>
          <div className="leftside-header">
            <div className="d-flex justify-content-between">
              <h4 className="mb-0">Rooms</h4>
              {selectedRoomId && (
                <CustomTooltip
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  text={"Refresh"}
                >
                  <div disabled={refreshIcon}>
                    <FontAwesomeIcon
                      className={`${
                        refreshlinkTasks && "refreshing-observation"
                      }`}
                      icon={faSync}
                      onClick={() => !refreshIcon && setrefreshlinkTasks(true)}
                      style={{
                        marginLeft: "0px",
                        cursor: !refreshIcon && "pointer",
                        marginRight: "8px",

                        fontSize: "20px",
                      }}
                    />
                  </div>
                </CustomTooltip>
              )}
            </div>

            <br />
            <div>
              <SearchDropdown
                placeHolder="Select Room / Item Type"
                apiCall={getRoomByInspectionTest}
                disabled={false}
                saveProp={"id"}
                testUI={true}
                user_role={userInfo?.role}
                apiCallPropsOne={insp_id}
                showProp={["name"]}
                onSelect={(ev) => {
                  setRoomName(ev?.label);
                  setselectedTask();
                  setSelectedRoomId(ev.value);
                }}
              />
            </div>
            <br />
            {selectedRoomId && checkTasks(tasks)?.length > 0 && (
              <div className="d-flex">
                <h4 className="center-div" style={{ height: "unset" }}>
                  Feature List
                </h4>
              </div>
            )}
          </div>

          <div style={{ marginTop: "8px" }}>
            <ScrollBar style={{ height: "auto" }}>
              <ul
                className={loader ? "ul-style list-spinner" : "ul-style"}
                style={{
                  height: "calc(100vh - 410px)",
                  padding: "0px 15px 0px 10px",
                }}
              >
                {loader ? (
                  <Spinner animation="border" variant="success" />
                ) : (
                  <TaskListing
                    testMode={false}
                    tasks={tasks}
                    setapprovedImg={setapprovedImg}
                    selectedTaskIndex={selectedTaskIndex}
                    taskpageIndex={taskpageIndex}
                    setselectedTaskNumber={setselectedTaskNumber}
                    setselectedTask={setselectedTask}
                    selectedTask={selectedTask}
                    setselectedTaskIndex={setselectedTaskIndex}
                  />
                )}
              </ul>
            </ScrollBar>
            <div>
              {tasks &&
                tasks?.data?.length !== 0 &&
                !loader &&
                checkTasks(tasks).length > 0 && (
                  <Paginator
                    activePage={activePagetasks}
                    itemsCountPerPage={itemsCountPerPagetasks}
                    totalItemsCount={totalItemsCounttasks}
                    onChange={(page) => {
                      settasksPage(page);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
        <ScrollBar
          className={`round-top ${!selectedTask && "d-center-div"}`}
          style={{
            height: "calc(100vh - 140px)",
            overflowY: "auto",
            width: "100%",
            background: "#F9F9F9 0% 0% no-repeat padding-box",
          }}
        >
          {leftSideLoader ? (
            <div className="d-center-div" style={{ height: "100%" }}>
              <Spinner animation="border" variant="success" />
            </div>
          ) : selectedTask ? (
            <div className="rightside" style={{ width: "100%" }}>
              <div className="rightsideinner">
                {}
                {selectedTask && checkTasks(tasks).length > 0 && (
                  <React.Fragment>
                    <div className="">
                      <span
                        style={{
                          font: "normal normal normal 28px Basis-Grotesque-Pro-Regular",
                        }}
                        className="protocol-top-text"
                      >
                        {`${RoomName}`}
                      </span>

                      <h4 className="mt-2">
                      Feature {selectedTaskNumber}: {selectedTask?.name}{" "}
                      </h4>
                    </div>

                    {selectedTask?.status !== null &&
                      selectedTask?.status !== "pending" && (
                        <div className="observation-details-section">
                          <div
                            style={{
                              maxHeight: "260px",
                            }}
                          >
                            <div>
                              {selectedTask?.images?.length !== 0 &&
                                selectedTask?.images?.map(
                                  (image, i) =>
                                    image?.image_url &&
                                    image?.status === "approved" &&
                                    image?.tests_recommended && (
                                      <>
                                        {i !== 0 && <hr />}
                                        <ImageBoxTest
                                          key={i}
                                          role={userInfo?.role}
                                          image_number={i}
                                          approvedImg={approvedImg}
                                          insp_id={insp_id}
                                          selectedTask={selectedTask}
                                          setrefreshlinkTasks={
                                            setrefreshlinkTasks
                                          }
                                          imageData={image}
                                          selectedTaskNumber={
                                            selectedTaskNumber
                                          }
                                        />
                                      </>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                  </React.Fragment>
                )}
              </div>
            </div>
          ) : (
            <img
              alt="notfound"
              src={
                require("../../../../../assets/icons/no-task-update.png")
                  .default
              }
              width="288px"
            />
          )}
        </ScrollBar>
      </div>
    </React.Fragment>
  );
}
