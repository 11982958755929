import { useState } from "react";

import { LOG_IN, SET_NOTIFICATIONS } from "../../../../store/actions";
import { useHistory, useLocation } from "react-router-dom";

import {
  loginRequest,
  profileRequest,
} from "../../../../services/auth-service";

import axios from "../../../../axios-defaults";

import { getFCMToken } from "../../../../services/firebaseSetup/firebase";
import { regexes } from "../../../shared/regexes";
import { useDispatch } from "react-redux";
import { roleNames } from "../../../shared/helpers";

export const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errors, seterrors] = useState({});
  const [FormSubmit, setFormSubmit] = useState(false);
  const location = useLocation();

  let history = useHistory();
  const dispatch = useDispatch();
  function topicOnMessageHandler(message) {
    dispatch({
      type: SET_NOTIFICATIONS,
      payload: true,
    });
  }
  const handleSubmit = (event) => {
    setFormSubmit(true);
    seterrors({});

    setErrorText("");

    if (email && password && !regexes.email.test(email)) {
      setErrorText("Please enter a valid email address");
    } else if (email && password && regexes.email.test(email)) {
      setErrorText("");

      setDisableLogin(true);
      loginRequest({
        email: email,
        password,
        device_name: "react",
        role: location.pathname.includes("product-owner")
          ? roleNames.superAdmin
          : location.pathname.includes("account-manager")
          ? roleNames.buAdmin
          : roleNames.bu_homeowner,
      })
        .then((resp) => {
          localStorage.setItem("access_token", resp.data.access_token);

          if (resp.data.access_token) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp.data.access_token}`;
          }

          return profileRequest();
        })
        .then((profile) => {
          if (profile) {
            dispatch({
              type: LOG_IN,
              payload: profile.data,
            });

            if (
              profile?.data?.flags?.password_changed === true ||
              !profile?.data?.flags?.hasOwnProperty("password_changed")
            ) {
              history.replace("/properties");
            } else {
              history.replace("/change-password");
            }
          }
          return getFCMToken(topicOnMessageHandler);
        })
        .catch((error) => {
          if (error?.response?.data?.error === "inactive_user") {
            setErrorText(error?.response?.data?.error_description);
          }
          if (error?.response?.data?.errors?.role) {
            setErrorText(error?.response?.data?.errors?.role);
          }
          if (error?.response?.status === 422) {
            seterrors(error?.response?.data?.errors);
          }
          if (error?.response?.status === 400) {
            setErrorText(error?.response?.data?.message);
          }
          setDisableLogin(false);
        });
    }
    event.preventDefault();
  };

  return [
    email,
    setEmail,
    password,
    setPassword,
    disableLogin,
    setDisableLogin,
    errorText,
    setErrorText,
    errors,
    seterrors,
    FormSubmit,
    setFormSubmit,
    location,
    handleSubmit,
  ];
};
