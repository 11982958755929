import {
  GET_FEATURE_LIST_FAIL,
  GET_FEATURE_LIST_REQUEST,
  GET_FEATURE_LIST_RESET,
  GET_FEATURE_LIST_SUCCESS,
  SELECT_FEATURE_FROM_FEATURE_LIST,
  GET_FEATURE_DETAILS_FAIL,
  GET_FEATURE_DETAILS_REQUEST,
  GET_FEATURE_DETAILS_RESET,
  GET_FEATURE_DETAILS_SUCCESS,
  SUBMIT_FEATURE_FAIL,
  SUBMIT_FEATURE_REQUEST,
  SUBMIT_FEATURE_SUCCESS,
} from "../../types/select_feature/selectFeatureTypes";

const featureListInitialState = {
  loading: false,
  selectedFeature: "",
  featureList: { data: [] },
  error: "",
};

export const featureSelectionReducers = (
  state = featureListInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FEATURE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_FEATURE_LIST_SUCCESS:
      const oldList = state.featureList.data;
      const combineList = [...oldList, ...payload.data];
      return {
        ...state,
        loading: false,
        featureList: {
          ...payload,
          data: combineList,
        },
        error: "",
      };
    case SELECT_FEATURE_FROM_FEATURE_LIST:
      return {
        ...state,
        loading: false,
        selectedFeature: payload,
        error: "",
      };

    case GET_FEATURE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_FEATURE_LIST_RESET:
      return {
        loading: false,
        error: "",
        selectedFeature: "",
        featureList: { data: [] },
      };

    default:
      return state;
  }
};

const featureDetailsInitialState = {
  loading: false,
  featureDetails: {},
  error: "",
};

export const featureDetailReducers = (
  state = featureDetailsInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FEATURE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_FEATURE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        featureDetails: payload,
        error: "",
      };

    case GET_FEATURE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_FEATURE_DETAILS_RESET:
      return {
        loading: false,
        error: "",
        featureDetails: {},
      };

    default:
      return state;
  }
};

const submitFeatureInitialState = {
  loading: false,
  submitFeatureResponse: {},
  error: "",
};

export const submitFeatureReducers = (
  state = submitFeatureInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_FEATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_FEATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        submitFeatureResponse: payload,
        error: "",
      };

    case SUBMIT_FEATURE_FAIL:
      return {
        ...state,
        loading: false,
        submitFeatureResponse: {},
        error: payload,
      };

    default:
      return state;
  }
};
