import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const createCustomer = (data) => {
  return axios.post("/api/v1/account", data);
};

const getCustomers = (user_role, page = 1, keyword = "") => {
  return axios.get(
    `v1${user_role === roleNames.superAdmin ? "/po" : ""}${
      user_role === roleNames.buAdmin ? "/am" : ""
    }/customers?${keyword && `search=` + keyword}${
      keyword ? "" : page && `page=` + page
    }`
  );
};

const getCustomerById = (customerId) => {
  return axios.get(`/api/v1/bu/customers/${customerId}`);
};

const updateCustomer = (customerId, data) => {
  return axios.post(`/api/v1/bu/customers/${customerId}`, {
    _method: "PATCH",
    ...data,
  });
};

const getCustomerProperties = (customerId, page = 1) => {
  return axios.get(
    `api/v1/bu/properties?customer_id=${customerId}&page=${page}`
  );
};

export {
  getCustomers,
  createCustomer,
  getCustomerById,
  updateCustomer,
  getCustomerProperties,
};
