import "./style.css";

import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WITLogo from "../../../assets/logo/web_logo.png";
/* eslint-disable react-hooks/exhaustive-deps */
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { isMaintenance } from "../../shared/helpers";
import maintenance from "../../../assets/logo/maintenance_img.png";
import { useLocation } from "react-router-dom";

const MaintenanceFile = () => {
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    isMaintenance();
  }, []);

  return (
    <div className="login-page">
      <div className="text-center" style={{ marginTop: "32px" }}>
        <img
          alt="WIT-logo"
          className="WIT-logo"
          style={{ width: "35%" }}
          src={WITLogo}
        />
      </div>

      <div
        style={{
          display: errorText !== "" ? "block" : "none",
        }}
      >
        <div className="email-pass-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-4 font-bold">{errorText}</span>
        </div>
      </div>
      <div>
        <img
          alt="maintenance-logo"
          src={maintenance}
          style={{ width: "440px" }}
        />
      </div>
      <p className="maintenance-cap-text">WE ARE UNDER MAINTENANCE</p>
      <p className="maintenance-small-text">Please visit us in 24 hours</p>
    </div>
  );
};

export default MaintenanceFile;
