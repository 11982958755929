import "./style.css";

import {
  addRooms,
  checkValuesEdit,
  initialState,
  redirectLinks,
} from "./InspectionHelper";
import { getErrorMsg, showToast } from "../../shared/helpers";
import {
  getInspectionbyId,
  updateInspectionbyId,
} from "../../../services/InspectionService";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import InspectionsFields from "./InspectionsFields";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const EditInspection = () => {
  const { insp_id } = useParams();
  const [isLoading, setisLoading] = useState(insp_id ? true : false);

  const location = useLocation();

  const [isSubmit, setisSubmit] = useState(false);
  const [errors, seterrors] = useState({});
  const [disbutton, setdisbutton] = useState(true);

  // delete
  const [deleteRooms, setdeleteRooms] = useState([]);

  //confirmationModal
  const [initialInspection, setInitialInspection] = useState({});

  let newdata = {};
  if (location.state) {
    newdata = [
      {
        id: location.state?.customer_id ? location.state.customer_id : null,

        first_name: location?.state?.first_name
          ? location.state.first_name
          : null,
        last_name: location?.state?.last_name ? location.state.last_name : null,
      },
    ];
  }
  const [inspection, setInspection] = useState(initialState(location, newdata));

  const [customerName, setcustomerName] = useState();
  const userInfo = useSelector((state) => state.user.data);
  const [editEnabled, seteditEnabled] = useState(insp_id ? false : true);
  const history = useHistory();

  useEffect(() => {
    if (insp_id) {
      getInspection(insp_id);
    } else if (location?.state?.inspection_id) {
      getInspection(location?.state?.inspection_id);
    }
  }, []);

  useEffect(() => {
    if (
      inspection.rooms.length > 0 &&
      inspection.property !== undefined &&
      inspection.type !== ""
    ) {
      setdisbutton(false);
    } else {
      setdisbutton(true);
    }
  }, [inspection]);

  const payloadData = (pivotIDs, pivotName, rID, rName) => {
    let data = {
      notes: inspection.notes,
      type: inspection.type,
      property_id: inspection?.property?.id
        ? inspection?.property?.id
        : inspection?.property,
    };
    if (pivotIDs.length > 0 && pivotName.length > 0) {
      data = {
        ...data,
        room_pivot_ids: pivotIDs,
        room_names_update: pivotName,
      };
    }

    if (rID.length > 0 && rName.length > 0) {
      data = { ...data, room_ids: rID, room_names: rName };
    }
    if (deleteRooms.length > 0) {
      data = { ...data, room_pivot_ids_delete: deleteRooms };
    }

    if (
      userInfo?.role !== "customer" &&
      inspection.type === "wit_inspect" &&
      (inspection.inspector_id || inspection?.inspectors[0]?.id)
    ) {
      data = {
        ...data,
        inspector_id: inspection.inspector_id || inspection?.inspectors[0]?.id,
      };
    }
    return data;
  };
  const EditInspection = (id) => {
    setisSubmit(true);

    if (inspection?.rooms?.length > 0) {
      let rID = [];
      let rName = [];
      let pivotIDs = [];
      let pivotName = [];
      addRooms(
        inspection,
        rID,
        rName,
        pivotIDs,
        pivotName,
        initialInspection
      ).then(() => {
        if (checkValuesEdit(inspection, rID, rName, pivotIDs)) {
          setdisbutton(true);

          updateInspectionbyId(
            id,
            payloadData(pivotIDs, pivotName, rID, rName),
            userInfo?.role
          )
            .then((resp) => {
              showToast("Inspection updated successfully.", true);
              redirectLinks(resp.data, userInfo, "edit", history);
            })
            .catch((err) => {
              console.log(err?.response?.status, "err?.response?.status ----- err?.response?.status " )
              if (err?.response?.status === 422) {
                seterrors(err?.response?.data?.errors);
              } 
              else if(err?.response?.status === 406){
                showToast(err?.response?.data?.message);
                 history.goBack();
                  // history.push("/inspections?page=1");
              }
              else {
                showToast(getErrorMsg(err) || "Unable to create inspection.");
              }
            })
            .finally(() => {
              seteditEnabled(false);
              setdisbutton(false);
            });
        }
      });
    } else {
      showToast("Please add a room / item");
    }
  };

  const getInspection = (id) => {
    setisLoading(true);

    getInspectionbyId(id, userInfo?.role)
      .then((resp) => {
        console.log(resp.data, "--------------------------");
        if (!resp.data.type) {
          resp.data.type = "wit_inspect";
        }
        setInspection(resp.data);
        setInitialInspection(JSON.parse(JSON.stringify(resp.data)));

        setcustomerName(
          resp.data.customer.first_name + " " + resp.data.customer.last_name
        );
        setdisbutton(false);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setdisbutton(false);

          seterrors(err?.response?.data?.errors);
        } else if (err?.response?.status === 404) {
          setdisbutton(true);
          // showToast("You can view further details on Mobile application", true);
        }
        else if(err?.response?.status === 406){
          showToast(err?.response?.data?.message);
          history.goBack();
            // history.push("/inspections?page=1");
        }
        else {
          setdisbutton(false);
          showToast(getErrorMsg(err) || "Unable to get inspection.");
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <>
            <div className="main-content-area">
              <div className="d-flex align-items-center">
                {insp_id ? (
                  <>
                    {editEnabled ? (
                      <>
                        {" "}
                        <button
                          className="btn btn-primary ml-auto"
                          disabled={disbutton}
                          style={{ paddingInline: "55px" }}
                          onClick={() => EditInspection(insp_id, "edit")}
                          id="save_insp"
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-primary "
                          disabled={disbutton}
                          style={{
                            background: "transparent",
                            borderColor: "#14444d",
                            paddingInline: "47px",
                            marginLeft: "16px",
                          }}
                          onClick={() => {
                            getInspection(insp_id);
                            seteditEnabled(false);
                          }}
                          id="cancel_edit_insp"
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-primary ml-auto"
                        disabled={disbutton}
                        onClick={() => seteditEnabled(true)}
                        id="edit_inps"
                        style={{ paddingInline: "30px" }}
                      >
                        <img
                          alt="Edit insp"
                          src={
                            require(`../../../assets/icons/edit@2x.png`).default
                          }
                        />
                        Edit Info
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    onClick={() => {
                      if (location?.state?.inspection_id) {
                        EditInspection(
                          location?.state?.inspection_id,
                          "submit"
                        );
                      }
                    }}
                    disabled={disbutton}
                    className={`btn btn-primary ml-auto ${
                      (disbutton ||
                        inspection.property === "" ||
                        inspection.type === "") &&
                      "dis-btn"
                    }`}
                  >
                    Save & Continue
                  </button>
                )}
              </div>
              <InspectionsFields
                isSubmit={isSubmit}
                inspection={inspection}
                setInspection={setInspection}
                userInfo={userInfo}
                errors={errors}
                insp_id={insp_id || location?.state?.inspection_id}
                setcustomerName={setcustomerName}
                customerName={customerName}
                setdeleteRooms={setdeleteRooms}
                deleteRooms={deleteRooms}
                editEnabled={editEnabled}
              />
            </div>
          </>
        )}
      </ScrollBar>
    </div>
  );
};

export default EditInspection;
