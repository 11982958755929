import { Suspense, useEffect, useState } from "react";
import { addRooms, checkValuesEdit, redirectLinks } from "../InspectionHelper";
import {
  getAddress,
  getErrorMsg,
  inspectionStatusName,
  paid_label,
  paid_value,
  phoneNumberFormat,
  showToast,
  truncate,
} from "../../../shared/helpers";
import {
  getInspectionbyId,
  updateInspectionbyId,
} from "../../../../services/InspectionService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import CustomField from "../../../components/CustomField";
import { INSPECTION_DISABLE } from "../../../../store/actions";
import InspectionsFields from "../InspectionsFields";
import SearchDropdown from "../../../components/SelectDropdown";
import { Spinner } from "react-bootstrap";

export default function GeneralInfo({ generalInfo }) {
  const [loader, setloader] = useState(true);
  const [errors, setErrors] = useState({});
  const [editEnabled, setEditEnabled] = useState(false);
  const [confirmModals, setConfirmModals] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  //submitInspection

  const dispatch = useDispatch();

  const [initialInspection, setInitialInspection] = useState({});
  const [inspectionData, setInspectionData] = useState({});
  const history = useHistory();

  const { insp_id } = useParams();

  const [isSubmit, setisSubmit] = useState(false);
  const userInfo = useSelector((state) => state.user.data);
  const checkDisable = useSelector((state) => state.inspectionDisable.disable);

  const [customerId, setcustomerId] = useState(generalInfo?.customer_id);
  const [customerName, setcustomerName] = useState(
    generalInfo?.customer?.first_name + " " + generalInfo?.customer?.last_name
  );
  const [showData, setshowData] = useState(generalInfo);

  const [deleteRooms, setdeleteRooms] = useState([]);
  console.log(inspectionData.status, "----");

  useEffect(() => {
    getInsptions();
  }, []);

  const getInsptions = () => {
    setisSubmit(true);
    setloader(true);
    getInspectionbyId(insp_id, userInfo?.role)
      .then((insp) => {
        console.log(insp, "-0-0-0-0-0-0-0-");
        if (!insp.data.type) {
          insp.data.type = "wit_inspect";
        }
        setInspectionData(insp.data);
        // setdata(x.data);
        // setgeneralInfo(x.data);
        setInitialInspection(JSON.parse(JSON.stringify(insp.data)));
        setcustomerName(
          insp.data?.customer?.first_name + " " + insp.data?.customer?.last_name
        );
        setcustomerId(insp.data?.customer_id);

        // to show data without effecting onchange
        setshowData(insp.data);
        setloader(false);

        if (
          insp.data.status === "completed" ||
          insp.data.paid_status === "un_paid" ||
          insp.data.deleted_at !== null
        ) {
          dispatch({
            type: INSPECTION_DISABLE,
            payload: { disable: true },
          });
        } else {
          dispatch({
            type: INSPECTION_DISABLE,
            payload: { disable: false },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          showToast("Unable to get an inspection.");
        } else {
          setloader(false);
        }
        console.log(err);
      });
  };
  const onSubmitTask = () => {
    const data = {
      submitted: inspectionData.status === "completed" ? "false" : "true",
    };
    updateInspectionbyId(insp_id, data, userInfo?.role)
      .then((res) => {
        showToast(res.data.message, true);
        history.push("/inspections?page=1");
      })
      .catch((err) => {
        if (err?.response?.status !== 500) {
          showToast(err?.response?.data?.errors?.inspection[0]);
        }
      })
      .finally(() => {
        setConfirmModals(false);
      });
  };

  const EditInspection = (id) => {
    setisSubmit(true);
    setIsSaving(true);

    if (inspectionData?.rooms?.length > 0) {
      let rID = [];
      let rName = [];
      let pivotIDs = [];
      let pivotName = [];
      addRooms(
        inspectionData,
        rID,
        rName,
        pivotIDs,
        pivotName,
        initialInspection
      ).then(() => {
        if (checkValuesEdit(inspectionData, rID, rName, pivotIDs)) {
          let data = {
            notes: inspectionData?.notes,
            type: inspectionData?.type,
            property_id: inspectionData?.property?.id
              ? inspectionData?.property?.id
              : inspectionData?.property,
            customer_id: customerId,

            paid_status: inspectionData?.paid_status,
            paid: inspectionData?.paid === "paid" ? true : false,
          };
          if (pivotIDs.length > 0) {
            data = { ...data, room_pivot_ids: pivotIDs };
          }
          if (pivotName.length > 0) {
            data = { ...data, room_names_update: pivotName };
          }

          if (rID.length > 0 && rName.length > 0) {
            data = { ...data, room_ids: rID, room_names: rName };
          }
          if (deleteRooms.length > 0) {
            data = { ...data, room_pivot_ids_delete: deleteRooms };
          }

          if (
            userInfo?.role !== "customer" &&
            inspectionData?.type === "wit_inspect" &&
            (inspectionData?.inspector_id || inspectionData?.inspectors[0]?.id)
          ) {
            data = {
              ...data,
              inspector_id:
                inspectionData.inspector_id ||
                inspectionData?.inspectors[0]?.id,
            };
          }
          updateInspectionbyId(id, data, userInfo?.role)
            .then((resp) => {
              showToast("Inspection updated successfully.", true);
              if (
                resp?.data?.type === "wit_inspect" &&
                !resp?.data?.calenly_id
              ) {
                redirectLinks(resp?.data, userInfo, "edit", history);
              }
              getInsptions();
            })
            .catch((err) => {
              console.log(err?.response);
              if (err?.response?.status === 422) {
                setErrors(err?.response?.data?.errors);
              } else {
                showToast(getErrorMsg(err) || "Unable to create inspection.");
              }
            })
            .finally(() => {
              setEditEnabled(false);
              setdeleteRooms([]);
              setIsSaving(false);
            });
        }
      });
    } else {
      showToast("Please add a room / item");
    }
  };

  // useEffect(() => {
  //   // generalInfo?.status && setloader(false);

  //   if (!location.pathname.includes("generalInfo")) {
  //     getInsptions();
  //   }
  // }, [location.pathname]);

  const redirectBox = (url, id) => {
    if (url === "property") {
      window.open(`/property/${id}`, "_blank");
    } else {
      window.open(`/users/${id}`, "_blank");
    }
  };

  return (
    <>
      {loader ? (
        <div className="spinner-div">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="main-content-area">
          <div className="d-flex justify-content-end">
            {!editEnabled ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <button
                    className="btn btn-primary "
                    style={{
                      paddingInline:
                        inspectionData.status === "completed" ? "10px" : "22px",
                      marginRight: "20px",
                    }}
                    disabled={
                      inspectionData.deleted_at !== null
                        ? true
                        : inspectionData.status !== "completed" &&
                          inspectionData.paid_status === "un_paid"
                    }
                    onClick={() => {
                      setConfirmModals(true);
                    }}
                  >
                    <img
                      alt="Edit insp"
                      src={
                        inspectionData.status === "completed"
                          ? require(`../../../../assets/icons/unsubmit.png`)
                              .default
                          : require(`../../../../assets/icons/submit.png`)
                              .default
                      }
                    />{" "}
                    {inspectionData.status === "completed"
                      ? "Un-Submit"
                      : "Submit"}
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    disabled={
                      checkDisable && inspectionData.paid_status !== "un_paid"
                    }
                    onClick={() => setEditEnabled(true)}
                    id="edit_inspection_info"
                  >
                    <img
                      alt="Edit insp"
                      src={
                        require(`../../../../assets/icons/edit@2x.png`).default
                      }
                    />{" "}
                    Edit Info
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  disabled={isSaving}
                  onClick={() => EditInspection(insp_id)}
                >
                  Save
                </button>
                <button
                  className="btn btn-primary"
                  style={{
                    background: "transparent",
                    borderColor: "#14444d",
                    paddingInline: "19px",
                    marginLeft: "16px",
                  }}
                  onClick={() => {
                    setEditEnabled(false);
                    getInsptions();
                  }}
                  disabled={isSaving}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="row mt-3 pb-2">
            <div className="col-md-4">
              <h4>Customer</h4>
              <div
                className="box-general-info info-address"
                style={{ width: "100%", cursor: "pointer" }}
                onClick={() => redirectBox("user", showData.customer.id)}
              >
                <div>
                  <h4
                    style={{ paddingTop: "17px", marginBottom: "2px" }}
                    className="name"
                  >
                    {truncate(
                      `${showData?.customer?.first_name} ${showData?.customer?.last_name}`
                    )}{" "}
                  </h4>
                  <p
                    style={{
                      marginBottom: "2px",
                      overflow: "hidden",
                      width: "100%",
                      textOverflow: "ellipsis",
                    }}
                    className="mb-1 info-address-details"
                  >
                    {showData?.customer?.email}
                  </p>
                  <p
                    style={{ paddingBottom: "10px" }}
                    className="mb-1 info-address-details"
                  >
                    {showData?.customer?.phone_number ? (
                      phoneNumberFormat(showData?.customer)
                    ) : (
                      <span style={{ paddingInline: "10px" }}>---</span>
                    )}
                  </p>
                </div>
                <div className="d-center-div">
                  <img
                    alt="right-arrow"
                    src={
                      require("../../../../assets/icons/arrow@2x.png").default
                    }
                    width="8px"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4" style={{ cursor: "pointer" }}>
              <h4>Property</h4>
              <div
                className="box-general-info info-address bg-prop-img"
                onClick={() => redirectBox("property", showData?.property?.id)}
              >
                <div className="d-center-div">
                  <h4
                    className="name compressed-property"
                    style={{ width: "202px" }}
                  >
                    {getAddress(showData?.property?.address)}
                  </h4>
                </div>
                <div className="d-center-div">
                  <img
                    alt="property-arrow"
                    src={
                      require("../../../../assets/icons/loc-icon@2x.png")
                        .default
                    }
                    width="25px"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-md-4"
              style={{ cursor: showData?.inspectors[0]?.id && "pointer" }}
            >
              <h4>Inspector</h4>
              <div
                className="box-general-info info-address"
                onClick={() =>
                  showData?.inspectors[0]?.id &&
                  redirectBox("user", showData?.inspectors[0]?.id)
                }
              >
                {showData?.inspectors?.length > 0 ? (
                  <div style={{ width: "100%" }}>
                    <h4
                      style={{ paddingTop: "17px", marginBottom: "2px" }}
                      className="name"
                    >
                      {truncate(
                        `${showData?.inspectors[0]?.first_name} ${showData?.inspectors[0]?.last_name}`
                      )}{" "}
                    </h4>
                    <p
                      style={{
                        marginBottom: "2px",
                        overflow: "hidden",
                        width: "100%",
                        textOverflow: "ellipsis",
                      }}
                      className="info-address-details"
                    >
                      {showData?.inspectors[0]?.email}
                    </p>
                    <p
                      style={{ paddingBottom: "10px" }}
                      className="mb-1 info-address-details"
                    >
                      {showData?.inspectors[0]?.phone_number ? (
                        phoneNumberFormat(showData?.inspectors[0])
                      ) : (
                        <span style={{ paddingInline: "10px" }}>---</span>
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="d-center-div">---</div>
                )}
                <div className="d-center-div">
                  <img
                    alt="right-arrow"
                    src={
                      require("../../../../assets/icons/arrow@2x.png").default
                    }
                    width="8px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-4 " style={{ height: "54px" }}>
              <h4>Appointment Details</h4>
              <div className="form-group app-date">
                <CustomField
                  name="status"
                  label="Status"
                  type="text"
                  disabled={true}
                  id="status_id"
                  value={inspectionStatusName(showData?.status)}
                  inputProps={{
                    maxLength: "60",
                  }}
                />
              </div>
            </div>

            <div className="col-md-4">
              <h4>Payment Details</h4>
              <div className="form-group">
                <SearchDropdown
                  placeHolder="Select"
                  optionsData={[
                    {
                      value: "paid",
                      label: "Paid",
                    },
                    {
                      value: "un_paid",
                      label: "Unpaid",
                    },
                  ]}
                  value={
                    insp_id
                      ? {
                          value: paid_value(inspectionData?.type),
                          label: `${paid_label(inspectionData?.paid)}`,
                        }
                      : null
                  }
                  disabled={insp_id ? (editEnabled ? false : true) : false}
                  defaultOptionValue={
                    insp_id && paid_value(inspectionData?.paid_status)
                  }
                  onSelect={(ev) => {
                    setInspectionData({
                      ...inspectionData,
                      paid_status: ev?.value || "",
                    });
                  }}
                />
                <small className="text-danger ml-1">
                  {isSubmit && inspectionData.type === ""
                    ? "Please select a inspector type"
                    : errors?.type
                    ? errors?.type[0]
                    : ""}
                </small>
              </div>
            </div>
            <div
              className="col-md-4"
              style={{ height: "54px", marginBottom: "18px" }}
            ></div>
          </div>
          <InspectionsFields
            isSubmit={isSubmit}
            inspection={inspectionData}
            setInspection={setInspectionData}
            userInfo={userInfo}
            errors={errors}
            insp_id={insp_id}
            setcustomerId={setcustomerId}
            setcustomerName={setcustomerName}
            customerName={customerName}
            setdeleteRooms={setdeleteRooms}
            deleteRooms={deleteRooms}
            editEnabled={editEnabled}
          />
          <Suspense fallback={null}>
            <ConfirmationModal
              show={confirmModals}
              onHide={() => setConfirmModals(false)}
              imgSrc={require("../../../../assets/icons/delete.png").default}
              helperText={
                inspectionData.status === "completed"
                  ? "Are you sure you want to un-submit the inspections?"
                  : "Are you sure you have completed this inspection?"
              }
              onSave={() => {
                onSubmitTask();
              }}
            />
          </Suspense>
        </div>
      )}
    </>
  );
}
