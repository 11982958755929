import "../Settings/style.css";

import {
  createUserCustomer,
  createUserInspector,
  createUserManager,
} from "../../../services/UserService";
import { getErrorMsg, showToast } from "../../shared/helpers";

import { regexes } from "../../shared/regexes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

export const useHookAddUser = () => {
  const history = useHistory();
  const [role, setrole] = useState("customer");
  const [userDetails, setuserDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    notes: "",
    avatar: "",
    role: role,
  });
  const [userHomeOwner, setuserHomeOwner] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    type: role,
  });
  const [isSubmit, setisSubmit] = useState(false);
  const [errors, seterrors] = useState({});

  const [disableSave, setDisableSave] = useState(false);
  const userInfo = useSelector((state) => state?.user?.data);

  const handleSave = () => {
    setisSubmit(true);
    if (role === "inspector" || role === "account_manager") {
      inspectorNAccountManagerCreation();
    }
    if (role === "customer") {
      customerCreation();
    }
  };
  const checkInspectorData = () => {
    return (
      userDetails.first_name &&
      userDetails.first_name.trim() &&
      userDetails.last_name &&
      userDetails.last_name.trim() &&
      userDetails.phone_number &&
      userDetails?.phone_number.length > 10 &&
      userDetails.phone_number.trim() &&
      userDetails.email &&
      regexes.email.test(userDetails?.email) &&
      userDetails.email.trim()
    );
  };
  const checkCustomerData = () => {
    return (
      userHomeOwner.first_name &&
      userHomeOwner.first_name.trim() &&
      userHomeOwner.last_name &&
      userHomeOwner.last_name.trim() &&
      userHomeOwner.phone_number &&
      userHomeOwner?.phone_number.length > 10 &&
      userHomeOwner.phone_number.trim() &&
      userHomeOwner.email &&
      regexes.email.test(userHomeOwner?.email) &&
      userHomeOwner.email.trim() &&
      userHomeOwner.address1 &&
      userHomeOwner.address1.trim() &&
      userHomeOwner.city &&
      userHomeOwner.city.trim() &&
      userHomeOwner.state &&
      userHomeOwner.zipcode &&
      userHomeOwner.zipcode.length > 4 &&
      userHomeOwner.zipcode.trim() &&
      userHomeOwner.type &&
      userHomeOwner.type.trim()
    );
  };
  const formData = new FormData();
  const inspectorNAccountManagerCreation = () => {
    if (checkInspectorData()) {
      formData.append("first_name", userDetails?.first_name);
      formData.append("last_name", userDetails?.last_name);
      formData.append("email", userDetails?.email);
      formData.append("phone_number", userDetails?.phone_number);
      if (userDetails?.notes) {
        formData.append("notes", userDetails?.notes);
      }

      if (userDetails?.avatar) {
        formData.append("avatar", userDetails?.avatar);
        console.log(
          "userDetails?.avatar_userDetails?.avatar",
          userDetails?.avatar
        );
      }

      if (role === "inspector") {
        setDisableSave(true);
        createUserInspector(userInfo.role, formData)
          .then((resp) => {
            if (resp) {
              console.log(resp.data);
              seterrors({});
              setisSubmit(false);
              showToast("User has been created", true);
              history.replace(`/users`);
            }
          })
          .catch((err) => {
            console.log(err?.response);
            if (err?.response?.status === 422) {
              seterrors(err?.response?.data?.errors);
              // showToast(err?.response?.data?.message);
            } else {
              showToast(getErrorMsg(err));
            }
          })
          .finally(() => setDisableSave(false));
      } else if (role === "account_manager") {
        setDisableSave(true);
        createUserManager(userInfo.role, formData)
          .then((resp) => {
            if (resp) {
              console.log(resp.data);
              seterrors({});
              setisSubmit(false);
              showToast("User has been created", true);
              history.replace(`/users`);
            }
          })
          .catch((err) => {
            console.log(err?.response);
            if (err?.response?.status === 422) {
              seterrors(err?.response?.data?.errors);
            } else {
              showToast(getErrorMsg(err));
            }
          })
          .finally(() => setDisableSave(false));
      }
    }
  };

  const customerCreation = () => {
    if (checkCustomerData()) {
      setDisableSave(true);
      formData.append("first_name", userHomeOwner?.first_name);
      formData.append("last_name", userHomeOwner?.last_name);
      formData.append("email", userHomeOwner?.email);
      formData.append("phone_number", userHomeOwner?.phone_number);
      formData.append("address1", userHomeOwner?.address1);
      formData.append("city", userHomeOwner?.city);
      formData.append("state", userHomeOwner?.state);
      formData.append("zipcode", userHomeOwner?.zipcode);
      formData.append("type", userHomeOwner?.type);
      formData.append("address2", userHomeOwner?.address2);
      if (userHomeOwner?.avatar) {
        formData.append("avatar", userHomeOwner?.avatar);
      }
      createUserCustomer(userInfo.role, formData)
        .then((resp) => {
          if (resp) {
            seterrors({});
            setisSubmit(false);
            showToast("User has been created", true);
            history.replace(`/users`);
          }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => setDisableSave(false));
    }
  };

  return [
    role,
    setrole,
    userDetails,
    setuserDetails,
    userHomeOwner,
    setuserHomeOwner,
    isSubmit,
    setisSubmit,
    errors,
    seterrors,
    disableSave,
    setDisableSave,
    handleSave,
    userInfo,
  ];
};
