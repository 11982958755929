import {
  faChevronLeft,
  faChevronRight,
  faStepBackward,
  faStepForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Pagination from "react-js-pagination";

export default function Paginator(props) {
  return (
    <Pagination
      {...props}
      pageRangeDisplayed={5}
      itemClass="page-item"
      linkClass="page-link"
      activeClass="active"
      linkClassPrev="prev"
      linkClassNext="next"
      linkClassFirst="first"
      linkClassLast="last"
      firstPageText={<FontAwesomeIcon icon={faStepBackward} />}
      lastPageText={<FontAwesomeIcon icon={faStepForward} />}
      nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
      prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
    />
  );
}
