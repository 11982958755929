/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getProperty } from "../../../services/PropertyService";
import Paginator from "../../components/Paginator/Paginator";
import {
  getAddress,
  timeConversionUTC,
  truncate,
  useDidUpdateEffect,
  utcDate,
} from "../../shared/helpers";
import "./style.css";

const PropertyListComponent = (props) => {
  const { property } = props;
  const history = useHistory();
  const propertyLink = (id) => {
    history.push("/property/" + id);
  };
  return Object.keys(property).length !== 0 && property?.data?.length !== 0 ? (
    property.data?.map((item, i) => (
      <tr
        key={`property-${i}`}
        style={{ cursor: "pointer" }}
        onClick={() => propertyLink(item.id)}
      >
        <td className="">
          <span>{utcDate(item?.created_at)}</span>
          <br />
          <span>{timeConversionUTC(item?.created_at)}</span>
        </td>
        <td>
          <span>{getAddress(item?.address)}</span>
        </td>
        <td className="">
          <span>
            {" "}
            {truncate(
              item?.customer?.first_name + " " + item?.customer?.last_name
            )}
          </span>
        </td>

        <td>
          <div>
            <img
              style={{ padding: "15px 20px" }}
              alt="arrow"
              src={require("../../../assets/icons/arrow@2x.png").default}
            />
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No record found</td>
    </tr>
  );
};

const Property = () => {
  const [propertyList, setpropertyList] = useState({});
  const userInfo = useSelector((state) => state?.user?.data);

  const [loader, setloader] = useState(true);

  const searchField = useSelector((state) => state.header.searchField);
  const [skip, setSkip] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const pageNumber = query.get("page");
  console.log(pageNumber, "queryqueryqueryqueryqueryqueryquery");

  const [page, setpage] = useState(pageNumber);
  console.log(page, "pagepagepagepagepage");

  useEffect(() => {
    setpage(pageNumber);
  }, [pageNumber]);

  useDidUpdateEffect(() => {
    setloader(true);
    getProperty(userInfo?.role, 1, searchField)
      .then((x) => {
        console.log(x.data);
        setpropertyList(x.data);
        setpage(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    setloader(true);
    getProperty(userInfo?.role, page, searchField)
      .then((res) => {
        if (res) {
          setpropertyList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  }, [page]);

  return (
    <>
      <div className="main">
        {/* code to un-comm */}
        <div style={{ padding: "25px 20px 25px 20px" }}>
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "calc(100vh - 200px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    height: "calc(100vh - 200px)",
                    paddingRight: "10px",
                  }
            }
          >
            <div>
              {loader ? (
                <>
                  <div>
                    <Spinner animation="border" variant="success" />
                  </div>
                </>
              ) : (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Date/Time Created</th>
                      <th>Address</th>
                      <th>Customer</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <PropertyListComponent property={propertyList} />
                  </tbody>
                </table>
              )}
            </div>
          </ScrollBar>
          {Object.keys(propertyList).length !== 0 &&
          propertyList?.data?.length !== 0 ? (
            <nav className="mt-4 mb-3">
              <ul className="pagination justify-content-center mb-0 paginator">
                <Paginator
                  activePage={propertyList?.current_page}
                  itemsCountPerPage={propertyList?.per_page}
                  totalItemsCount={propertyList?.total}
                  onChange={(pages) => {
                    setSkip(false);
                    history.push(`/properties?page=${pages}`);
                  }}
                />
              </ul>
              <ul className="total-align">
                Showing {propertyList?.from} to {propertyList?.to} of{" "}
                {propertyList?.total} entries{" "}
              </ul>
            </nav>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Property;
