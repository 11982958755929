import "./style.css";

import { Link, NavLink } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import React, { useEffect } from "react";
import routes, { authRoles } from "../../../navigation/route-config";
import { useDispatch, useSelector } from "react-redux";

import LogoSidenav from "../../../assets/logo/logo_sidenav.png";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { SET_SIDENAV } from "../../../store/actions";
import Submenu from "./SubMenu";
import metadata from "../../../metadata.json";
import { permissions } from "../../shared/helpers";
import { useMediaQuery } from "react-responsive";

function SideNav() {
  const open = useSelector((state) => state.sidenav.open);
  const role = useSelector((state) => state.user.data.role);

  const accessibleRoutes = useSelector((state) => state.user.data.roles);

  let availablePaths = null;

  const navRoutes = routes.filter((item) => {
    if (role === authRoles?.buSubAdmin) {
      const availablePaths = permissions.filter((pr) =>
        accessibleRoutes.some((ar) => ar.alias === pr.value)
      );

      return (
        item.showInSideNav &&
        availablePaths.some((i) => item.path.startsWith(i.routePath))
      );
    } else {
      return item.showInSideNav && item.roles.includes(role);
    }
  });

  const dispatch = useDispatch();
  const md = useMediaQuery({ query: "(max-width: 992px)" });
  const lg = useMediaQuery({ query: "(min-width: 993px)" });
  const openSidenav = {
    width: "230px",
    opacity: 1,
    isFocused: true,
    overflow: "auto",
  };
  const closeSidenav = {
    marginLeft: "-230px",
    opacity: 0,
  };

  useEffect(() => {
    if (md) {
      dispatch({ type: SET_SIDENAV, payload: { open: false } });
    } else if (lg) {
      dispatch({ type: SET_SIDENAV, payload: { open: true } });
    }
  }, [md, lg]);

  return (
    <Navbar
      id="side-nav"
      style={open ? openSidenav : closeSidenav}
      tabIndex="-1"
      onBlur={() => {}}
    >
      <Link to="/" className="navbar-logo">
        <img
          src={LogoSidenav}
          width="190px"
          className="d-inline-block align-top"
          alt="WIT logo"
        />
      </Link>
      {role && (
        <>
          <div className="margin-t-sidebar"></div>

          <div
            className="column"
            // style={{ height: "474px", overflowY: "auto", position: "relative" }}
          >
            {/* <PerfectScrollbar
              className="scrollbarheight"
              ref={(el) => (scrollViewRef.current = el)}
              // ref={scrollViewRef}
            > */}
            <Nav className="column">
              {navRoutes.map((item, i) => (
                <React.Fragment key={item.path}>
                  {item.hasChildren ? (
                    <Submenu data={item} />
                  ) : (
                    <NavLink
                      id={item.name}
                      key={item.path + item.name}
                      to={item.navPath ?? item.path}
                      style={{ color: "#ffffff" }}
                      className="nav-link"
                      activeClassName="active"
                      activeStyle={{ color: "#ffffff" }}
                    >
                      {item.icon ? (
                        <img
                          src={
                            require(`../../../assets/icons/${item?.icon}`)
                              .default
                          }
                          alt="icon"
                        />
                      ) : null}
                      {item.name}
                    </NavLink>
                  )}
                  {/* {i === navRoutes.length - 2 ? (
                      <hr style={{ width: "80%" }} />
                    ) : null} */}
                </React.Fragment>
              ))}
            </Nav>
            <ProfileIcon
              // subscribed={false}
              style={{ justifyContent: "flex-end" }}
            />
            <div className="build-name">
              {process.env.REACT_APP_SENTRY_ENV === "qa" && (
                <span className="text">
                  {`QA : ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}
                </span>
              )}
            </div>
            {/* <ProfileIcon /> */}
            {/* </PerfectScrollbar> */}
            {/* </ScrollBar> */}
          </div>
        </>
      )}
    </Navbar>
  );
}
export default SideNav;
