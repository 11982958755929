import "../style.css";

import React, { useState } from "react";

import CustomField from "../../../components/CustomField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ScrollBar from "react-perfect-scrollbar";
import SuccessModal from "../../../components/SuccessModal";
import emailIcon from "../../../../assets/icons/email@2x.png";
/* eslint-disable react-hooks/exhaustive-deps */
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import passIcon from "../../../../assets/icons/lock@2x.png";
import personIcon from "../../../../assets/icons/name@2x.png";
import { regexes } from "../../../shared/regexes";
import { signupRequest } from "../../../../services/auth-service";
import witLogo from "../../../../assets/logo/web_logo.png";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableSignup, setDisableSignup] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errors, seterrors] = useState({});
  const [FormSubmit, setFormSubmit] = useState(false);
  const [fullName, setfullName] = useState("");
  const [Cpassword, setCpassword] = useState("");
  const [opendel, setopendel] = useState(false);
  const [last_name, setlast_name] = useState();
  const [emailErr, setemailErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [bothPassErr, setbothPassErr] = useState(false);

  function handleSubmit(event) {
    setFormSubmit(true);
    setemailErr(false);
    setpassErr(false);
    setbothPassErr(false);
    setErrorText("");
    seterrors({});

    if (email && password && Cpassword && fullName && last_name) {
      if (!regexes.email.test(email)) {
        setErrorText("Please enter a valid email address");
        setemailErr(true);
      } else if (password.length < 8 || !regexes.password.test(password)) {
        setErrorText(
          "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
        );
        setpassErr(true);
      } else if (Cpassword !== password) {
        setbothPassErr(true);
        setErrorText("Passwords do not match.");
      }
      if (
        email &&
        password &&
        Cpassword &&
        fullName &&
        last_name &&
        !password.length < 8 &&
        regexes.password.test(password) &&
        regexes.email.test(email) &&
        Cpassword === password
      ) {
        setErrorText("");

        setDisableSignup(true);
        signupRequest({
          email,
          password,
          first_name: fullName,
          last_name,
          type: "customer",
        })
          .then((resp) => {
            if (resp) {
              setopendel(true);

              setDisableSignup(false);
            }
          })
          .catch((error) => {
            setopendel(false);
            if (error?.response?.data?.error === "inactive_user") {
              setErrorText(error?.response?.data?.error_description);
            }
            if (error?.response?.status === 422) {
              if (error?.response?.data?.errors?.email) {
                setErrorText(error?.response?.data?.errors.email[0]);
              } else {
                seterrors(error?.response?.data?.errors);
              }
            }
            if (error?.response?.status === 400) {
              setErrorText(error?.response?.data?.message);
            }
            setDisableSignup(false);
          });
      }
    }
    event.preventDefault();
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "#f4eadf" }}>
      <ScrollBar
        className="d-flex justify-content-center align-items-center"
        maxScrollbarLength="10px"
        style={{ height: "101vh", position: "static" }}
      >
        <div className="signup-page">
          <div className="text-center" style={{ marginTop: "50px" }}>
            <img
              alt="WIT-logo"
              className="WIT-logo"
              width="260px"
              style={{
                marginTop:
                  (errorText !== "" ||
                    ((!email || !password || !fullName || !last_name) &&
                      FormSubmit)) &&
                  "100px",
              }}
              src={witLogo}
            />
          </div>

          <div
            style={{
              display: errorText !== "" ? "block" : "none",
            }}
          >
            <div className="email-pass-error">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span className="ml-4 font-bold">{errorText}</span>
            </div>
          </div>

          <form onSubmit={handleSubmit} autocomplete="off">
            <div id="float-label-auth">
              <CustomField
                name="fullName"
                value={fullName}
                label="First Name"
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z ]/gi, "");
                  setfullName(value);
                }}
                id="signup_fullName"
                type="text"
                icon={personIcon}
                minWidth="410px"
                errors={
                  FormSubmit &&
                  (!fullName || (errors?.first_name && errors?.first_name[0]))
                }
                inputProps={{
                  maxLength: "40",
                  autoFocus: "autoFocus",
                }}
              />
              <small
                className="text-danger ml-1"
                style={{
                  display: FormSubmit ? "block" : "none",
                }}
              >
                {FormSubmit && !fullName
                  ? "Please enter first name"
                  : errors?.first_name
                  ? errors?.first_name[0]
                  : ""}
              </small>
            </div>
            <div id="float-label-auth" className="margin-top-field">
              <CustomField
                name="lastName"
                value={last_name}
                label="Last Name"
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z ]/gi, "");
                  setlast_name(value);
                }}
                id="signup_lastName"
                type="text"
                icon={personIcon}
                minWidth="410px"
                errors={
                  FormSubmit &&
                  (!last_name || (errors?.last_name && errors?.last_name[0]))
                }
                inputProps={{
                  maxLength: "40",
                }}
              />
              <small
                className="text-danger ml-1"
                style={{
                  display: FormSubmit ? "block" : "none",
                }}
              >
                {console.log(errors?.last_name)}
                {FormSubmit && !last_name
                  ? "Please enter last name"
                  : errors?.last_name
                  ? errors?.last_name[0]
                  : ""}
              </small>
            </div>

            <div id="float-label-auth" className="margin-top-field">
              <CustomField
                name="email"
                value={email}
                label="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                id="signup_email"
                type="text"
                icon={emailIcon}
                autocomplete="new-password"
                minWidth="410px"
                errors={
                  FormSubmit &&
                  (!email ||
                    (!regexes.email.test(email) && emailErr) ||
                    (errors?.email && errors?.email[0]))
                }
              />
              <small
                className="text-danger ml-1"
                style={{
                  display: FormSubmit ? "block" : "none",
                }}
              >
                {FormSubmit && !email
                  ? "Please enter an email address"
                  : errors?.email
                  ? errors?.email[0]
                  : ""}
              </small>
            </div>

            <div className="margin-top-field">
              <CustomField
                name="password"
                label="Password"
                value={password}
                minWidth="410px"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                password={true}
                autocomplete="new-password"
                icon={passIcon}
                id="signup_password"
                className={`auth-input-field`}
                errors={
                  FormSubmit &&
                  (!password ||
                    ((password.length < 8 ||
                      !regexes.password.test(password)) &&
                      passErr) ||
                    (Cpassword !== password && bothPassErr) ||
                    (errors?.password && errors?.password[0]))
                }
                type="password"
                inputProps={{
                  maxLength: "16",
                }}
              />
              <small
                className="text-danger ml-1"
                style={{
                  display: FormSubmit ? "block" : "none",
                  width: "410px",
                }}
              >
                {FormSubmit && !password
                  ? "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
                  : errors?.password
                  ? errors?.password[0]
                  : ""}
              </small>
            </div>
            <div className="margin-top-field">
              <CustomField
                name="Cpassword"
                label="Confirm Password"
                value={Cpassword}
                onChange={(e) => {
                  setCpassword(e.target.value);
                }}
                password={true}
                icon={passIcon}
                id="signup_Cpassword"
                className={`auth-input-field`}
                errors={
                  FormSubmit &&
                  (!Cpassword ||
                    (Cpassword !== password && bothPassErr) ||
                    (errors?.Cpassword && errors?.Cpassword[0]))
                }
                type="password"
                inputProps={{
                  maxLength: "16",
                }}
              />
              <small
                className="text-danger ml-1"
                style={{
                  display: FormSubmit ? "block" : "none",
                }}
              >
                {FormSubmit && !Cpassword
                  ? "Please enter confirm password"
                  : errors?.Cpassword
                  ? errors?.Cpassword[0]
                  : ""}
              </small>
            </div>

            <div className="margin-top-field text-align-end">
              <button
                id="signin_button"
                disabled={disableSignup}
                variant="primary"
                className={
                  disableSignup
                    ? "login-btn disabled-bg-color"
                    : "login-btn bg-light20"
                }
                type="submit"
              >
                Signup
              </button>
            </div>

            <div className="d-flex justify-content-center mt-3 black-text ">
              <div>
                Already have an account?{" "}
                <Link
                  to="/login/home-owner"
                  className="new-tab-link"
                  id="terms"
                >
                  Login
                </Link>
              </div>
            </div>
          </form>
          <div className=" mt-3 mb-3  black-text  font-16">
            <div className="mt-2">
              By logging in I agree to the{" "}
              <Link
                to="/terms-of-use"
                target="_blank"
                className="new-tab-link"
                id="terms"
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                className="new-tab-link"
                id="policy"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
          <SuccessModal
            show={opendel}
            displayText="You have successfully Signup"
            company_name="bcd"
            closeOutside={true}
            redirectLink="/login/home-owner"
            onHide={() => setopendel(false)}
          />
        </div>
      </ScrollBar>
    </div>
  );
};

export default Signup;
