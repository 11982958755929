import "./index.css";

import React, { useEffect, useState } from "react";
import { getErrorMsg, showToast } from "../../shared/helpers";
import { useHistory, useLocation } from "react-router-dom";

import Calendly from "./Calendly";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { getInspectionbyId } from "../../../services/InspectionService";
import { useSelector } from "react-redux";

export default function ThirdPartyPayment() {
  const history = useHistory();
  const locationCurrent = useLocation();
  const [isLoading, setisLoading] = useState(false);

  const { inspection_id, reschedule } = locationCurrent.state || {};
  const userInfo = useSelector((state) => state.user.data);

  if (locationCurrent.pathname.includes("calendly")) {
    if (!inspection_id) {
      history.push("/");
    }
  }

  const [pageScreenDescription, setpageScreenDescription] = useState(
    (locationCurrent.pathname.includes("payment") && "WIT / Self Inspection") ||
      (locationCurrent.pathname.includes("qrcode") &&
        "Download our app to start your inspection") ||
      (locationCurrent.pathname.includes("calendly") &&
        "Set a Call with a We Inspect Empowerment Coach to discuss the next steps for an inspection!")
  );
  const [pagePricing, setpagePricing] = useState("");

  useEffect(() => {
    if (inspection_id) {
      getInspection();
    }
  }, []);

  const getInspection = () => {
    setisLoading(true);

    getInspectionbyId(inspection_id, userInfo?.role)
      .then((resp) => {
        console.log("hi there", resp);
        if (resp.data.calenly_id) {
          history.push("/inspections?page=1");
        }
      })

      .catch((err) => {
        showToast(getErrorMsg(err) || "Unable to get inspection.");
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  return (
    <>
      <ScrollBar style={{ height: "100vh" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="half-background d-flex">
            <div className="first-half d-flex justify-content-center align-items-center">
              <div className="text-half">
                <img
                  alt="wit-logo"
                  width="290px"
                  src={require(`../../../assets/logo/logo_sidenav.png`).default}
                />
                <p
                  className={` ${
                    pagePricing && "letter-spacing"
                  } page-description`}
                >
                  {pageScreenDescription}
                </p>
                {pagePricing && locationCurrent.pathname.includes("payment") ? (
                  <p className="page-subprice">{pagePricing}</p>
                ) : null}
              </div>
            </div>

            <div className="second-half d-flex justify-content-center align-items-center">
              {/* {(locationCurrent.pathname.includes("payment") && (
                <StripePayment
                  inspection_id={inspection_id}
                  customer_id={customer_id}
                  inspectType={inspectType}
                  ProductData={ProductData}
                  userRole={userInfo.role}
                  calendly_id={calendly_id}
                  location={location}
                />
              )) 
              
              ||
                (locationCurrent.pathname.includes("qrcode") && <QRcode />) || */}
              {/* {locationCurrent.pathname.includes("calendly") && ( */}
              <Calendly
                inspection_id={inspection_id}
                reschedule={reschedule}
                userRole={userInfo.role}
              />
              {/* )} */}
            </div>
          </div>
        )}
      </ScrollBar>
    </>
  );
}
