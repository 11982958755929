import "../style.css";

import { Link } from "react-router-dom";
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import CustomField from "../../../components/CustomField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessModal from "../../../components/SuccessModal";
import WITLogo from "../../../../assets/icons/forgot-img@2x.png";
import emailIcon from "../../../../assets/icons/email@2x.png";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useForgotPassword } from "./useForgotPassword";
import { regexes } from "../../../shared/regexes";

const ForgetPassword = (props) => {
  const [
    email,
    setEmail,
    errorText,
    setErrorText,
    errors,
    seterrors,
    FormSubmit,
    setFormSubmit,
    location,
    handleSubmit,
    disableReset,
    opendel,
    role,
    SuccessMsg,
    history,
    setopendel,
  ] = useForgotPassword();

  return (
    <div className="login-page">
      <div className="text-center">
        <img
          alt="WIT-logo"
          width="130px"
          style={{
            marginBottom: errorText !== "" && "20px",
          }}
          className="WIT-logo"
          src={WITLogo}
        />
      </div>

      <p className="forgot-h1 mb-1">Forgot your password?</p>

      <div className="reset-password-text" style={{ width: "319px" }}>
        Enter your email address and we'll send you a link to reset your
        password.
      </div>
      <div style={{ display: errorText !== "" ? "block" : "none" }}>
        <div className="email-pass-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-4 font-bold">{errorText}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={`${errorText !== "" && "none"}`}>
          <CustomField
            name="email"
            value={email}
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            id="login_email"
            type="text"
            minWidth="410px"
            errors={
              FormSubmit &&
              (!email ||
                !regexes.email.test(email) ||
                (errors?.email && errors?.email[0]))
            }
            autoFocus
            icon={emailIcon}
            inputProps={{
              autoFocus: "autoFocus",
            }}
          />
          <small
            className="text-danger ml-1"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && email === ""
              ? "Please enter an email address"
              : errors?.email
              ? errors?.email[0]
              : ""}
          </small>
        </div>

        <div className="mt-4 text-align-end">
          <button
            disabled={disableReset}
            variant="primary"
            className={
              disableReset
                ? "login-btn disabled-bg-color"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
      <div
        style={{
          paddingTop: "55px",
        }}
      >
        <Link
          to={
            (location.pathname === "/forget-password" && `/login/home-owner`) ||
            (location.pathname === "/forget-password/account-manager" &&
              `/login/account-manager`) ||
            (location.pathname === "/forget-password/product-owner" &&
              `/login/product-owner`)
          }
          className="new-tab-link back-text"
        >
          Back to Login
        </Link>
      </div>
      <SuccessModal
        show={opendel}
        displayText={SuccessMsg}
        company_name="bcd"
        closeOutside={true}
        redirectLink={
          (role === "super_admin" && "/login/product-owner") ||
          (role === "customer" && "/login/home-owner") ||
          (role === "admin" && "/login/account-manager") ||
          (role === "inspector" && "")
        }
        onHide={() => setopendel(false)}
      />
    </div>
  );
};

export default ForgetPassword;
