import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getUsers } from "../../../services/UserService";
import Paginator from "../../components/Paginator/Paginator";
import {
  phoneNumberFormat,
  roleToText,
  truncate,
  useDidUpdateEffect,
  utcDate,
} from "../../shared/helpers";

const UsersComponent = (props) => {
  const { user } = props;
  const history = useHistory();

  const userLink = (id, userRole) => {
    history.push({ pathname: `/users/` + id, state: { userRole: userRole } });
  };

  return Object.keys(user).length !== 0 && user?.data?.length !== 0 ? (
    user.data?.map((item, i) => (
      <tr
        key={`user-${i}`}
        style={{ cursor: "pointer" }}
        onClick={() => userLink(item?.id, item?.role)}
      >
        <td className="">
          <span>{utcDate(item?.created_at)}</span>
        </td>
        <td>
          <span>{roleToText(item.role)}</span>
        </td>
        <td className="">
          <span> {truncate(item?.first_name + " " + item?.last_name)}</span>
        </td>
        <td className="">{truncate(item?.email, 30)}</td>

        <td className="">
          <span>
            {" "}
            {item?.phone_number ? (
              phoneNumberFormat(item)
            ) : (
              <span style={{ paddingInline: "10px" }}>---</span>
            )}
          </span>
        </td>
        <td>
          <div>
            <button className="next-btn ripple">
              <img
                alt="arrow"
                src={require("../../../assets/icons/arrow@2x.png").default}
              />
            </button>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No record found</td>
    </tr>
  );
};

export default function Users() {
  const [loader, setloader] = useState(true);
  const [users, setusers] = useState({ data: [] });
  const userInfo = useSelector((state) => state.user.data);
  const searchField = useSelector((state) => state.header.searchField);

  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const pageNumber = query.get("page");
  console.log(pageNumber, "queryqueryqueryqueryqueryqueryquery");

  const [page, setpage] = useState(pageNumber);
  console.log(page, "pagepagepagepagepage");

  useEffect(() => {
    setpage(pageNumber);
  }, [pageNumber]);

  useDidUpdateEffect(() => {
    setloader(true);
    getUsers(userInfo?.role, 1, searchField)
      .then((x) => {
        console.log(x.data);
        setusers(x.data);
        setpage(1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  }, [searchField]);

  useEffect(() => {
    setloader(true);
    getUsers(userInfo?.role, page, searchField)
      .then((x) => {
        console.log(x.data);
        setusers(x.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setloader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <div className="main">
        {/* code to un-comm */}
        <div className="" style={{ padding: "25px 20px 25px 20px" }}>
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "calc(100vh - 200px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    height: "calc(100vh - 200px)",
                    paddingRight: "10px",
                  }
            }
          >
            <div>
              {loader ? (
                <>
                  <div>
                    <Spinner animation="border" variant="success" />
                  </div>
                </>
              ) : (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Date Created</th>
                      <th>Role</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UsersComponent role={userInfo?.role} user={users} />
                  </tbody>
                </table>
              )}
            </div>
          </ScrollBar>
          {!loader &&
          Object.keys(users).length !== 0 &&
          users?.data?.length !== 0 ? (
            <nav className="mt-4 mb-3">
              <span className="pagination justify-content-center mb-0 paginator">
                <Paginator
                  activePage={users?.current_page}
                  itemsCountPerPage={users?.per_page}
                  totalItemsCount={users?.total}
                  onChange={(pages) => {
                    // setpage(page);
                    history.push(`/users?page=${pages}`);
                  }}
                />
              </span>
              <span className="total-align">
                Showing {users?.from} to {users?.to} of {users?.total} entries{" "}
              </span>
            </nav>
          ) : null}
        </div>
      </div>
    </>
  );
}
