import "./searchBox.css";

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import { getAddress } from "../../shared/helpers";

const { Option } = components;

function SearchDropdown(props) {
  const {
    apiCall,
    apiCallPropsOne,
    saveProp,
    showProp,
    placeHolder,
    onSelect,
    complicated,
    disabled,
    RadioIcon,
    optionsData,
    user_role,
    defaultOptionValue,
    customHeight,
    pivotID,
    testUI,
    showErrors,
    showStatus,
    isSearch = false,
    menuPlacement = "bottom",
  } = props;
  const [value, onChange] = useState(props?.value ? props.value : null);
  const [defaultValue, setDefaultValue] = useState();
  const [inputValueSearch, setinputValueSearch] = useState("");
  useEffect(() => {
    if (complicated) {
      console.log("force update");
    }
  }, [props.value]);

  useEffect(() => {
    if (defaultOptionValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    var val = null;
    let filteredOptions;
    let hasMore;

    if (apiCall) {
      var totalpage = { current_page: null, last_page: null };

      await apiCall(
        page,
        user_role && user_role,
        apiCallPropsOne && apiCallPropsOne,
        search
      )
        .then((x) => {
          totalpage = {
            current_page: x.data.current_page,
            last_page: x.data.last_page,
          };

          // console.log(x.data.last_page);
          val = x.data.data.map((d) => {
            let temp = "";
            if (!complicated) {
              if (pivotID) {
                temp = d?.pivot;
                return {
                  value: temp[saveProp],
                  label: temp?.description,
                  status: temp["observations_status"],
                };
              } else if (testUI) {
                temp = d;
                return {
                  value: temp[saveProp],
                  label: temp?.description,
                };
              } else {
                showProp.forEach((element) => {
                  temp = temp + d[element] + " ";
                });
              }
            } else {
              temp = getAddress(d?.address);
              return {
                value: d[saveProp],
                label: temp,
                check: d?.address?.check,
              };
            }
            return {
              value: d[saveProp],
              label: temp,
            };
          });
        })
        .catch((err) => console.log(err));
      !defaultValue &&
        setDefaultValue(val.find((o) => o.label.includes(defaultOptionValue)));

      // if (showErrors) {
      //   console.log(defaultOptionValue, "defaultOptionValuedefaultOptionValue");
      //   if (!val.find((o) => o.label.includes(defaultOptionValue))) {
      //     showToast(showErrors);
      //   }
      // }
      if (!search) {
        filteredOptions = val;
      } else {
        const searchLower = search.toLowerCase();
        filteredOptions = val.filter(({ label }) =>
          label.toLowerCase().includes(searchLower)
        );
      }

      hasMore = totalpage?.current_page >= totalpage?.last_page ? false : true;
      console.log(totalpage, "pages");
    } else {
      !defaultValue &&
        setDefaultValue(
          optionsData.find((o) => o.value.includes(defaultOptionValue))
        );

      filteredOptions = optionsData;
    }

    return {
      options: filteredOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  useEffect(() => {
    setinputValueSearch("");
  }, [onSelect]);

  const boldQuery = (str) => {
    const n = str?.toUpperCase();
    const q = inputValueSearch?.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    const l = q.length;
    return (
      str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
    );
  };
  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex align-items-center">
        {" "}
        <span>
          {" "}
          <img
            src={
              props?.data?.value === value?.value
                ? require("../../../assets/icons/radio@2x.png").default
                : require("../../../assets/icons/radio1@2x.png").default
            }
            style={{ paddingRight: "15px" }}
            alt={props.data.label}
          />
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: boldQuery(props.data.label),
          }}
        ></span>
      </div>
    </Option>
  );

  const LabelData = (props) => (
    <Option {...props}>
      <div
        className="d-flex align-items-center"
        style={{
          color:
            showStatus &&
            props.data.status === "completed" &&
            "rgb(100, 188, 81)",

          fontWeight: showStatus && props.data.status === "completed" && "bold",
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: boldQuery(props.data.label),
          }}
        ></span>
      </div>
    </Option>
  );

  return (
    <div disabled={disabled ? true : false}>
      <AsyncPaginate
        // className="select-custom"
        classNamePrefix="react-select-custom"
        isSearchable={isSearch}
        components={
          RadioIcon
            ? {
                Option: IconOption,
              }
            : {
                Option: LabelData,
              }
        }
        // menuIsOpen={true}
        menuPlacement={menuPlacement}
        // debounceTimeout={}
        value={value}
        onInputChange={(inputValue, { action }) => {
          console.log(action, "---------------------------", inputValue);
          if (action === "input-change") {
            setinputValueSearch(inputValue);
          }
        }}
        loadOptions={loadOptions}
        onChange={(val) => {
          onChange(val);
          onSelect(val);
        }}
        defaultOptions
        // defaultOptions={defaultOptionValue ? true : false}
        // menuIsOpen={true}
        placeholder={placeHolder}
        additional={{
          page: 1,
        }}
        maxMenuHeight={customHeight ? customHeight : 180}
        isDisabled={disabled ? true : false}
      />
    </div>
  );
}
export default SearchDropdown;
