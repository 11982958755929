import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getUserByID, updateUserByID } from "../../../../services/UserService";
import { getErrorMsg, setHeader, showToast } from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import AddHomeOwner from "../AddHomeOwner";
import AddInspectorNAccountManager from "../AddInspectorNAccountManager";
import UserInfoTable from "./UserInfoTables";

const UserInfo = ({
  userId,
  userInfo,
  userHomeOwner,
  role,
  errors,
  setuserHomeOwner,
  setuserDetails,
  userDetails,
  seterrors,
  imgUser,
  setrole,
}) => {
  const [isSubmit, setisSubmit] = useState(false);
  const [disbutton, setdisbutton] = useState(false);
  const [editEnabled, seteditEnabled] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const formData = new FormData();

  const EditInspection = () => {
    setisSubmit(true);
    if (
      role !== "customer" &&
      userDetails.first_name &&
      userDetails.first_name.trim() &&
      userDetails.last_name &&
      userDetails.last_name.trim() &&
      userDetails.phone_number &&
      userDetails?.phone_number.length > 10 &&
      userDetails.phone_number.trim() &&
      userDetails.email &&
      regexes.email.test(userDetails?.email) &&
      userDetails.email.trim()
    ) {
      formData.append("first_name", userDetails?.first_name);
      formData.append("last_name", userDetails?.last_name);
      formData.append("email", userDetails?.email);
      formData.append("_method", "PATCH");

      formData.append("phone_number", userDetails?.phone_number);
      if (userDetails?.notes !== undefined && userDetails?.notes !== null) {
        formData.append("notes", userDetails?.notes);
      }

      if (userDetails?.avatar !== imgUser) {
        console.log("imgUserimgUserimgUserimgUser", imgUser);
        formData.append("avatar", userDetails?.avatar);
      }
      setdisbutton(true);
      EditUser(formData);
    } else if (
      role === "customer" &&
      userHomeOwner.first_name &&
      userHomeOwner.first_name.trim() &&
      userHomeOwner.last_name &&
      userHomeOwner.last_name.trim() &&
      userHomeOwner.phone_number &&
      userHomeOwner?.phone_number.length > 10 &&
      userHomeOwner.phone_number.trim() &&
      userHomeOwner.email &&
      regexes.email.test(userHomeOwner?.email) &&
      userHomeOwner.email.trim() &&
      userHomeOwner.address1 &&
      userHomeOwner.address1.trim() &&
      userHomeOwner.city &&
      userHomeOwner.city.trim() &&
      userHomeOwner.state &&
      userHomeOwner.zipcode &&
      userHomeOwner.zipcode.length > 4 &&
      userHomeOwner.zipcode.trim()
    ) {
      setdisbutton(true);

      formData.append("first_name", userHomeOwner?.first_name);
      formData.append("last_name", userHomeOwner?.last_name);
      formData.append("email", userHomeOwner?.email);
      formData.append("phone_number", userHomeOwner?.phone_number);

      formData.append("address1", userHomeOwner?.address1);
      formData.append("city", userHomeOwner?.city);
      formData.append("state", userHomeOwner?.state);
      formData.append("zipcode", userHomeOwner?.zipcode);
      if (
        userHomeOwner?.address2 !== null &&
        userHomeOwner?.address2 !== "null" &&
        userHomeOwner?.address2 !== undefined &&
        userHomeOwner?.address2 !== "undefined"
      ) {
        formData.append("address2", userHomeOwner?.address2);
      }
      formData.append("_method", "PATCH");

      if (userHomeOwner?.avatar !== imgUser) {
        formData.append("avatar", userHomeOwner?.avatar);
      }

      setdisbutton(true);
      EditUser(formData);
    }
  };
  const EditUser = (formData) => {
    updateUserByID(userInfo?.role, userId, formData)
      .then((res) => {
        seterrors({});

        setUserName(res.data);

        showToast("Your information has been updated!", true);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          seterrors(err?.response?.data?.errors);
          showToast(err?.response?.data?.message);
        } else {
          showToast(getErrorMsg(err));
        }
      })
      .finally(() => {
        setisLoading(false);
        seteditEnabled(false);
        setdisbutton(false);
      });
  };
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  const getUser = () => {
    setisLoading(true);
    getUserByID(userInfo?.role, userId)
      .then((res) => {
        setUserName(res.data);

        if (res.data) {
          setrole(res.data.role);
          if (res.data.role === "customer") {
            setuserHomeOwner({ ...res?.data?.address, ...res.data });
          } else {
            setuserDetails(res?.data);
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          seterrors(err?.response?.data?.errors);
          showToast(err?.response?.data?.message);
        } else {
          showToast(getErrorMsg(err));
        }
      })
      .finally(() => setisLoading(false));
  };

  const setUserName = (info) => {
    setHeader(dispatch, {
      title: `${info?.first_name} ${info?.last_name}`,
      rating: info?.role === "admin" ? false : info?.rating,
    });
  };
  const onEditView = () => {
    seteditEnabled(true);
    if (role === "customer") {
      setHeader(dispatch, {
        title: `Edit Home Owner`,
        rating: false,
      });
    } else if (role === "admin") {
      setHeader(dispatch, {
        title: `Edit Account Manager`,
        rating: false,
      });
    } else {
      setHeader(dispatch, {
        title: `Edit Inspector`,
        rating: false,
      });
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="center-spinner">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <>
              {editEnabled ? (
                <>
                  {" "}
                  <button
                    className="btn btn-primary ml-auto"
                    disabled={disbutton}
                    style={{ paddingInline: "55px" }}
                    onClick={EditInspection}
                    id="save_insp"
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary"
                    disabled={disbutton}
                    style={{
                      background: "transparent",
                      borderColor: "#14444d",
                      paddingInline: "47px",
                      marginLeft: "16px",
                    }}
                    onClick={() => {
                      getUser();
                      seteditEnabled(false);
                    }}
                    id="cancel_user_info"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-primary ml-auto"
                  disabled={disbutton}
                  onClick={() => {
                    onEditView();
                  }}
                  id="edit_inps"
                  style={{ paddingInline: "30px" }}
                >
                  <img
                    alt="Edit insp"
                    src={
                      require(`../../../../assets/icons/edit@2x.png`).default
                    }
                  />
                  Edit Info
                </button>
              )}
            </>
          </div>
          <div>
            {role === "customer" ? (
              <AddHomeOwner
                userHomeOwner={userHomeOwner}
                setuserHomeOwner={setuserHomeOwner}
                errors={errors}
                showRole={true}
                emailDisabled={true}
                disabled={!editEnabled}
                isSubmit={isSubmit}
                setdisbutton={setdisbutton}
                // seteditEnabled={seteditEnabled}
                // setDisableSave={setDisableSave}
              />
            ) : (
              <AddInspectorNAccountManager
                userDetails={userDetails}
                setuserDetails={setuserDetails}
                errors={errors}
                emailDisabled={true}
                disabled={!editEnabled}
                showRole={true}
                role={role}
                isSubmit={isSubmit}
                setdisbutton={setdisbutton}
                // setDisableSave={setDisableSave}
              />
            )}
          </div>

          {!editEnabled && (
            <>
              <div className="row mt-4">
                <div className="col-md-6 d-flex align-items-center height-insp-text">
                  {" "}
                  <h4>{role === "customer" ? "Properties" : "Inspections"}</h4>
                </div>
                <div className="col-md-6 d-flex">
                  <Link
                    className="ml-auto"
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname:
                        role === "customer"
                          ? "/properties/new"
                          : "/inspections/new",
                      state: {
                        customer_id: role === "admin" ? null : userId,
                        first_name:
                          role === "customer"
                            ? userHomeOwner.first_name
                            : role === "admin"
                            ? null
                            : userDetails.first_name,
                        last_name:
                          role === "customer"
                            ? userHomeOwner.last_name
                            : role === "admin"
                            ? null
                            : userDetails.last_name,
                      },
                    }}
                    id="create_inspection"
                  >
                    <button className="btn btn-primary">
                      <img
                        alt="create_inspection"
                        src={
                          require(`../../../../assets/icons/add@2x.png`).default
                        }
                      />
                      Add {role === "customer" ? "Properties" : "Inspections"}
                    </button>
                  </Link>
                </div>
              </div>
              {role !== "admin" && (
                <UserInfoTable userId={userId} role={role} />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UserInfo;
