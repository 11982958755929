/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Header from "../main/components/Header";
import SideNav from "../main/components/SideNav";
import {
  isAuthenticated,
  permissions,
  setHeader,
} from "../main/shared/helpers";
import {
  LOGOUT_USER,
  LOG_OUT,
  SET_SEARCH_LABEL,
  SHOW_GOBACK,
  SHOW_SEARCH,
} from "../store/actions";
import routes, { authRoles } from "./route-config";
import RouteCustom from "./route-custom";
import "./style.css";

let combinedRoutes = [];
routes.forEach((r) => {
  if (r.path === "/tools") {
    const shallowR = { ...r };
    const children = shallowR.childrens;
    delete shallowR.children;
    combinedRoutes.push(
      ...children.map((c) => ({ ...shallowR, ...c, path: r.path + c.to }))
    );
  } else {
    combinedRoutes.push(r);
  }
});
// console.log("combinedRoutes", combinedRoutes);

const AppRouter = () => {
  const role = useSelector((state) => state.user.data.role);
  const accessibleRoutes = useSelector((state) => state.user.data.roles);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [fallbackUri, setFallbackUri] = useState("");
  const userInfo = useSelector((state) => state.user.data);
  console.log(role, "rolerolerolerolerolerole");
  const userToken = localStorage.getItem("access_token");
  // console.log(userToken, "userTokenuserTokenuserToken");

  useEffect(() => {
    setFallbackUri(
      `${appropriateFallbackUri(
        role,
        userInfo,
        userInfo.updated_at !== null && userInfo.flags,
        accessibleRoutes
      )}`
    );
    // console.log("use effect role", role, userInfo);
    let tempRoutes;
    if (role === undefined) {
      tempRoutes = routes.filter((route) => {
        return route.roles.length === 0 || route.isProtected === "both";
      });
    } else {
      if (
        role === authRoles.bu_homeowner ||
        role === authRoles.superAdmin ||
        role === authRoles.buAdmin
      ) {
        tempRoutes = routes.filter((route) => {
          return route.isProtected === "both" || route.roles.includes(role);
        });
      } else if (role === authRoles.buSubAdmin) {
        // console.log("bu sub admin");
        console.log(permissions);

        const availablePaths = permissions.filter((pr) =>
          accessibleRoutes.some((ar) => ar.alias === pr.value)
        );
        console.log("availablePaths", availablePaths);

        tempRoutes = routes.filter((route) => {
          return (
            route.isProtected === "both" ||
            availablePaths.some((i) => route.path.startsWith(i.routePath))
          );
        });
      }
    }
    setAvailableRoutes([...tempRoutes]);
  }, [role, userToken]);

  return (
    <Router>
      {isAuthenticated() && <HeaderandSideNav />}
      <Switch>
        {availableRoutes.map((route, index) => {
          if (route.hasChildren) {
            return route.childrens.map((child, i) => (
              <RouteCustom
                key={`route-${i}`}
                path={route.path + child.to}
                exact={route.exact}
                component={route.component}
                isProtected={route.isProtected}
                role={role}
              >
                <child.component />
              </RouteCustom>
            ));
          }
          return (
            <RouteCustom
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              isProtected={route.isProtected}
              role={role}
            >
              {/* {console.log(, "route")} */}
              {route?.component && <route.component />}
            </RouteCustom>
          );
        })}
        {availableRoutes.length > 0 ? <Redirect to={fallbackUri} /> : null}
      </Switch>
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        toastOptions={{
          duration: 3000,
        }}
      />
    </Router>
  );
};

export function HeaderandSideNav() {
  const [showNavHeader, setShowNavHeader] = useState(false);
  const [showSideNav, setshowSideNav] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const logout = useSelector((state) => state.app.logout);
  const role = useSelector((state) => state.user.data.role);

  const history = useHistory();

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (!e.key && !e.oldValue && !e.newValue) {
        localStorage.clear();
        dispatch({
          type: LOG_OUT,
          payload: {},
        });
        dispatch({
          type: LOGOUT_USER,
          payload: {
            logout: true,
          },
        });
        if (role === "super_admin") {
          history.replace("/login/product-owner");
        } else if (role === "admin") {
          history.replace("/login/account-manager");
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [logout]);

  useEffect(() => {
    const currentRouteObject = combinedRoutes.find((item) => {
      if (item.path.includes(":")) {
        return location.pathname.startsWith(item.path.split(":")[0]);
      } else {
        return location.pathname === item.path;
      }
    });
    // console.log(currentRouteObject);

    // isAuthenticated()
    console.log(
      currentRouteObject,
      "currentRouteObjectcurrentRouteObjectcurrentRouteObject",
      location.pathname
    );
    if (currentRouteObject?.hideNavigation) {
      console.log("here is a test");
      setShowNavHeader(false);
      setshowSideNav(false);
    } else if (
      !currentRouteObject?.hideNavigation ||
      currentRouteObject?.hideSideNav
    ) {
      console.log("this creates the bug");
      setShowNavHeader(true);
      if (currentRouteObject?.hideSideNav) {
        setshowSideNav(false);
      } else {
        setshowSideNav(true);
      }
      if (currentRouteObject?.name) {
        setHeader(dispatch, {
          title: currentRouteObject.name,
          previousPath: "",
          iconWidth: currentRouteObject?.iconWidth,
          icon: currentRouteObject.icon,
          rating: currentRouteObject.rating ? currentRouteObject.rating : "",
        });
      }
    }

    //show go back button
    if (currentRouteObject?.showGoBack === true) {
      dispatch({ type: SHOW_GOBACK, payload: { showGoback: true } });
    } else {
      dispatch({ type: SHOW_GOBACK, payload: { showGoback: false } });
    }
    //show search component
    if (currentRouteObject?.showSearch === true) {
      dispatch({ type: SHOW_SEARCH, payload: true });
      dispatch({ type: SET_SEARCH_LABEL, payload: currentRouteObject?.name });
    } else {
      dispatch({ type: SHOW_SEARCH, payload: false });
    }
  }, [location.pathname]);
  return (
    <React.Fragment>
      {showNavHeader && <Header className="header" />}
      {showSideNav && <SideNav className="side-nav" />}
    </React.Fragment>
  );
}

function appropriateFallbackUri(role) {
  if (!role) {
    localStorage.clear();
  }
  if (!isAuthenticated() || !role) {
    return "/login/home-owner";
  } else {
    if (role === authRoles.superAdmin || role === authRoles.buAdmin) {
      return "/properties";
    } else if (
      role === authRoles.bu_homeowner ||
      role === authRoles.buSubAdmin
    ) {
      return "/properties";
    } else {
      return "";
    }
  }
}

export default AppRouter;
