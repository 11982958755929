import { Spinner, Tab, Tabs } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import GeneralInfo from "./GeneralInfo";
import { INSPECTION_DISABLE } from "../../../../store/actions";
import Observation from "./Observation/Observation";
import Reviews from "./Reviews";
import ScrollBar from "react-perfect-scrollbar";
import TestRecommended from "./TestRecommended/TestRecommended";
import { getInspectionbyId } from "../../../../services/InspectionService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function InspectionDetails() {
  const userInfo = useSelector((state) => state.user.data);

  const [key, setKey] = useState();
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const { insp_id } = useParams();
  const [generalInfo, setgeneralInfo] = useState({});

  const [inspectionReview, setinspectionReview] = useState();
  let history = useHistory();
  let location = useLocation();
  const search = useLocation().search;
  let queryLocation = new URLSearchParams(search).get("tab");
  useEffect(() => {
    setKey(queryLocation || "generalInfo");
    // if()
    console.log(queryLocation, "location.pathname");
    if (!queryLocation) {
      history.push(`${location.pathname}?tab=generalInfo`);
    } else if (!queryLocation?.includes("generalInfo")) {
      getInsptions();
    }
  }, [queryLocation]);

  const getInsptions = () => {
    // setloader(true);

    getInspectionbyId(insp_id, userInfo?.role)
      .then((x) => {
        // setdata(x.data);
        setgeneralInfo(x.data);
        console.log(x.data);
        // setloader(false);

        if (
          x.data.status === "completed" ||
          x.data.paid_status === "un_paid" ||
          x.data.deleted_at !== null
        ) {
          dispatch({
            type: INSPECTION_DISABLE,
            payload: { disable: true },
          });
        } else {
          dispatch({
            type: INSPECTION_DISABLE,
            payload: { disable: false },
          });
        }
      })
      .catch((err) => {
        setloader(false);
        if (err?.response?.status === 404) {
          console.log("-------------------------------------------------");
        }
        console.log(err);
      });
  };
  return (
    <div className="main">
      <div
        style={{
          background: "#f4eadf",
          display: "block",
          // display: "flex",
          marginTop: "90px",
          minHeight: "calc(100vh - 90px)",
          height: "80vh",
        }}
        className="d-center-div"
      >
        {loader ? (
          <div className="center-spinner">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div
            className=""
            style={key === "observation" ? { background: "white" } : {}}
          >
            <Tabs
              className="setting-tab-btns"
              defaultActiveKey="generalInfo"
              // onSelect={(k) => setKey(k)}
              transition={false}
              activeKey={key}
              onSelect={(k) => {
                history.push(`${location.pathname}?tab=${k}`);
              }}
            >
              <Tab eventKey="generalInfo" title="General Info">
                <div
                  style={{ height: "calc(100vh - 140px)", overflowY: "scroll" }}
                >
                  {key === "generalInfo" && <GeneralInfo />}
                </div>
              </Tab>
              <Tab eventKey="observation" title="Observations/ Tasks">
                {key === "observation" && (
                  <div
                    style={{
                      height: "calc(100vh - 140px)",
                      overflowY: "auto",
                    }}
                  >
                    <Observation
                      insp_id={insp_id}
                      userInfo={userInfo}
                      generalInfo={generalInfo}
                    />
                  </div>
                )}
              </Tab>
              <Tab eventKey="TestRecommended" title="Recommended Tests">
                <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
                  {key === "TestRecommended" && (
                    <TestRecommended
                      insp_id={insp_id}
                      generalInfo={generalInfo}
                      userInfo={userInfo}
                    />
                  )}
                </ScrollBar>
              </Tab>
              <Tab eventKey="Reviews" title="Reviews">
                <ScrollBar style={{ height: "calc(100vh - 140px)" }}>
                  {key === "Reviews" && (
                    <Reviews
                      reviewed={inspectionReview}
                      insp_id={insp_id}
                      userInfo={userInfo}
                      generalInfo={generalInfo}
                    />
                  )}
                </ScrollBar>
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}
