import "./style.css";

import {
  addRooms,
  checkValuesSubmit,
  initialState,
  redirectLinks,
} from "./InspectionHelper";
import { getErrorMsg, roleNames, showToast } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import InspectionsFields from "./InspectionsFields";
import { LOADER_STATE } from "../../../store/actions";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { createInspection } from "../../../services/InspectionService";

const AddInspection = () => {
  const { insp_id } = useParams();
  const [isLoading, setisLoading] = useState(insp_id ? true : false);
  const showLoader = useSelector((state) => state.app.loader_state);

  const dispatch = useDispatch();
  const location = useLocation();

  const [isSubmit, setisSubmit] = useState(false);
  const [errors, seterrors] = useState({});
  const [disbutton, setdisbutton] = useState(true);

  // delete
  const [deleteRooms, setdeleteRooms] = useState([]);

  //confirmationModal
  const [confirmModal, setConfirmModal] = useState(false);

  let newdata = {};
  if (location.state) {
    newdata = [
      {
        id: location.state?.customer_id ? location.state.customer_id : null,

        first_name: location?.state?.first_name
          ? location.state.first_name
          : null,
        last_name: location?.state?.last_name ? location.state.last_name : null,
      },
    ];
  }
  const [inspection, setInspection] = useState(initialState(location, newdata));

  const [customerName, setcustomerName] = useState();
  const userInfo = useSelector((state) => state.user.data);
  const [editEnabled, seteditEnabled] = useState(insp_id ? false : true);
  const history = useHistory();

  useEffect(() => {
    if (
      inspection.rooms.length > 0 &&
      inspection.property !== undefined &&
      inspection.type !== ""
    ) {
      setdisbutton(false);
    } else {
      setdisbutton(true);
    }
  }, [inspection]);

  const payloadData = (rID, rName) => {
    let data = {
      notes: inspection.notes,
      type: inspection.type,
      property_id: inspection.property,
      room_ids: rID,
      room_names: rName,
    };
    if (
      userInfo?.role !== "customer" &&
      inspection.type === "wit_inspect" &&
      inspection.inspector_id
    ) {
      data = { ...data, inspector_id: inspection.inspector_id };
    }
    if (userInfo?.role === roleNames.bu_homeowner) {
      data = { ...data, paid_status: "un_paid" };
    } else {
      data = { ...data, paid_status: "paid" };
    }
    return data;
  };
  const submit = () => {
    setisSubmit(true);

    if (inspection?.rooms?.length > 0) {
      let rID = [];
      let rName = [];
      addRooms(inspection, rID, rName).then(() => {
        if (checkValuesSubmit(inspection, rID, rName)) {
          setdisbutton(true);

          createInspection(userInfo?.role, payloadData(rID, rName))
            .then((resp) => {
              if (resp.data) {
                showToast("Inspection created successfully.", true);
                redirectLinks(resp.data, userInfo, "edit", history);
                if (userInfo?.role !== roleNames.bu_homeowner) {
                  history.push("/inspections?page=1");
                }
              }
            })
            .catch((err) => {
              if (err?.response?.status === 422) {
                seterrors(err?.response?.data?.errors);
              } else {
                showToast(getErrorMsg(err) || "Unable to create inspection.");
              }
            })
            .finally(() => {
              setdisbutton(false);
            });
        }
      });
    } else {
      if (showLoader) {
        dispatch({
          type: LOADER_STATE,
          payload: false,
        });
        showToast("Please add a room / item", false, dispatch);
      }
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <>
            <div className="main-content-area">
              <div className="d-flex align-items-center">
                {insp_id ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      if (location?.state?.inspection_id) {
                      } else {
                        setConfirmModal(true);
                      }
                    }}
                    disabled={disbutton}
                    className={`btn btn-primary ml-auto ${
                      (disbutton ||
                        inspection.property === "" ||
                        inspection.type === "") &&
                      "dis-btn"
                    }`}
                  >
                    Save & Continue
                  </button>
                )}
              </div>
              <InspectionsFields
                isSubmit={isSubmit}
                inspection={inspection}
                setInspection={setInspection}
                userInfo={userInfo}
                errors={errors}
                insp_id={insp_id || location?.state?.inspection_id}
                setcustomerName={setcustomerName}
                customerName={customerName}
                setdeleteRooms={setdeleteRooms}
                deleteRooms={deleteRooms}
                editEnabled={editEnabled}
              />
            </div>
          </>
        )}
      </ScrollBar>

      {confirmModal && (
        <ConfirmationModal
          show={confirmModal}
          onHide={() => setConfirmModal(false)}
          imgSrc={require("../../../assets/icons/delete.png").default}
          helperText="Are you sure you want to create a new inspection?"
          onSave={() => {
            submit();
          }}
        />
      )}
    </div>
  );
};

export default AddInspection;
