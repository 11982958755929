import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import App from "./App";
import axios from "./axios-defaults";
import reportWebVitals from "./reportWebVitals";
import Store from "./store/reducers";

import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("access_token")}`;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);
reportWebVitals();
