import { useLocation } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import {
  getAddress,
  insp_type,
  itemsIds,
  showToast,
} from "../../shared/helpers";
import { getPropertyByCustomer } from "../../../services/PropertyService";
import { useDispatch, useSelector } from "react-redux";

import CustomField from "../../components/CustomField";
import Delete from "../../components/SubItemDeleteModal";
import { LOADER_STATE } from "../../../store/actions";
import SearchDropdown from "../../components/SelectDropdown";
import SearchDropdownRadioIcon from "../../components/SearchDropdownRadioIcon";
import { getInspectors } from "../../../services/InspectionService";

const AddRoomModal = React.lazy(() => import("./AddRoomModal"));
const AddItemModal = React.lazy(() => import("./AddItemModal"));

const InspectionsFields = (props) => {
  const {
    isSubmit,
    inspection,
    setInspection,
    errors,
    insp_id,
    editEnabled,
    userInfo,
    setcustomerId,
    setcustomerName,
    customerName,
    locationProperty,
    deleteRooms,
    setdeleteRooms,
  } = props;
  const [modalShow, setmodalShow] = useState(false);
  const [deleteItem, setdeleteItem] = useState();
  const [itemModalShow, setItemModalShow] = useState(false);
  const [deleteRoomID, setdeleteRoomID] = useState(null);
  const [opendel, setopendel] = useState(false);
  const [editState, seteditState] = useState(false);
  const [roomID, setroomID] = useState();

  // edit
  const [editRoomId, seteditRoomId] = useState();

  const location = useLocation();

  const showLoader = useSelector((state) => state.app.loader_state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.property) {
      setInspection({
        ...inspection,
        property: location?.state?.property.id,
      });
      setcustomerName(
        location?.state?.property.customer_first_name +
          " " +
          location?.state?.property.customer_last_name
      );
      // setcustomerId(location?.state?.property.customer_id);
    }
  }, []);

  const propertyDropdown = () => {
    if (location?.state?.property?.id) {
      console.log(location?.state?.property, "333333");
      return {
        value: location?.state?.property.id,
        label: getAddress(location?.state?.property),
      };
    }
    if (insp_id) {
      console.log("111111");
      return {
        value: inspection?.property?.id,
        label: `${getAddress(inspection?.property?.address)} `,
      };
    }
    if (locationProperty?.propertyId) {
      return {
        value: parseInt(locationProperty?.propertyId),
        label: `${getAddress(locationProperty?.address)} `,
      };
    }
  };
  const propertyDropdownId = () => {
    if (insp_id) {
      return inspection?.property?.id;
    }
    if (locationProperty?.propertyId) {
      return locationProperty?.propertyId;
    }
    if (location?.state?.property?.id) {
      return parseInt(location?.state?.property?.id);
    }
  };

  const saveItemCTA = (ev) => {
    let sortID = [];
    let changeID = [];
    console.log(ev);
    inspection?.rooms?.map((ab, i) => {
      if (ev[0]?.id === ab.id || ev[1]?.id === ab.id) {
        sortID.push(i);
        changeID.push(ab.id);
      }
    });
    if (sortID.length === 2) {
      addingItem(ev);
      setInspection({
        ...inspection,
      });
    } else if (sortID.length === 1) {
      addingItem(ev); // replace the existing  values

      let addOther = ev.findIndex((obj) => obj.id !== changeID[0]);
      if (addOther !== -1) {
        setInspection({
          ...inspection,
          rooms: [...inspection?.rooms, ev[addOther]],
        });
      }
    } else {
      setInspection({
        ...inspection,
        rooms: [...inspection?.rooms, ...ev],
      });
    }
  };

  const addingItem = (ev) => {
    ev.map((evProp) => {
      inspection.rooms[
        inspection.rooms.findIndex((obj) => obj.id === evProp?.id)
      ] = {
        id: evProp?.id,
        name: evProp?.name,
        description: evProp?.description,
      };
    });
  };

  const onSaveEditCTA = (ev) => {
    if (ev?.pivot) {
      inspection.rooms[ev?.arrayId] = {
        id: ev.id,
        name: ev.RoomIdName,
        pivot: { id: ev?.pivotId, description: ev.name },
      };
    } else {
      inspection.rooms[ev?.arrayId] = {
        id: ev.id,
        name: ev.RoomIdName,
        description: ev.name,
      };
    }
    setInspection({
      ...inspection,
    });
    seteditRoomId("");
  };

  const itemSaveEditCTA = (ev) => {
    let sortID = [];
    console.log(ev);
    inspection?.rooms?.map((ab, i) => {
      if (ev[0]?.id === ab.id || ev[1]?.id === ab.id) {
        sortID.push(i);
      }
    });
    let saveOther;

    let data = { ...inspection };
    if (sortID.length === 0) {
      setInspection({
        ...inspection,
        rooms: [...inspection?.rooms, ...ev],
      });
    } else {
      ev.map((item) => {
        if (item?.pivot) {
          data.rooms[data.rooms.findIndex((obj) => obj.id === item?.id)] = {
            id: item?.id,
            name: item?.name,
            pivot: {
              id: item?.pivot?.id,
              description: item?.pivot?.description,
            },
          };
        } else {
          saveOther = data.rooms.findIndex((obj) => obj.id === item?.id);

          if (saveOther !== -1) {
            data.rooms[data.rooms.findIndex((obj) => obj.id === item?.id)] = {
              id: item?.id,
              name: item?.name,
              description: item?.description,
            };
          } else {
            data = { ...data, rooms: [...inspection?.rooms, item] };
          }
        }
      });

      setInspection({
        ...data,
      });
    }

    seteditRoomId("");
  };

  const ItemsInList = () => {
    let itemsExists = [];
    inspection?.rooms?.map((ab, i) => {
      if (itemsIds.HVAC === ab?.id || itemsIds.APPLIANCES === ab?.id) {
        itemsExists.push(i);
      }
    });
    if (itemsExists.length === 2 || !inspection?.rooms?.length) {
      return true;
    } else {
      return false;
    }
  };

  const inspectionTypeOption = () => {
    let option;
    if (userInfo?.flags?.clickfunnel_user && !userInfo?.flags?.first_payment) {
      option = [
        {
          value: "self_inspect",
          label: "Self Inspect",
        },
      ];
    } else if (inspection.type === "wit_inspect") {
      option = [
        {
          value: "self_inspect",
          label: "Self Inspect",
        },
        {
          value: "wit_inspect",
          label: "We Inspect Professional",
        },
      ];
    } else {
      option = [
        {
          value: "self_inspect",
          label: "Self Inspect",
        },
        // TODO : "will remove when clients says open inspector mode"
        // also remove else if that write above
        // {
        //   value: "wit_inspect",
        //   label: "We Inspect Professional",
        // },
      ];
    }
    return option;
  };

  return (
    <div>
      <h4>General Details</h4>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="float-label">
              <textarea
                id="additional_remarks"
                className={`padding-without-placeholder ${
                  isSubmit &&
                  (!inspection?.notes ||
                    !inspection?.notes?.trim() ||
                    (errors?.notes && errors?.notes[0])) &&
                  "error-border-textarea"
                }`}
                type="text"
                maxLength="1000"
                placeholder="Notes"
                disabled={insp_id ? (editEnabled ? false : true) : false}
                value={inspection.notes}
                onChange={(e) =>
                  setInspection({ ...inspection, notes: e.target.value })
                }
                style={{ minHeight: "120px" }}
              />
            </div>
            <small className="text-danger ml-1">
              {isSubmit && inspection?.notes?.length === 0
                ? "Notes cannot be empty"
                : isSubmit &&
                  inspection?.notes?.length !== 0 &&
                  !inspection?.notes?.trim()
                ? "Required field cannot be left blank"
                : errors?.notes
                ? errors?.notes[0]
                : ""}
            </small>
          </div>
        </div>

        <div className="col-md-6">
          <h4>Link Property</h4>
          {/* {console.log(value, "000000000")} */}
          <div className="form-group">
            <SearchDropdownRadioIcon
              // key={searchDropdownKey}
              apiCall={getPropertyByCustomer}
              complicated={true}
              saveProp={"id"}
              customer_id={
                inspection?.customer_id
                  ? inspection?.customer_id
                  : "" || location?.state?.property?.customer_id
                  ? location?.state?.property?.customer_id
                  : ""
              }
              user_role={userInfo?.role}
              showProp={["address"]}
              placeHolder="Choose Linked Property"
              value={propertyDropdown()}
              RadioIcon={true}
              disabled={insp_id ? (editEnabled ? false : true) : false}
              onSelect={(ev) => {
                if (setcustomerId) {
                  setcustomerId(ev?.id);
                }
                setcustomerName(ev?.customer);
                console.log(ev, "inspection.type");
                setInspection({
                  ...inspection,
                  property: ev?.value,
                });
              }}
              defaultOptionValue={propertyDropdownId()}
            />
            <small className="text-danger ml-1">
              {isSubmit && !inspection.property
                ? "Please select a property"
                : errors?.property
                ? errors?.property[0]
                : ""}
            </small>
          </div>
        </div>

        <div className="col-md-6">
          <h4>Inspection Type</h4>
          <div className="form-group">
            <SearchDropdown
              placeHolder="Choose Inspector"
              optionsData={inspectionTypeOption()}
              value={
                insp_id
                  ? {
                      value: inspection?.type,
                      label: `${insp_type(inspection?.type)}`,
                    }
                  : null
              }
              disabled={insp_id ? true : false}
              defaultOptionValue={insp_id && inspection?.type}
              onSelect={(ev) =>
                setInspection({
                  ...inspection,
                  type: ev?.value || "",
                })
              }
            />
            <small className="text-danger ml-1">
              {isSubmit && inspection.type === ""
                ? "Please select a inspector type"
                : errors?.type
                ? errors?.type[0]
                : ""}
            </small>
          </div>
        </div>
        {inspection.property && (
          <div className="col-md-6 mb-4">
            <h4>Customer Name</h4>
            <CustomField
              label="Linked Customer"
              value={customerName}
              name="LinkedCustomer"
              type="text"
              id={`customer_name`}
              inputProps={{
                disabled: true,
                maxLength: "50",
              }}
            />
          </div>
        )}
        {inspection.type === "wit_inspect" && userInfo?.role !== "customer" && (
          <div className="col-md-6">
            <h4>Link Inspector</h4>
            <div className="form-group">
              <SearchDropdownRadioIcon
                apiCall={getInspectors}
                saveProp={"id"}
                user_role={userInfo?.role}
                showProp={["first_name", "last_name"]}
                placeHolder="Choose Linked Inspector"
                value={
                  inspection?.inspectors && inspection?.inspectors[0]?.id
                    ? {
                        value: inspection?.inspectors[0]?.id,
                        label: `${
                          inspection?.inspectors[0]?.first_name +
                          " " +
                          inspection?.inspectors[0]?.last_name
                        }`,
                      }
                    : null
                }
                RadioIcon={true}
                disabled={insp_id ? (editEnabled ? false : true) : false}
                onSelect={(ev) => {
                  setInspection({
                    ...inspection,
                    inspector_id: ev?.value,
                    inspectors: [{ id: ev?.value }],
                  });
                }}
                defaultOptionValue={insp_id && inspection?.inspectors[0]?.id}
              />

              <small className="text-danger ml-1">
                {isSubmit && errors?.inspector_id
                  ? errors?.inspector_id[0]
                  : ""}
              </small>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-6">
          <h4>Property Rooms and Items</h4>
        </div>
      </div>
      <div className="row" style={{ alignItems: editEnabled && "center" }}>
        {editEnabled && inspection.status !== "completed" && (
          <div className="col-md-12 d-flex">
            <>
              <button
                className="btn btn-primary ml-auto"
                style={{ padding: "10px 17px" }}
                onClick={() => {
                  if (inspection?.rooms?.length < 40) {
                    setmodalShow(true);
                  } else {
                    if (showLoader) {
                      dispatch({
                        type: LOADER_STATE,
                        payload: false,
                      });
                      showToast(
                        `You have reached the limit of 40 rooms per inspection`,
                        false,
                        dispatch
                      );
                    }
                  }
                }}
                disabled={inspection?.rooms?.length < 40 ? false : true}
              >
                <img
                  alt="add_room"
                  className=""
                  src={require(`../../../assets/icons/add@2x.png`).default}
                />
                Add Room
              </button>

              <button
                className="btn btn-primary ml-4"
                onClick={() => {
                  if (inspection?.rooms?.length < 40) {
                    setItemModalShow(true);
                  } else {
                    if (showLoader) {
                      dispatch({
                        type: LOADER_STATE,
                        payload: false,
                      });
                      showToast(
                        `You have reached the limit of 40 rooms per inspection`,
                        false,
                        dispatch
                      );
                    }
                  }
                }}
                disabled={ItemsInList()}
              >
                <img
                  alt="add_item"
                  className=""
                  src={require(`../../../assets/icons/add@2x.png`).default}
                />
                Add Item
              </button>
            </>
          </div>
        )}
        <div
          className="col-md-6"
          style={editEnabled ? { marginTop: "-50px" } : {}}
        >
          {inspection?.rooms?.map((room, i) => (
            <div style={{ display: "flex" }}>
              <div
                key={i}
                // className="col-md-12"
                style={
                  editEnabled ? { marginTop: "-10px" } : { marginTop: "-15px" }
                }
              >
                <div className="my-md-3 d-flex">
                  <CustomField
                    label="Linked Customer"
                    value={`${room?.name?.trim()} : ${
                      room?.pivot?.description
                        ? room?.pivot?.description
                        : room?.description
                    }`}
                    parentClassName="w-100"
                    name="LinkedCustomer"
                    type="text"
                    minWidth={471}
                    id={`LinkedCustomer_${i}`}
                    inputProps={{
                      disabled: true,
                      maxLength: "50",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 d-flex">
                {editEnabled && (
                  <>
                    <div className="d-flex align-items-center mr-3 pointer">
                      <img
                        onClick={() => {
                          console.log("room.name", room.id === 8);
                          if (room.id === 8 || room.id === 9) {
                            setroomID(room.id);
                            seteditState(true);
                            setItemModalShow(true);
                          } else {
                            setroomID("");
                            seteditState(true);
                            setmodalShow(true);
                            seteditRoomId(i);
                          }
                        }}
                        alt="edit_room"
                        className=""
                        src={
                          require(`../../../assets/icons/edit1@2x.png`).default
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center pointer">
                      <img
                        onClick={() => {
                          if (inspection.rooms.length > 1) {
                            if (inspection.rooms.length > i + 1) {
                              const isNextRoomTypeIsItem = Object.values(
                                itemsIds
                              ).findIndex(
                                (itemId) =>
                                  itemId === inspection.rooms[i + 1].id
                              );
                              if (
                                isNextRoomTypeIsItem !== -1 &&
                                Number(inspection.rooms[0].id) ===
                                  Number(room.id)
                              ) {
                                if (showLoader) {
                                  dispatch({
                                    type: LOADER_STATE,
                                    payload: false,
                                  });
                                  showToast(
                                    "There should be at least one room above the room item.",
                                    false,
                                    dispatch
                                  );
                                }
                                return;
                              }
                            }
                            setopendel(true);
                            setdeleteItem(i);
                            if (room?.pivot?.id) {
                              setdeleteRoomID(room?.pivot.id);
                            }
                          } else {
                            if (showLoader) {
                              dispatch({
                                type: LOADER_STATE,
                                payload: false,
                              });
                              showToast(
                                "You can not delete all rooms",
                                false,
                                dispatch
                              );
                            }
                          }
                        }}
                        alt="delete_room"
                        className=""
                        src={
                          require(`../../../assets/icons/Close@2x.png`).default
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Suspense fallback={null}>
        <AddRoomModal
          show={modalShow}
          onHide={() => {
            seteditRoomId("");
            setmodalShow(false);
            seteditState(false);
          }}
          user_role={userInfo?.role}
          onSave={(ev) => {
            setInspection({
              ...inspection,
              rooms: [
                ...inspection?.rooms,
                { id: ev.id, name: ev.RoomIdName, description: ev.name },
              ],
            });
          }}
          editRoom={editRoomId !== "" && inspection?.rooms[editRoomId]}
          editRoomArrayId={editRoomId}
          roomsList={inspection?.rooms}
          insp_id={insp_id}
          onSaveEdit={(ev) => {
            onSaveEditCTA(ev);
          }}
          editState={editState}
        />
      </Suspense>

      <Suspense fallback={null}>
        <AddItemModal
          show={itemModalShow}
          onHide={() => {
            seteditRoomId("");
            setItemModalShow(false);
            seteditState(false);
          }}
          user_role={userInfo?.role}
          inspection={inspection}
          onSave={(ev) => {
            console.log(ev, "wwwwwwwwwwwqqqqqqqqqqqq");
            if (insp_id) {
              itemSaveEditCTA(ev);
            } else {
              saveItemCTA(ev);
            }
          }}
          setInspection={setInspection}
          deleteRooms={deleteRooms}
          setdeleteRooms={setdeleteRooms}
          editState={editState}
          roomID={roomID}
          ItemsInList={ItemsInList}
        />
      </Suspense>

      <Delete
        show={opendel}
        onHideDelete={() => setopendel(false)}
        msg={"Are you sure you want to delete this room?"}
        deleteId={deleteItem}
        deleteRoomID={deleteRoomID}
        confirmDelete={(ev) => {
          console.log(ev, "evevevevevevev");
          if (ev?.deleteRoomID !== null && ev?.deleteRoomID !== "") {
            setdeleteRooms([...deleteRooms, ev.deleteRoomID]);
          }
          inspection.rooms.splice(ev.deleteId, 1);
          setInspection({ ...inspection });
          setdeleteItem("");
          setdeleteRoomID("");
        }}
      />
    </div>
  );
};

export default InspectionsFields;
