import "../style.css";

import { Link, useHistory, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import CustomField from "../../../components/CustomField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessModal from "../../../components/SuccessModal";
import WITLogo from "../../../../assets/icons/password-img@2x.png";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import passIcon from "../../../../assets/icons/lock@2x.png";
import { regexes } from "../../../shared/regexes";
import { useSelector } from "react-redux";
import { useCreatePassword } from "./useCreatePassword";

const CreatePassword = () => {
  const userInfo = useSelector((state) => state.user.data);
  const [
    password,
    setPassword,
    oldpassword,
    setOldPassword,
    confirmPassword,
    setConfirmPassword,
    showSuccess,
    setShowSuccess,
    disableReset,
    setDisableReset,
    errorText,
    setErrorText,
    errors,
    seterrors,
    opendel,
    setopendel,
    bothPassErr,
    setbothPassErr,
    passErr,
    setpassErr,
    FormSubmit,
    setFormSubmit,
    role,
    handleSubmit,
    handleSkip,
    history,
  ] = useCreatePassword();

  useEffect(() => {
    if (
      userInfo?.flags?.password_changed === true ||
      !userInfo?.flags?.hasOwnProperty("password_changed")
    ) {
      history.replace("/properties");
    }
  }, []);

  return (
    <div className="login-page reset-password">
      <div className="text-center">
        <img
          alt="WIT-logo"
          className="WIT-logo reset-pass"
          src={WITLogo}
          width="130px"
          // width="410px"
          style={{
            marginBottom: "10px",
          }}
        />
      </div>
      <p className="forgot-h1 mb-1">Create new password</p>

      <div className="reset-password-text">Enter your new password.</div>

      {errorText !== "" ? (
        <div>
          <div className="email-pass-error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-4 font-bold">{errorText}</span>
          </div>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div id="float-label-auth" className="">
          <CustomField
            name="password"
            label="Old Password"
            value={oldpassword}
            minWidth="410px"
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="old_password"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!oldpassword ||
                (errors?.old_password && errors?.old_password[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1 error-text"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && !oldpassword
              ? "Please enter old password "
              : errors?.old_password
              ? errors?.old_password[0]
              : ""}
          </small>
        </div>

        <div id="float-label-auth" className="mt-3 ">
          <CustomField
            name="password"
            label="New Password"
            value={password}
            minWidth="410px"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="signup_password"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!password ||
                ((password.length < 8 || !regexes.password.test(password)) &&
                  passErr) ||
                (confirmPassword !== password && bothPassErr) ||
                (errors?.password && errors?.password[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1 error-text"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && !password
              ? "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
              : ""}
          </small>
        </div>
        <div className="margin-top-field">
          <CustomField
            name="confirmPassword"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="signup_confirmPassword"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!confirmPassword ||
                (confirmPassword !== password && bothPassErr) ||
                (errors?.confirmPassword && errors?.confirmPassword[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && !confirmPassword
              ? "Please enter confirm password"
              : errors?.confirmPassword
              ? errors?.confirmPassword[0]
              : ""}
          </small>
        </div>

        <div className="margin-top-field  text-align-end">
          <button
            variant="primary"
            className={"login-btn bg-light20"}
            type="submit"
          >
            Change Password
          </button>
        </div>
      </form>

      <div className="d-flex justify-content-center mt-3 black-text ">
        <div className="mt-3">
          <>
            <Link onClick={handleSkip} className="new-tab-link" id="terms">
              Skip
            </Link>{" "}
          </>
        </div>
      </div>

      <SuccessModal
        show={opendel}
        displayText="Your password has been changed!"
        company_name="bcd"
        closeOutside={true}
        redirectLink={"/properties"}
        onHide={() => setopendel(false)}
      />
    </div>
  );
};

export default CreatePassword;
