import Axios from "axios";
import getUploadUrl from "./getUploadUrl";

const uploadFile = (file, name) => {
  console.log(file, name, "---------------");

  return new Promise((resolve, reject) => {
    let url = "";
    let key = "";
    getUploadUrl(name)
      .then((res) => {
        url = res.data.presignedUrl;
        key = res.data.key;

        const config = {
          method: "put",
          url,
          headers: {
            "Content-Type": file.type,
          },
          data: file,
        };

        const newAxios = Axios.create({});

        // delete newAxios.defaults.headers.common.Authorization;
        newAxios(config)
          .then(() => {
            // append key with s3 bucket url
            let url = `https://${process.env.REACT_APP_IMAGE_KEY}.s3.us-east-1.amazonaws.com/${key}`;

            resolve(url);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { uploadFile };
