import {
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_FAIL,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST,
  SELECT_INSTRUCTIONAL_CUSTOM_TASK_RESET,
 
  SELECT_INSTRUCTIONAL_TASK_FAIL,
  SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS,
  SELECT_INSTRUCTIONAL_TASK_REQUEST,
  SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS,
  SELECT_INSTRUCTIONAL_TASK_RESET,
 
  SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK,
} from "../../types/select_instructional_task/selectInstructionalTaskTypes";

const storeTaskInitialState = {
  loading: false,
  storedTaskList: [],
  selectedStoreTaskList: [],
  error: "",
};

const customTaskInitialState = {
  loading: false,
  customTaskList: [],
  selectedCustomTaskList: [],
  error: "",
};

export const instructionalStoreTaskSelectionReducers = (
  state = storeTaskInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_INSTRUCTIONAL_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedStoreTaskList: [payload, ...state.selectedStoreTaskList],
        error: "",
      };

    case SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS:
      const removeStoreTask = state.selectedStoreTaskList.filter(
        (image) => image?.taskImgUrl !== payload?.taskImgUrl
      );
      return {
        ...state,
        loading: false,
        selectedStoreTaskList: removeStoreTask,
        error: "",
      };

    case SELECT_INSTRUCTIONAL_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SELECT_INSTRUCTIONAL_TASK_RESET:
      return {
        loading: false,
        error: "",
        selectedStoreTaskList: [],
      };

    default:
      return state;
  }
};

export const instructionalCustomTaskSelectionReducers = (
  state = customTaskInitialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK:
      return {
        ...state,
        loading: false,
        customTaskList: [payload, ...state.customTaskList],
        selectedCustomTaskList: [payload, ...state.selectedCustomTaskList],
        error: "",
      };

    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCustomTaskList: [payload, ...state.selectedCustomTaskList],
        error: "",
      };
      
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS:
      const removeCustomTask = state.selectedCustomTaskList.filter(
        (image) => image?.taskImgUrl !== payload?.taskImgUrl
      );
      return {
        ...state,
        loading: false,
        selectedCustomTaskList: removeCustomTask,
        error: "",
      };

    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    
    case SELECT_INSTRUCTIONAL_CUSTOM_TASK_RESET:
      return {
        loading: false,
        error: "",
        customTaskList: [],
        selectedCustomTaskList: [],
      };

    default:
      return state;
  }
};
