import "./style.css";

import {
  checkCustomer,
  getErrorMsg,
  inputNumberKeyPress,
  showToast,
  valueExists,
  setHeader,
} from "../../shared/helpers";
import {
  getInspectionsByProperty,
  getPropertyDetails,
  updateProperty,
} from "../../../services/PropertyService";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import PropertyForm from "./PropertyForm";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

const PropertyEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const { propertyId } = useParams();
  const propertyData = location?.state;
  const [dis, setdis] = useState(false);
  const userInfo = useSelector((state) => state.user.data);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const [property, setProperty] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    customer_id: checkCustomer(userInfo, propertyData),
    customer_first_name: propertyData?.first_name ?? "",
    customer_last_name: propertyData?.last_name ?? "",
  });

  const [errors, seterrors] = useState({});

  const [editEnabled, seteditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(propertyId ? true : false);
  const [listLoader, setListLoader] = useState(false);

  const EditProperty = () => {
    setIsSubmit(true);
    if (valueExists(property)) {
      setdis(true);

      updateProperty(userInfo?.role, propertyId, { ...property })
        .then((resp) => {
          if (resp) {
            setListLoader(true);
            seteditEnabled(false);
            showToast("Property has been updated", true);
            seterrors({});
          }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => {
          setdis(false);
        });
    }
  };
  const getEditProperty = useCallback(() => {
    setIsLoading(true);
    getPropertyDetails(userInfo?.role, propertyId)
      .then((resp) => {
        setProperty({
          ...resp?.data?.address,
          customer_id: resp?.data?.customer?.id,
          customer_first_name: resp?.data?.customer?.first_name,
          customer_last_name: resp?.data?.customer?.last_name,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [propertyId, userInfo?.role]);

  useEffect(() => {
    if (propertyId) {
      getEditProperty();
    }
  }, [getEditProperty, propertyId]);

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <PropertyForm
            history={history}
            propertyId={propertyId}
            dis={dis}
            userInfo={userInfo}
            isSubmit={isSubmit}
            property={property}
            setProperty={setProperty}
            listLoader={listLoader}
            setListLoader={setListLoader}
            errors={errors}
            editEnabled={editEnabled}
            seteditEnabled={seteditEnabled}
            inputNumberKeyPress={inputNumberKeyPress}
            EditProperty={EditProperty}
            getEditProperty={getEditProperty}
            setHeader={setHeader}
            dispatch={dispatch}
          />
        )}
      </ScrollBar>
    </div>
  );
};

export default PropertyEdit;
