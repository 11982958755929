import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { isAuthenticated } from "../main/shared/helpers";

const RouteCustom = ({ isProtected, exact, path, children }) => {
  const logout = useSelector((state) => state.app.logout);
  useEffect(() => {
    if (logout) {
      return <Redirect to="/login/home-owner" />;
    }
  }, [logout]);

  if (isProtected === "both") {
    return (
      <Route exact={exact} path={path}>
        {children}
      </Route>
    );
  }

  if (localStorage.getItem("maintenance_mode")) {
    if (!path.includes("maintenance")) {
      console.log("first", localStorage.getItem("maintenance_mode"));
      return <Redirect to="/maintenance" />;
    }
  }
  if (
    path.includes("maintenance") &&
    !localStorage.getItem("maintenance_mode")
  ) {
    return <Redirect to="" />;
  }

  if (!isAuthenticated() && isProtected === true) {
    localStorage.removeItem("user");

    console.log("redirect to login");
    return <Redirect to="/login/home-owner" />;
  }

  if (isAuthenticated() && isProtected === false) {
    console.log("redirect to root");
    return <Redirect to="" />;
  }

  return (
    <Route exact={exact} path={path}>
      {children && children}
    </Route>
  );
};

export default RouteCustom;
