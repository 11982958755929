import "./style.css";

import CustomField from "../../components/CustomField";
import PropertyInspections from "./PropertyInspections";
import SearchDropdown from "../../components/SelectDropdown";
import SearchDropdownRadioIcon from "../../components/SearchDropdownRadioIcon";
import { getCustomers } from "../../../services/CustomerService";
import { getUsStates } from "../../../services/PropertyService";
import { roleNames } from "../../shared/helpers";

const PropertyForm = (props) => {
  const {
    history,
    propertyId,
    dis,
    userInfo,
    isSubmit,
    property,
    setProperty,
    errors,
    editEnabled,
    seteditEnabled,
    inputNumberKeyPress,
    submit,
    EditProperty,
    getEditProperty,
    setHeader,
    dispatch,
    listLoader,
    setListLoader,
  } = props;

  return (
    <div className="main-content-area">
      <div className="d-flex align-items-center">
        {propertyId ? (
          <>
            {editEnabled ? (
              <>
                {" "}
                <button
                  className="btn btn-primary ml-auto"
                  disabled={dis}
                  style={{
                    paddingInline: "55px",
                  }}
                  onClick={EditProperty}
                  id="save_property"
                >
                  Save
                </button>
                <button
                  className="btn btn-primary"
                  disabled={dis}
                  style={{
                    background: "transparent",
                    borderColor: "#14444d",
                    paddingInline: "47px",
                    marginLeft: "16px",
                  }}
                  onClick={() => {
                    getEditProperty();
                    seteditEnabled(false);
                    setHeader(dispatch, {
                      title: `Property Details`,
                    });
                  }}
                  id="cancel_edit_property"
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-primary ml-auto"
                disabled={dis}
                onClick={() => {
                  seteditEnabled(true);
                  setHeader(dispatch, {
                    title: `Edit Property`,
                  });
                }}
                id="edit_property"
                style={{
                  paddingInline: "30px",
                }}
              >
                <img
                  alt="Edit property"
                  src={require(`../../../assets/icons/edit@2x.png`).default}
                />
                Edit Info
              </button>
            )}
          </>
        ) : (
          <button
            className="btn btn-primary ml-auto"
            disabled={dis}
            style={{
              paddingInline: "55px",
            }}
            onClick={submit}
            id="save_property"
          >
            Save
          </button>
        )}
      </div>
      <div>
        <h4>Address</h4>

        <div className="row">
          <div className="col-md-6 mb-2">
            <CustomField
              name="address"
              label="Street 1"
              type="text"
              value={property?.address1}
              isSubmit={isSubmit}
              CustomErrors={errors}
              disabled={propertyId ? (editEnabled ? false : true) : false}
              onChange={(e) =>
                setProperty({
                  ...property,
                  address1: e.target.value,
                })
              }
              id="first_street"
              errors={
                isSubmit &&
                (!property.address1 ||
                  !property.address1.trim() ||
                  (errors?.address1 && errors?.address1[0]))
              }
              inputProps={{
                maxLength: "60",
              }}
            />
            <small className="text-danger ml-1">
              {isSubmit && property?.address1.length === 0
                ? "Please enter an address"
                : isSubmit &&
                  property?.address1.length !== 0 &&
                  !property?.address1.trim()
                ? "Required field cannot be left blank"
                : errors?.address1
                ? errors?.address1[0]
                : ""}
            </small>
          </div>

          <div className="col-md-6">
            <CustomField
              name="street2"
              label="Street 2 (Optional)"
              disabled={propertyId ? (editEnabled ? false : true) : false}
              type="text"
              onChange={(e) =>
                setProperty({
                  ...property,
                  address2: e.target.value,
                })
              }
              mandatory={false}
              isSubmit={isSubmit}
              CustomErrors={errors}
              id="second_street"
              value={property?.address2}
              errors={isSubmit && errors?.address2 && errors?.address2[0]}
              inputProps={{
                maxLength: "60",
              }}
            />
            <small className="text-danger ml-1">
              {isSubmit && errors?.address2 ? errors?.address2[0] : ""}
            </small>
          </div>
          <div className="col-md-6">
            <CustomField
              name="city"
              label="City"
              type="text"
              disabled={propertyId ? (editEnabled ? false : true) : false}
              onChange={(e) =>
                setProperty({ ...property, city: e.target.value })
              }
              id="city"
              isSubmit={isSubmit}
              CustomErrors={errors}
              value={property?.city}
              errors={
                isSubmit &&
                (!property?.city ||
                  !property.city.trim() ||
                  (errors?.city && errors?.city[0]))
              }
              inputProps={{
                maxLength: "60",
              }}
            />
            <small className="text-danger ml-1">
              {isSubmit && property?.city.length === 0
                ? "Please enter a city name"
                : isSubmit &&
                  property?.city.length !== 0 &&
                  !property?.city.trim()
                ? "Required field cannot be left blank"
                : errors?.city
                ? errors?.city[0]
                : ""}
            </small>
          </div>
          <div className="col-md-3">
            <SearchDropdown
              apiCall={getUsStates}
              saveProp={"id"}
              showProp={["name"]}
              disabled={propertyId ? (editEnabled ? false : true) : false}
              defaultOptionValue={propertyId && property?.state}
              value={
                property?.state
                  ? {
                      value: property?.state,
                      label: property?.state,
                    }
                  : null
              }
              RadioIcon={true}
              placeHolder="States"
              isSearch={true}
              onSelect={(ev) => {
                setProperty({ ...property, state: ev?.label });
              }}
            />
            <small className="text-danger ml-1">
              {isSubmit && property?.state === ""
                ? "Please select a state"
                : errors?.state
                ? errors?.state[0]
                : ""}
            </small>
          </div>
          <div className="col-md-3">
            <CustomField
              name="zipcode"
              label="Zip Code"
              disabled={propertyId ? (editEnabled ? false : true) : false}
              type="text"
              onChange={(e) => {
                setProperty({
                  ...property,
                  zipcode: e.target.value.replace(/[^\d.]/gi, ""),
                });
              }}
              id="zipcode"
              value={property?.zipcode}
              errors={
                isSubmit &&
                (!property?.zipcode ||
                  !property?.zipcode?.trim() ||
                  (errors?.zipcode && errors?.zipcode[0]))
              }
              isSubmit={isSubmit}
              CustomErrors={errors}
              inputProps={{
                maxLength: "6",
                step: "0.01",
                onKeyPress: inputNumberKeyPress,
              }}
            />
            <small className="text-danger ml-1">
              {isSubmit && property?.zipcode.length === 0
                ? "Please enter a ZIP code"
                : isSubmit &&
                  property?.zipcode.length !== 0 &&
                  !property?.zipcode.trim()
                ? "Required field cannot be left blank"
                : isSubmit && property?.zipcode.length < 5
                ? "ZIP code must contain at least 5 characters"
                : errors?.zipcode
                ? errors?.zipcode[0]
                : ""}
            </small>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h4>Customer Name</h4>
        <div className="row">
          <div className="col-md-6">
            {userInfo.role === roleNames.superAdmin ||
            userInfo.role === roleNames.buAdmin ? (
              <>
                <SearchDropdownRadioIcon
                  apiCall={getCustomers}
                  saveProp={"id"}
                  user_role={userInfo?.role}
                  showProp={["first_name", "last_name"]}
                  placeHolder="Select Customer"
                  disabled={propertyId ? (editEnabled ? false : true) : false}
                  value={
                    property?.customer_id
                      ? {
                          value: property?.customer_id,
                          label: `${
                            property?.customer_first_name +
                            " " +
                            property?.customer_last_name
                          } `,
                        }
                      : null
                  }
                  defaultOptionValue={propertyId ? property?.customer_id : ""}
                  onSelect={(val) => {
                    setProperty({
                      ...property,
                      customer_id: val?.value || "",
                    });
                  }}
                />
                <small className="text-danger ml-1">
                  {isSubmit &&
                  (property?.customer_id === "" || !property?.customer_id)
                    ? "Please select a customer"
                    : errors?.customer_id
                    ? errors?.customer_id[0]
                    : ""}
                </small>
              </>
            ) : (
              <CustomField
                label="Linked Customer"
                value={`${userInfo?.first_name} ${userInfo?.last_name}`}
                name="LinkedCustomer"
                type="text"
                disabled={true}
                id="LinkedCustomer"
                inputProps={{
                  maxLength: "40",
                }}
              />
            )}
          </div>
        </div>
      </div>

      {propertyId && (
        <PropertyInspections
          history={history}
          propertyId={propertyId}
          userInfo={userInfo}
          property={property}
          listLoader={listLoader}
          setListLoader={setListLoader}
          editEnabled={editEnabled}
        />
      )}
    </div>
  );
};

export default PropertyForm;
