// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { showToast } from "../../main/shared/helpers";
import { postFCMToken } from "../Notifications";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const fMessaging = getMessaging();
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window["safari"] ||
      (typeof window["safari"] !== "undefined" &&
        window["safari"].pushNotification)
  );

export const getFCMToken = (handler = () => {}) => {
  getToken(fMessaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        NotificationCalled(currentToken, handler);
      }
    })
    .catch((error) => {
      if (!isSafari) {
        showToast(
          "Please grant notification permission to receive notifications"
        );
      }
    });
};
const NotificationCalled = (currentToken, handler = () => {}) => {
  let localToken = localStorage.getItem("access_token");

  if (localToken) {
    localStorage.setItem("fcm_token", btoa(currentToken));

    postFCMToken({ fcm_token: currentToken })
      .then((resToken) => {
        onMessage(
          fMessaging,
          (payload) => {
            console.log("Messageddddddddddddddddddddd 2", payload);

            navigator.serviceWorker
              .getRegistration("/firebase-cloud-messaging-push-scope")
              .then((registration) => {
                console.log("Message received. ", payload);
                registration.showNotification(
                  payload?.notification?.title,
                  payload?.notification
                );
              });
            handler(payload);
          },
          (error) => {
            console.log(error);
          }
        );
        console.log(resToken, "resTokenresTokenresTokenresTokenresToken");
      })
      .catch((errToken) => {
        showToast("You are not subscribed to get notifications");
      });
  }
};

onMessage(
  fMessaging,
  (payload) => {
    console.log("Messageddddddddddddddddddddd 1 ", payload);

    navigator.serviceWorker
      .getRegistration("/firebase-cloud-messaging-push-scope")
      .then((registration) => {
        console.log("Message received. ", payload);
        registration.showNotification(
          payload?.notification?.title,
          payload?.notification
        );
      });
  },
  (error) => {
    console.log(error);
  }
);
export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    getToken(fMessaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_KEY,
    })
      .then((currentToken) => {
        resolve(currentToken);
        if (currentToken) {
          // setTokenFound(currentToken);
          NotificationCalled(currentToken);
          // return currentToken;
        } else {
          // setTokenFound(currentToken);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
