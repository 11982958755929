// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import "../Settings/style.css";

import { inputNumberKeyPress, roleToText } from "../../shared/helpers";

import CustomField from "../../components/CustomField";
import ImageIcon from "../../../assets/icons/camera@2x.png";
import PhoneInput from "react-phone-input-2";
import SearchDropdown from "../../components/SelectDropdown";
import { fileSizeLimits } from "../../shared/validations";
import { getUsStates } from "../../../services/PropertyService";
import { regexes } from "../../shared/regexes";
import { useState } from "react";
import { uploadFile } from "../../../services/UploadFile";
import Compressor from 'compressorjs';


const AddHomeOwner = ({
  userHomeOwner,
  setuserHomeOwner,
  errors,
  disabled,
  emailDisabled,
  showRole,
  isSubmit,
  addUser,
  setDisableSave,
  setdisbutton,
}) => {

  console.log(disabled, "disableSave_disableSave")
  
  const [preview, setPreview] = useState(
    userHomeOwner.avatar ? userHomeOwner.avatar : null
    );
    const [file, setFile] = useState(null);
    const [file_e, setFile_e] = useState("");

    const fileChangedHandler = (event) => {
      const file = event.target.files[0];

    if (file) {
      if (file.name.includes(".jfif")) {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else {
        if(setdisbutton){
          setdisbutton(true)
        }else{
          setDisableSave(true);
        }
        // seteditEnabled(true)
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
        // uploadAllImagestoS3(file, filename);
        compressImage(file)

      }
    }
  };


  
  const compressImage = (file) => {
    new Compressor(file, {
      quality: 0.5,
      convertSize: 500000,
      convertTypes: ["image/png", "image/jpeg", "image/jpg"],
      success: (compressedResult) => {
        const namefile = compressedResult.name.split(".").pop();
        const filename = `images/customer/${new Date().getTime()}.${namefile}`;
        uploadImagetoS3(compressedResult, filename)
      },
    });
  }


  const uploadImagetoS3 = (file, filename) => {
    uploadFile(file, filename)
      .then((url) => {
        setuserHomeOwner({ ...userHomeOwner, avatar: url });
        if(setdisbutton){
          setdisbutton(false)
        }else{
          setDisableSave(false);
        }
      })
      .catch((er) => {
        console.log(er, "");
        if(setdisbutton){
          setdisbutton(false)
        }else{

          setDisableSave(false);
        }
      });
  };

  return (
    <div>
      <h4>Home Owner Details</h4>
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <CustomField
                  name="first_name"
                  label="Customer First Name"
                  type="text"
                  disabled={disabled}
                  value={userHomeOwner?.first_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z ]/gi, "");
                    setuserHomeOwner({
                      ...userHomeOwner,
                      first_name: value,
                    });
                  }}
                  id="first_name"
                  errors={
                    isSubmit &&
                    !disabled &&
                    (!userHomeOwner.first_name ||
                      !userHomeOwner.first_name.trim() ||
                      (errors?.first_name && errors?.first_name[0]))
                  }
                  inputProps={{
                    maxLength: "30",
                  }}
                />
                <small className="text-danger ml-1">
                  {isSubmit &&
                  !disabled &&
                  userHomeOwner?.first_name.length === 0
                    ? "Please enter a first name"
                    : isSubmit &&
                      !disabled &&
                      userHomeOwner?.first_name.length !== 0 &&
                      !userHomeOwner?.first_name.trim()
                    ? "Required field cannot be left blank"
                    : errors?.first_name
                    ? errors?.first_name[0]
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <CustomField
                name="last_name"
                label="Customer Last Name"
                type="text"
                disabled={disabled}
                value={userHomeOwner?.last_name}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z ]/gi, "");
                  setuserHomeOwner({
                    ...userHomeOwner,
                    last_name: value,
                  });
                }}
                id="last_name"
                errors={
                  isSubmit &&
                  !disabled &&
                  (!userHomeOwner.last_name ||
                    !userHomeOwner.last_name.trim() ||
                    (errors?.last_name && errors?.last_name[0]))
                }
                inputProps={{
                  maxLength: "30",
                }}
              />
              <small className="text-danger ml-1">
                {isSubmit && !disabled && userHomeOwner?.last_name.length === 0
                  ? "Please enter a last name"
                  : isSubmit &&
                    !disabled &&
                    userHomeOwner?.last_name.length !== 0 &&
                    !userHomeOwner?.last_name.trim()
                  ? "Required field cannot be left blank"
                  : errors?.last_name
                  ? errors?.last_name[0]
                  : ""}
              </small>
            </div>

            <div className="col-md-6">
              <CustomField
                name="email"
                label="Email "
                type="text"
                disabled={emailDisabled ? emailDisabled : disabled}
                value={userHomeOwner?.email}
                onChange={(e) =>
                  setuserHomeOwner({
                    ...userHomeOwner,
                    email: e.target.value,
                  })
                }
                id="email"
                errors={
                  isSubmit &&
                  !disabled &&
                  (!userHomeOwner.email ||
                    !regexes.email.test(userHomeOwner?.email) ||
                    !userHomeOwner.email.trim() ||
                    (errors?.email && errors?.email[0]))
                }
                inputProps={{
                  maxLength: "40",
                }}
              />
              <small className="text-danger ml-1">
                {isSubmit && !disabled && userHomeOwner?.email.length === 0
                  ? "Please enter an email address"
                  : isSubmit &&
                    !disabled &&
                    userHomeOwner?.email.length !== 0 &&
                    !userHomeOwner?.email.trim()
                  ? "Required field cannot be left blank"
                  : isSubmit &&
                    !disabled &&
                    userHomeOwner?.email.length !== 0 &&
                    !regexes.email.test(userHomeOwner?.email)
                  ? "Please enter a valid email address"
                  : errors?.email
                  ? errors?.email[0]
                  : ""}
              </small>
            </div>
            <div className="col-md-6">
              <PhoneInput
                country={"us"}
                disabled={disabled}
                value={userHomeOwner?.phone_number}
                buttonClass={disabled ? "flag-dis" : "flag-enable"}
                // buttonClass={{disp}}
                onChange={(e) => {
                  let value = e;

                  setuserHomeOwner({
                    ...userHomeOwner,
                    phone_number: value,
                  });
                }}
              />

              <small className="text-danger ml-1">
                {isSubmit &&
                !disabled &&
                userHomeOwner?.phone_number?.length === 0
                  ? "Please enter a phone number"
                  : isSubmit &&
                    !disabled &&
                    userHomeOwner?.phone_number?.length !== 0 &&
                    !userHomeOwner?.phone_number?.trim()
                  ? "Required field cannot be left blank"
                  : isSubmit &&
                    !disabled &&
                    userHomeOwner?.phone_number?.length <= 10
                  ? "The phone number at least have 11 digits"
                  : errors?.phone_number
                  ? errors?.phone_number[0]
                  : ""}
              </small>
            </div>
          </div>
        </div>

        <div
          className="col-md-3 d-flex justify-content-end"
          style={{ marginTop: addUser && "-26px" }}
        >
          <div className="upload-avatar ">
            <div className="image-area" disabled={disabled}>
              {!preview ? (
                <span
                  className="upload-icon"
                  style={
                    preview
                      ? { backgroundColor: "transparent" }
                      : { backgroundColor: "#f3f3f3" }
                  }
                >
                  <img src={ImageIcon} alt="" />
                </span>
              ) : null}

              <img
                width="155"
                className="avatar-lg"
                src={`${
                  preview?.includes("blob:htt")
                    ? preview
                    : `${preview}?${new Date().getTime()}`
                }`}
                alt=""
              />
              <input
                type="file"
                className="upload-image"
                accept="image/jpeg,image/png"
                onChange={(e) => {
                  fileChangedHandler(e);
                }}
                disabled={disabled}
              />
            </div>
            <p>Upload Photo (Optional)</p>
            <small className="text-danger ml-1">{!disabled && file_e}</small>
          </div>
        </div>
      </div>
      {showRole && (
        <div className="row mb-4" style={{ marginTop: "-60px" }}>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <h4>Role</h4>

                <CustomField
                  name="role"
                  label="role "
                  type="text"
                  value={roleToText(userHomeOwner?.role)}
                  id="role"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <h4>Billing Address</h4>
      <div className="row">
        <div className="col-md-6 mb-2">
          <CustomField
            name="street"
            label="Street 1"
            type="text"
            disabled={disabled}
            value={userHomeOwner?.address1}
            // disabled={propertyId ? (editEnabled ? false : true) : false}
            onChange={(e) =>
              setuserHomeOwner({ ...userHomeOwner, address1: e.target.value })
            }
            id="first_street"
            errors={
              isSubmit &&
              !disabled &&
              (!userHomeOwner.address1 ||
                !userHomeOwner.address1.trim() ||
                (errors?.address1 && errors?.address1[0]))
            }
            inputProps={{
              maxLength: "60",
            }}
          />
          <small className="text-danger ml-1">
            {isSubmit && !disabled && userHomeOwner?.address1?.length === 0
              ? "Please enter an address"
              : isSubmit &&
                !disabled &&
                userHomeOwner?.address1?.length !== 0 &&
                !userHomeOwner?.address1?.trim()
              ? "Required field cannot be left blank"
              : errors?.address1
              ? errors?.address1[0]
              : ""}
          </small>
        </div>
        <div className="col-md-6">
          <CustomField
            name="street2"
            label="Street 2 (Optional)"
            // disabled={propertyId ? (editEnabled ? false : true) : false}
            type="text"
            disabled={disabled}
            onChange={(e) =>
              setuserHomeOwner({ ...userHomeOwner, address2: e.target.value })
            }
            id="second_street"
            value={userHomeOwner?.address2}
            errors={
              isSubmit && !disabled && errors?.address2 && errors?.address2[0]
            }
            inputProps={{
              maxLength: "60",
            }}
          />
          <small className="text-danger ml-1">
            {isSubmit && !disabled && errors?.address2
              ? errors?.address2[0]
              : ""}
          </small>
        </div>
        <div className="col-md-6">
          <CustomField
            name="city"
            label="City"
            type="text"
            // disabled={propertyId ? (editEnabled ? false : true) : false}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/[^A-Za-z ]/gi, "");
              setuserHomeOwner({ ...userHomeOwner, city: value });
            }}
            id="city"
            disabled={disabled}
            value={userHomeOwner?.city}
            errors={
              isSubmit &&
              !disabled &&
              (!userHomeOwner?.city ||
                !userHomeOwner.city.trim() ||
                (errors?.city && errors?.city[0]))
            }
            inputProps={{
              maxLength: "60",
            }}
          />
          <small className="text-danger ml-1">
            {isSubmit && !disabled && userHomeOwner?.city?.length === 0
              ? "Please enter a city name"
              : isSubmit &&
                !disabled &&
                userHomeOwner?.city?.length !== 0 &&
                !userHomeOwner?.city?.trim()
              ? "Required field cannot be left blank"
              : errors?.city
              ? errors?.city[0]
              : ""}
          </small>
        </div>
        <div className="col-md-3">
          <SearchDropdown
            // key={searchDropdownKey}
            apiCall={getUsStates}
            saveProp={"id"}
            showProp={["name"]}
            disabled={disabled}
            defaultOptionValue={!disabled && userHomeOwner?.state}
            value={
              userHomeOwner?.state
                ? { value: userHomeOwner?.state, label: userHomeOwner?.state }
                : null
            }
            isSearch={true}
            menuPlacement="top"
            // disabled={propertyId ? (editEnabled ? false : true) : false}
            // defaultOptionValue={propertyId && userHomeOwner?.state}
            // value={{ value: userHomeOwner.state, label: "Alaska" }}
            RadioIcon={false}
            placeHolder="States"
            onSelect={(ev) => {
              setuserHomeOwner({ ...userHomeOwner, state: ev?.label });
            }}
          />
          <small className="text-danger ml-1">
            {isSubmit && !disabled && !userHomeOwner?.state
              ? "Please select a state"
              : errors?.state
              ? errors?.state[0]
              : ""}
          </small>
        </div>
        <div className="col-md-3">
          <CustomField
            name="zipcode"
            label="Zip Code"
            // disabled={propertyId ? (editEnabled ? false : true) : false}
            type="text"
            disabled={disabled}
            onChange={(e) => {
              setuserHomeOwner({
                ...userHomeOwner,
                zipcode: e.target.value.replace(/[^\d.]/gi, ""),
              });
            }}
            id="zipcode"
            value={userHomeOwner?.zipcode}
            errors={
              isSubmit &&
              !disabled &&
              (!userHomeOwner?.zipcode ||
                !userHomeOwner?.zipcode?.trim() ||
                (errors?.zipcode && errors?.zipcode[0]))
            }
            inputProps={{
              maxLength: "6",
              step: "0.01",
              onKeyPress: inputNumberKeyPress,
              // onkeydown: inputNumberKeyDown,
            }}
          />
          <small className="text-danger ml-1">
            {isSubmit && userHomeOwner?.zipcode?.length === 0
              ? "Please enter a ZIP code"
              : isSubmit &&
                !disabled &&
                userHomeOwner?.zipcode?.length !== 0 &&
                !userHomeOwner?.zipcode?.trim()
              ? "Required field cannot be left blank"
              : isSubmit && !disabled && userHomeOwner?.zipcode?.length < 5
              ? "ZIP code must contain at least 5 characters"
              : errors?.zipcode
              ? errors?.zipcode[0]
              : ""}
          </small>
        </div>
      </div>
    </div>
  );
};

export default AddHomeOwner;
