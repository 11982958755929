import Axios from "axios";
import * as Sentry from "@sentry/react";
import { showToast } from "./main/shared/helpers";

const axios = Axios.create();
axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;
axios.defaults.withCredentials = false;

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      localStorage.removeItem("maintenance_mode");
      if (localStorage.getItem("maintenance_mode")) {
        window.location.href = "/";
      }
    }
    return response;
  },
  async (error) => {
    console.log({ error });
    if (error?.response) {
      if (error?.response?.status !== 400 || error?.response?.status !== 422) {
        Sentry.captureException(error);
      }
      if (error?.response?.status === 503) {
        localStorage.setItem("maintenance_mode", "true");
        if (!window.location.href.includes("/maintenance")) {
          window.location.href = "/maintenance";
        }
      }
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/login/home-owner";
      }
      if (error?.response?.data?.errors) {
        const errors = error?.response?.data?.errors;
        const errorKeys = Object.keys(errors) || [];
        let msg = "";
        if (errorKeys && errorKeys?.length) {
          if (errors[errorKeys[0]]?.length) {
            msg = errors[errorKeys[0]][0];
            if (msg) {
              showToast(msg);
            }
          }
        }
        throw Error(msg);
      } else {
        if (error?.response.data.message) {
          showToast(error?.response.data.message);
          throw Error(error?.response.data.message);
        } else {
          showToast("Opps!! something wrong!");
          throw Error("Opps!! something wrong!");
        }
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axios;
