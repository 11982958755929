import "./index.css";

import { CalendlyEventListener, InlineWidget } from "react-calendly";

import React from "react";
import { roleNames } from "../../shared/helpers";
import { updateInspectionForCalendly } from "../../../services/InspectionService";
import { useHistory } from "react-router-dom";

const Calendly = ({ inspection_id, reschedule, userRole }) => {
  console.log("calendly event", inspection_id);
  const history = useHistory();

  const onEventScheduled = (e) => {
    console.log(e, "calendly event");

    if (e.data.payload.invitee.uri.split("/").pop()) {
      updateInspectionForCalendly(inspection_id, {
        calenly_id: e.data.payload.invitee.uri.split("/").pop(),
      })
        .then((resp) => {
          if (userRole === roleNames.bu_homeowner) {
            history.push("/inspections");
          } else {
            history.replace(
              "/inspections/" + inspection_id + "/details?tab=generalInfo"
            );
          }
        })
        .catch((err) => {
          console.log(err, "updateInspectionForCalendly");
        });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <InlineWidget
        style={{ minWidth: "320px", height: "580px", marginTop: "15px" }}
        url="https://calendly.com/weinspecttogetherdev/we-inspect-professional-consult-call"
        pageSettings={{
          backgroundColor: "ffffff",
          primaryColor: "00a2ff",
          textColor: "4d5055",
        }}
      />

      <CalendlyEventListener onEventScheduled={onEventScheduled} />
    </div>
  );
};

export default Calendly;
