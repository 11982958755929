import "../Settings/style.css";

import AddHomeOwner from "./AddHomeOwner";
import AddInspectorNAccountManager from "./AddInspectorNAccountManager";
import ScrollBar from "react-perfect-scrollbar";
import SearchDropdown from "../../components/SelectDropdown";
import { useHookAddUser } from "./useHookAddUser";

const AddUser = () => {
  const [
    role,
    setrole,
    userDetails,
    setuserDetails,
    userHomeOwner,
    setuserHomeOwner,
    isSubmit,
    setisSubmit,
    errors,
    seterrors,
    disableSave,
    setDisableSave,
    handleSave,
    userInfo,
  ] = useHookAddUser();
  
  const userOptionsData = () => {
    return userInfo.role === "admin"
      ? [
        // TODO : "will remove when clients says open inspector mode"
          // { value: "inspector", label: "Inspector" },
          { value: "customer", label: "Home Owner" },
        ]
      : [
        // TODO : "will remove when clients says open inspector mode"
          // { value: "inspector", label: "Inspector" },
          { value: "customer", label: "Home Owner" },
          {
            value: "account_manager",
            label: "Account Manager",
          },
        ];
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 94px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              style={{ paddingInline: "55px" }}
              className={`btn btn-primary ml-auto ${
                disableSave ? "disabled-bg-color" : ""
              }`}
              onClick={() => handleSave()}
              disabled={disableSave}
            >
              Save
            </button>
          </div>
          <div className="row">
            <div className="col-md-9">
              <h4>
                Role
              </h4>
              <div className="row">
                <div className="col-md-6">
                  <SearchDropdown
                    placeHolder="Choose Role"
                    optionsData={userOptionsData()}
                    defaultOptionValue={role}
                    onSelect={(ev) => {
                      setuserHomeOwner({
                        first_name: "",
                        last_name: "",
                        phone_number: "",
                        email: "",
                        address1: "",
                        address2: "",
                        city: "",
                        state: "",
                        zipcode: "",
                        type: ev.value,
                      });
                      setuserDetails({
                        first_name: "",
                        last_name: "",
                        phone_number: "",
                        email: "",
                        notes: "",
                        avatar: null,
                        role: ev.value,
                      });
                      setrole(ev?.value);
                    }}
                  />
                  <small className="text-danger ml-1">
                    {isSubmit && role === ""
                      ? "Please select a inspector type"
                      : errors?.type
                      ? errors?.type[0]
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
          {(role === "inspector" || role === "account_manager") && (
            <AddInspectorNAccountManager
              userDetails={userDetails}
              setuserDetails={setuserDetails}
              errors={errors}
              showRole={false}
              role={role}
              disabled={false}
              addUser={true}
              isSubmit={isSubmit}
              setDisableSave={setDisableSave}

            />
          )}

          {role === "customer" && (
            <AddHomeOwner
              userHomeOwner={userHomeOwner}
              setuserHomeOwner={setuserHomeOwner}
              errors={errors}
              disabled={false}
              addUser={true}
              role={role}
              isSubmit={isSubmit}
              setDisableSave={setDisableSave}
            />
          )}
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddUser;
