import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const postTestByImg = (role, imgId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/images/${imgId}/tests`,
    { _method: "POST", tests: data }
  );
};
const patchTestByImg = (role, imgId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/images/${imgId}/tests`,
    { _method: "PATCH", ...data }
  );
};

const deleteTestByImageId = (role, imgId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/images/${imgId}/tests`,
    { _method: "DELETE", test_id: data }
  );
};

const getObservationByRoomForTests = (page, role, id, observation_id) => {
  // console.log(page, role, id, "apiCallPropsOne", observation_id);
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${id}/rooms/${observation_id}/observations?tests=true&page=${page}`
  );
};

export {
  postTestByImg,
  getObservationByRoomForTests,
  patchTestByImg,
  deleteTestByImageId,
};
