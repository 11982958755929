import "../style.css";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  checkResetTokenRequest,
  resetPassword,
} from "../../../../services/auth-service";

import CustomField from "../../../components/CustomField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessModal from "../../../components/SuccessModal";
import WITLogo from "../../../../assets/icons/password-img@2x.png";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import passIcon from "../../../../assets/icons/lock@2x.png";
import { regexes } from "../../../shared/regexes";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errors, seterrors] = useState({});
  const [opendel, setopendel] = useState(false);
  const [bothPassErr, setbothPassErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [FormSubmit, setFormSubmit] = useState(false);
  const { id, role } = useParams();
  let history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();
    setFormSubmit(true);
    setErrorText("");
    setShowSuccess(false);
    if (confirmPassword && password) {
      if (password.length < 8 || !regexes.password.test(password)) {
        setErrorText(
          "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
        );
        setpassErr(true);
      } else if (confirmPassword !== password) {
        setErrorText("Passwords do not match");
        setbothPassErr(true);
      }
      if (
        !password.length < 8 &&
        regexes.password.test(password) &&
        confirmPassword === password
      ) {
        setDisableReset(true);

        resetPassword({
          password,
          token: id,
          _method: "PATCH",
        })
          .then((resp) => {
            if (resp) {
              setopendel(true);
              setDisableReset(false);
            }
          })
          .catch((err) => {
            // setErrorText(getErrorMsg(err));
            setErrorText(err?.response?.data?.errors?.password);

            setDisableReset(false);
          });
      }
    }
  }

  useEffect(() => {
    console.log(id, "ididididid");
    checkResetTokenRequest(id)
      .then(() => {
        setErrorText("");
        setDisableReset(false);
      })
      .catch((err) => {
        if (err) {
          setErrorText(err?.response?.data?.message);
          setDisableReset(true);
        }
      });
  }, [id]);

  return (
    <div className="login-page reset-password">
      <div className="text-center">
        <img
          alt="WIT-logo"
          className="WIT-logo reset-pass"
          src={WITLogo}
          height="202px"
          width="130px"
          style={{
            marginBottom: "10px",
          }}
        />
      </div>
      <p className="forgot-h1 mb-1">Create new password</p>

      <div className="reset-password-text">
        <b>Enter your new password.</b>
      </div>

      {errorText !== "" ? (
        <div>
          <div className="email-pass-error">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-4 font-bold">{errorText}</span>
          </div>
        </div>
      ) : null}
      {showSuccess ? (
        <div>
          <div className="email-pass-success">
            <span className="ml-4 font-bold">
              Your password has been Successfully changed. Try logging in the
              web application again.
            </span>
          </div>
        </div>
      ) : null}

      <form onSubmit={handleSubmit}>
        <div id="float-label-auth" className="mt-1">
          <CustomField
            name="password"
            label="New Password"
            value={password}
            minWidth="410px"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="signup_password"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!password ||
                ((password.length < 8 || !regexes.password.test(password)) &&
                  passErr) ||
                (confirmPassword !== password && bothPassErr) ||
                (errors?.password && errors?.password[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1 error-text"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && !password
              ? "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
              : errors?.password
              ? errors?.password[0]
              : ""}
          </small>
        </div>
        <div className="margin-top-field">
          <CustomField
            name="confirmPassword"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="signup_confirmPassword"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!confirmPassword ||
                (confirmPassword !== password && bothPassErr) ||
                (errors?.confirmPassword && errors?.confirmPassword[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && !confirmPassword
              ? "Please enter confirm password"
              : errors?.confirmPassword
              ? errors?.confirmPassword[0]
              : ""}
          </small>
        </div>

        <div className="margin-top-field  text-align-end">
          <button
            disabled={disableReset}
            variant="primary"
            className={
              disableReset
                ? "login-btn disabled-bg-color"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            Reset Password
          </button>
        </div>
      </form>
      <div
        style={{
          paddingTop: "45px",
        }}
      >
        <Link
          to={
            (role === "super_admin" && "/login/product-owner") ||
            (role === "customer" && "/login/home-owner") ||
            (role === "admin" && "/login/account-manager") ||
            (role === "inspector" && "")
          }
          className="new-tab-link back-text"
        >
          Back to Login
        </Link>
      </div>
      <SuccessModal
        show={opendel}
        displayText="Your password has been changed!"
        company_name="bcd"
        closeOutside={true}
        redirectLink={
          (role === "super_admin" && "/login/product-owner") ||
          (role === "customer" && "/login/home-owner") ||
          (role === "admin" && "/login/account-manager") ||
          (role === "inspector" && "")
        }
        onHide={() => setopendel(false)}
      />
    </div>
  );
};

export default ResetPassword;
