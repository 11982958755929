import Inspection, {
  AddInspection,
  EditInspection,
} from "../main/pages/Inspection";

import AddUser from "../main/pages/Users/AddUser";
import CreatePassword from "../main/pages/Auth/CreatePassword";
import ForgetPassword from "../main/pages/Auth/ForgotPassword";
import InspectionDetails from "../main/pages/Inspection/InspectionDetails";
import Login from "../main/pages/Auth/Login";
import MaintenanceFile from "../main/pages/Maintenance";
import Property from "../main/pages/Properties";
import { default as PropertyAdd } from "../main/pages/Properties/PropertyAdd";
import PropertyEdit from "../main/pages/Properties/PropertyEdit";
import ResetPassword from "../main/pages/Auth/ResetPassword";
import ServerDownPage from "../main/pages/ServerDownPage/ServerDownPage";
import SettingsChangePassword from "../main/pages/Settings/SettingsChangePassword";
import SettingsUserDetails from "../main/pages/Settings/SettingsUserDetails";
import Signup from "../main/pages/Auth/Signup";
import TermsAndPolicy from "../main/pages/TermsAndPolicy";
import { ThirdPartyIntegration } from "../main/pages/ThirdParty";
// import EditUser from "../main/pages/Users/UsersDetails/EditUser";
import Users from "../main/pages/Users/Users";
import UsersDetails from "../main/pages/Users/UsersDetails";

export const authRoles = {
  superAdmin: "super_admin",
  bu_homeowner: "customer",
  buAdmin: "admin",
  buSubAdmin: "sub_admin",
  buCustomer: "bu_customer",
  ultraAdmin: "ultra_admin",
};

const routes = [
  // for separate actor urls
  // {
  //   name: "Login",
  //   path: "/login",
  //   component: Login,
  //   isInitial: true,
  //   isProtected: false,
  //   roles: [],
  //   exact: true,

  //   hideNavigation: true,
  //   showInSideNav: false,
  // },
  {
    name: "Login",
    path: "/login/home-owner",
    component: Login,
    isInitial: true,
    isProtected: false,
    roles: [],
    exact: true,

    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Login product Owner",
    path: "/login/product-owner",
    component: Login,
    isInitial: true,
    isProtected: false,
    roles: [],
    exact: true,

    hideNavigation: true,
    showInSideNav: false,
  },

  {
    name: "Login account manager",
    path: "/login/account-manager",
    component: Login,
    isInitial: true,
    isProtected: false,
    roles: [],
    exact: true,
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Signup",
    path: "/signup",
    component: Signup,
    isInitial: true,
    isProtected: false,
    roles: [],
    exact: true,

    hideNavigation: true,
    showInSideNav: false,
  },

  {
    name: "Forget-Password",
    path: "/forget-password",
    isInitial: true,
    component: ForgetPassword,
    isProtected: false,
    exact: true,

    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Forget-Password",
    path: "/forget-password/product-owner",
    isInitial: true,
    component: ForgetPassword,
    isProtected: false,
    exact: true,

    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Forget-Password",
    path: "/forget-password/account-manager",
    isInitial: true,
    component: ForgetPassword,
    isProtected: false,
    exact: true,

    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Reset-Password",
    path: "/reset-password/:id/:role",
    isInitial: true,
    component: ResetPassword,
    isProtected: false,
    roles: [],
    exact: true,
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Reset-Password",
    path: "/change-password",
    isInitial: true,
    component: CreatePassword,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
    exact: true,
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Terms of Use",
    path: "/terms-of-use",
    isInitial: true,
    component: TermsAndPolicy,
    isProtected: "both", //for routes that will be available with and without authentication
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    isInitial: true,
    component: TermsAndPolicy,
    isProtected: "both",
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    icon: "add-property@2x.png",
    name: "Add Property",
    path: "/properties/new",
    exact: true,
    component: PropertyAdd,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },
  {
    path: "/firebase-cloud-messaging-push-scope",
    exact: true,

    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
  },
  {
    path: "/firebase-messaging-sw.js",
    exact: true,

    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
  },
  {
    icon: "properties1@2x.png",
    name: "Properties",
    path: "/properties",
    navPath: "/properties?page=1",
    exact: true,
    component: Property,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showInSideNav: true,
    showSearch: true,
  },
  {
    icon: "inspector@2x.png",
    name: "Inspections",
    path: "/inspections",
    navPath: "/inspections?page=1",
    exact: true,
    component: Inspection,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showSearch: true,
    showInSideNav: true,
  },
  {
    icon: "users@2x.png",
    name: "Users",
    path: "/users",
    navPath: "/users?page=1",
    exact: true,
    component: Users,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin],
    showInSideNav: true,
    showSearch: true,
  },
  {
    icon: "Create Inspection@2x.png",
    iconWidth: "26px",
    name: "Create Inspection",
    path: "/inspections/new",
    exact: true,
    component: AddInspection,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.buAdmin,
      authRoles.bu_homeowner,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },
  {
    icon: "users@2x.png",
    name: "Inspection Details",
    path: "/inspections/:insp_id/details",
    exact: true,
    component: InspectionDetails,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin],
    showGoBack: true,
  },
  {
    icon: "inspector@2x.png",
    name: "Inspection Details",
    path: "/inspections/:insp_id",
    exact: true,
    component: EditInspection,
    isProtected: true,
    roles: [authRoles.bu_homeowner, authRoles.buAdmin],
    showGoBack: true,
  },
  {
    icon: "users@2x.png",
    name: "Add User",
    path: "/users/new",
    exact: true,
    component: AddUser,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },
  {
    icon: "settings-1@2x.png",
    name: "Settings",
    path: "/settings",
    exact: true,
    component: SettingsUserDetails,
    isProtected: true,
    hasChildren: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showInSideNav: true,
    childrens: [
      {
        name: "User Details",
        to: "/user-details",
        component: SettingsUserDetails,
      },
      {
        name: "Change Password",
        to: "/change-password",
        component: SettingsChangePassword,
      },
    ],
  },

  {
    icon: "settings-1@2x.png",
    name: "Server Down",
    path: "/maintainance",
    component: ServerDownPage,
    isProtected: "both",
    roles: [],
    hideNavigation: true,
    showInSideNav: false,
  },

  {
    icon: "properties1@2x.png",
    name: "Add Property",
    path: "/properties/new/:customerId/:customerName",
    exact: true,
    component: PropertyAdd,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },

  {
    icon: "properties-info@2x.png",
    name: "Property Details",
    path: "/property/:propertyId",
    exact: true,
    component: PropertyEdit,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },

  {
    icon: "inspector@2x.png",
    name: "Add Inspection",
    path: "/inspections/new/:customerId/:customerName/:propertyId/:propertyAdd",
    exact: true,
    component: AddInspection,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.bu_homeowner,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    showGoBack: true,
  },
  {
    icon: "users@2x.png",
    name: " ",
    path: "/users/:userId",
    exact: true,
    component: UsersDetails,
    isProtected: true,
    roles: [
      authRoles.superAdmin,
      authRoles.buAdmin,
      authRoles.buSubAdmin,
      authRoles.ultraAdmin,
    ],
    rating: "",
    showGoBack: true,
  },
  {
    name: "User pay screen",
    path: "/payment",
    exact: true,
    component: ThirdPartyIntegration,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
    showGoBack: true,
    hideSideNav: true,
    showInSideNav: false,
  },
  {
    name: "User qrcode screen",
    path: "/qrcode",
    exact: true,
    component: ThirdPartyIntegration,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
    showGoBack: true,

    hideSideNav: true,
    showInSideNav: false,
  },
  {
    name: "User calendly screen",
    path: "/calendly",
    exact: true,
    component: ThirdPartyIntegration,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
    showGoBack: true,
    hideSideNav: true,
    showInSideNav: false,
  },

  // {
  //   name: "User calendly screen",
  //   path: "/calendly",
  //   exact: true,
  //   component: ,
  //   isProtected: true,
  //   roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
  //   showGoBack: true,
  //   hideSideNav: true,
  //   showInSideNav: false,
  // },

  {
    name: "maintenanceFile",
    path: "/maintenance",
    component: MaintenanceFile,
    isInitial: true,
    isProtected: true,
    roles: [authRoles.superAdmin, authRoles.buAdmin, authRoles.bu_homeowner],
    exact: true,
    hideNavigation: true,
    showInSideNav: false,
  },
  {
    name: "maintenanceFile",
    path: "/maintenance",
    component: MaintenanceFile,
    isInitial: true,
    isProtected: false,
    roles: [],
    exact: true,
    hideNavigation: true,
    showInSideNav: false,
  },
];

export default routes;
