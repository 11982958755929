import "./style.css";

import React, { useState } from "react";
import { getErrorMsg, showToast } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";

import CustomField from "../../components/CustomField";
import { LOG_IN } from "../../../store/actions";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import passIcon from "../../../assets/icons/lock@2x.png";
import { regexes } from "../../shared/regexes";
import { updateSettings } from "../../../services/SettingServices";
import { useHistory } from "react-router-dom";

const SettingsChangePassword = () => {
  const dispatch = useDispatch();
  const [dis, setdis] = useState(false);
  const userInfo = useSelector((state) => state.user.data);
  // console.log(userInfo, "userInfo");
  const [isSubmit, setIsSubmit] = useState(false);

  const [changePass, setChangePass] = useState({
    password: "",
    old_password: "",
    confirmPassword: "",
  });

  const [errors, seterrors] = useState({});
  const [bothPassErr, setbothPassErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  // const [editEnabled, seteditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const EditSettings = () => {
    console.log(errors, "password_changed_flagpassword_changed_flag");
    setIsSubmit(true);

    if (
      changePass?.password?.length < 8 ||
      !regexes.password.test(changePass?.password)
    ) {
      setpassErr(true);
    } else if (changePass?.confirmPassword !== changePass?.password) {
      setbothPassErr(true);
    } else if (
      changePass?.confirmPassword &&
      changePass?.password &&
      changePass?.old_password
    ) {
      setdis(true);

      updateSettings(userInfo?.role, { ...changePass, _method: "PATCH" })
        .then((resp) => {
          // history.push("/properties/32");
          if (resp) {
            seterrors({});
            showToast("Your password has been changed!", true);
            dispatch({
              type: LOG_IN,
              payload: resp.data,
            });
            setChangePass({
              ...changePass,
              password: "",
              old_password: "",
              confirmPassword: "",
            });
            setIsSubmit(false);
          }
          // history.push("/properties");
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
            // showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => {
          setpassErr(false);
          setbothPassErr(false);
          setdis(false);
        });
    }
  };

  const getSettings = () => {
    setIsLoading(true);
    setChangePass({
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      email: userInfo?.email,
    });
    setIsLoading(false);
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area" style={{ padding: "24px 35px" }}>
            <div className="d-flex align-items-center">
              <>
                <>
                  {" "}
                  <button
                    // className="btn btn-primary ml-auto"
                    style={{ paddingInline: "55px" }}
                    onClick={EditSettings}
                    id="save_settings"
                    disabled={
                      dis ||
                      changePass.password === "" ||
                      changePass.old_password === "" ||
                      changePass.confirmPassword === ""
                    }
                    className={`btn btn-primary ml-auto ${
                      (changePass.password === "" ||
                        changePass.old_password === "" ||
                        changePass.confirmPassword === "") &&
                      "dis-btn"
                    }`}
                  >
                    Save
                  </button>
                </>
              </>
            </div>

            <div className="mt-4">
              <div className="center-column">
                <h3>
                  Change Password
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className=" mt-3">
                      <CustomField
                        minWidth="400px"
                        name="password"
                        label="Enter Old Password"
                        value={changePass?.old_password}
                        onChange={(e) =>
                          setChangePass({
                            ...changePass,
                            old_password: e.target.value,
                          })
                        }
                        password={true}
                        id="old_password"
                        className={`auth-input-field`}
                        icon={passIcon}
                        errors={
                          isSubmit &&
                          (changePass?.old_password === "" ||
                            (errors?.old_password && errors?.old_password[0]))
                        }
                        type="password"
                        inputProps={{
                          autoComplete: "new-password",
                          maxLength: "16",
                        }}
                      />
                      {console.log(
                        changePass?.oldpassword,
                        "changePass?.oldpasswordpay?.oldpassword"
                      )}

                      <small className="text-danger ml-1 error-text">
                        {isSubmit && !changePass?.old_password
                          ? "Please enter old password"
                          : errors?.old_password
                          ? errors?.old_password[0]
                          : ""}
                      </small>
                    </div>

                    <div className=" ">
                      <CustomField
                        name="password"
                        minWidth="400px"
                        label="New Password"
                        value={changePass?.password}
                        icon={passIcon}
                        onChange={(e) =>
                          setChangePass({
                            ...changePass,
                            password: e.target.value,
                          })
                        }
                        password={true}
                        id="signup_password"
                        className={`auth-input-field`}
                        errors={
                          isSubmit &&
                          (changePass?.password === "" ||
                            ((changePass?.password?.length < 8 ||
                              !regexes.password.test(changePass?.password)) &&
                              passErr) ||
                            (errors?.password && errors?.password[0]))
                        }
                        type="password"
                        inputProps={{
                          maxLength: "16",
                        }}
                      />

                      <small className="text-danger ml-1 error-text">
                        {isSubmit &&
                        (changePass?.password?.length < 8 ||
                          !regexes.password.test(changePass?.password))
                          ? "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
                          : errors?.password
                          ? errors?.password[0]
                          : ""}
                      </small>
                    </div>
                    <div>
                      <CustomField
                        name="confirmPassword"
                        minWidth="400px"
                        label="Confirm New Password"
                        value={changePass?.confirmPassword}
                        onChange={(e) =>
                          setChangePass({
                            ...changePass,
                            confirmPassword: e.target.value,
                          })
                        }
                        icon={passIcon}
                        password={true}
                        id="signup_confirmPassword"
                        className={`auth-input-field`}
                        errors={
                          isSubmit &&
                          (changePass?.confirmPassword === "" ||
                            (changePass?.confirmPassword !==
                              changePass?.password &&
                              bothPassErr) ||
                            (errors?.confirmPassword &&
                              errors?.confirmPassword[0]))
                        }
                        type="password"
                        inputProps={{
                          maxLength: "16",
                        }}
                      />
                      <small className="text-danger ml-1">
                        {isSubmit && changePass?.confirmPassword === ""
                          ? "Please enter confirm password"
                          : bothPassErr
                          ? "Passwords do not match"
                          : errors?.confirmPassword
                          ? errors?.confirmPassword[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="center-change-pass">
                      <img
                        alt="change_pass_logo"
                        src={
                          require(`../../../assets/icons/password-icon@2x.png`)
                            .default
                        }
                      />
                      <p style={{ fontSize: "20px" }}>
                         Enter your new password
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ScrollBar>
    </div>
  );
};

export default SettingsChangePassword;
