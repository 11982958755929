import * as Actions from "../actions";

import { getFilters } from "../../main/shared/helpers";

const initialState = {
  title: "",
  previousPath: "",
  icon: "properties1@2x.png",
  searchField: "",
  showSearch: false,
  searchLabel: "",
  searchFilter: getFilters(),
  searchFilterAPI: false,
  rating: "",
  notificationsList: false,
};

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SEARCH_FIELD:
      return { ...state, searchField: action.payload };
    case Actions.SET_HEADER:
      return { ...state, ...action.payload };
    case Actions.SHOW_SEARCH:
      return { ...state, showSearch: action.payload };
    case Actions.SET_SEARCH_LABEL:
      return { ...state, searchLabel: action.payload };
    case Actions.SET_SEARCH_FILTER:
      return { ...state, searchFilter: action.payload };
    case Actions.SET_SEARCH_FILTER_API:
      return { ...state, searchFilterAPI: action.payload };
    case Actions.SET_RATING:
      return { ...state, rating: action.payload };
    case Actions.SET_NOTIFICATIONS:
      return { ...state, notificationsList: action.payload };

    default:
      return state;
  }
}
