import "./style.css";

import { Spinner, Tabs } from "react-bootstrap";
import { getErrorMsg, setHeader, showToast } from "../../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import ScrollBar from "react-perfect-scrollbar";
import Tab from "react-bootstrap/Tab";
import UserInfo from "./UserInfo";
import UserReviews from "./UserReviews";
import { getUserByID } from "../../../../services/UserService";
import { useParams } from "react-router-dom";

const UsersDetails = () => {
  const [key, setKey] = useState("UserInfo");
  const params = useParams();
  const userInfo = useSelector((state) => state?.user?.data);
  // console.log(params);
  const [userDetails, setuserDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    notes: "",
    avatar: "",
  });
  const [userHomeOwner, setuserHomeOwner] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address1: "",
    address2: null,
    city: "",
    state: "",
    zipcode: "",
    type: "",
  });
  const [isLoading, setisLoading] = useState(true);
  const [errors, seterrors] = useState({});
  const [role, setrole] = useState();
  const [imgUser, setimgUser] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    const getUserData = () => {
      setisLoading(true);
      getUserByID(userInfo?.role, params?.userId)
        .then((res) => {
          console.log(res);
          setHeader(dispatch, {
            title: `${res?.data.first_name} ${res?.data.last_name}`,
            rating: res.data.role === "admin" ? false : res?.data?.rating,
          });
          setimgUser(res?.data.avatar);

          if (res.data) {
            setrole(res.data.role);
            if (res.data.role === "customer") {
              setuserHomeOwner({ ...res?.data?.address, ...res.data });
            } else {
              setuserDetails(res?.data);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
            showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => setisLoading(false));
    };
    getUserData();
  }, [userInfo?.role, params?.userId, dispatch]);

  return (
    <>
      <div className="main" style={{ height: "80vh" }}>
        {isLoading ? (
          <div className="center-spinner">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <Tabs
            className="setting-tab-btns"
            defaultActiveKey="UserInfo"
            transition={false}
            onSelect={(k) => {
              setKey(k);
            }}
          >
            <Tab eventKey="UserInfo" title="User Info">
              <ScrollBar style={{ height: "calc(100vh - 146px)" }}>
                {key === "UserInfo" && (
                  <UserInfo
                    userInfo={userInfo}
                    userHomeOwner={userHomeOwner}
                    setuserHomeOwner={setuserHomeOwner}
                    userDetails={userDetails}
                    setuserDetails={setuserDetails}
                    userId={params?.userId}
                    setHeader={setHeader}
                    dispatch={dispatch}
                    errors={errors}
                    role={role}
                    imgUser={imgUser}
                    setrole={setrole}
                    seterrors={seterrors}
                  />
                )}
              </ScrollBar>
            </Tab>
            {role !== "admin" && (
              <Tab eventKey="userReviews" title="Reviews">
                {key === "userReviews" && (
                  <UserReviews userId={params?.userId} />
                )}
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    </>
  );
};

export default UsersDetails;
