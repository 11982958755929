import "../../style.css";

import React, { useEffect, useState } from "react";

const TaskListing = ({
  tasks,
  setselectedTask,
  selectedTask,
  setselectedTaskIndex,
  selectedTaskIndex,
  setselectedTaskNumber,
  taskpageIndex,
  setloadingImg,
  setcounterImg,
  setShowCheckbox,
  showCheckbox,
  setisChecked,
  setCheckedData,
  setCheckedCount,
}) => {
  const [deleteList, setdeleteList] = useState([]);
  const [checkedCounts, setCheckedCounts] = useState(0);

  useEffect(() => {
    setCheckedData([...deleteList]);

    setisChecked(deleteList.length > 0 ? true : false);
    setCheckedCount(checkedCounts);
  }, [checkedCounts, deleteList]);

  return (
    <>
      {tasks && tasks?.data?.length !== 0 && (
        <div className="tasklist">
          {tasks?.data?.map((task, i) => (
            <React.Fragment key={task.id}>
              <li
                className="d-flex "
                onClick={() => {
                  setselectedTaskIndex(i);
                  setselectedTaskNumber(taskpageIndex + i);
                  setloadingImg(true);
                  setcounterImg(0);
                  setselectedTask(task);
                }}
                style={{
                  justifyContent: "space-between",
                  cursor: "pointer",
                  fontFamily:
                    selectedTaskIndex === i
                      ? "Basis-Grotesque-Pro-Medium"
                      : "Basis-Grotesque-Pro-Regular",
                  color:
                    task.status === "approved"
                      ? "#64BC51"
                      : task.status === "rejected"
                      ? "#FF1A1A"
                      : task.status === "pending_2"
                      ? "#FFAA00"
                      : "black",
                }}
                key={task}
              >
                {/* {console.log(task, "----")} */}

                <span>
                  <input
                    type="checkbox"
                    className="deleteCheckbox checkbox"
                    id={i}
                    name={task?.name}
                    value={task?.id}
                    style={{
                      display: showCheckbox === true ? "unset" : "none",
                    }}
                    disabled={task.status === "approved" ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setdeleteList([...deleteList, Number(e.target.value)]);
                        setCheckedCounts(checkedCounts + 1);
                      } else {
                        setCheckedCounts(checkedCounts - 1);
                        const index = deleteList.indexOf(
                          Number(e.target.value)
                        );
                        console.log(index, "index_index");
                        if (index !== -1) {
                          deleteList.splice(index, 1);
                          setdeleteList([...deleteList]);
                        }
                      }
                    }}
                  />
                  Feature {taskpageIndex && taskpageIndex + i}:{" "}
                  {task.room_name ? " (" + task.room_name + ") " : ""}{" "}
                  {task?.name}
                </span>

                <span>
                  {task?.under_review && (
                    <img
                      alt="status"
                      src={
                        require("../../../../../assets/icons/under-review-icon.png")
                          .default
                      }
                    />
                  )}
                </span>
              </li>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default TaskListing;
