import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";

import { regexes } from "../../../shared/regexes";
import { showToast } from "../../../shared/helpers";
import { updateProfilePassword } from "../../../../services/auth-service";

export const useCreatePassword = () => {
  const [password, setPassword] = useState("");

  const [oldpassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errors, seterrors] = useState({});
  const [opendel, setopendel] = useState(false);
  const [bothPassErr, setbothPassErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [FormSubmit, setFormSubmit] = useState(false);
  const { role } = useParams();
  let history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();
    setFormSubmit(true);
    setErrorText("");
    setShowSuccess(false);
    if (confirmPassword && password && oldpassword) {
      if (password.length < 8 || !regexes.password.test(password)) {
        setErrorText(
          "Password must be between 8 and 16 characters which include an upper case, lower case, number, and special characters"
        );
        setpassErr(true);
      } else if (confirmPassword !== password) {
        setErrorText("Passwords do not match");
        setbothPassErr(true);
      }
      if (
        !password.length < 8 &&
        regexes.password.test(password) &&
        confirmPassword === password
      ) {
        setDisableReset(true);

        updateProfilePassword({
          password,
          old_password: oldpassword,
          _method: "PATCH",
        })
          .then((resp) => {
            if (resp) {
              setopendel(true);
              setDisableReset(false);
            }
          })
          .catch((err) => {
            setDisableReset(false);

            if (err?.response?.status === 422) {
              if (err?.response?.data?.errors?.old_password) {
                setErrorText(err?.response?.data?.errors.old_password[0]);
              } else if (err?.response?.data?.errors?.password) {
                showToast(err?.response?.data?.errors.password[0]);
                // setErrorText();
                seterrors(err?.response?.data?.errors);
              }
            }
            if (err?.response?.status === 400) {
              setErrorText(err?.response?.data?.message);
            }
          });
      }
    }
  }

  const handleSkip = () => {
    updateProfilePassword({
      password_changed_flag: "true",
      _method: "PATCH",
    })
      .then((resp) => {
        if (resp) {
          history.replace("/properties");
        }
      })
      .catch((err) => {
        setDisableReset(false);

        if (err?.response?.status === 422) {
          if (err?.response?.data?.errors?.old_password) {
            setErrorText(err?.response?.data?.errors.old_password[0]);
          } else if (err?.response?.data?.errors?.password) {
            showToast(err?.response?.data?.errors.password[0]);
            // setErrorText();
            seterrors(err?.response?.data?.errors);
          }
        }
        if (err?.response?.status === 400) {
          setErrorText(err?.response?.data?.message);
        }
      });
  };

  return [
    password,
    setPassword,
    oldpassword,
    setOldPassword,
    confirmPassword,
    setConfirmPassword,
    showSuccess,
    setShowSuccess,
    disableReset,
    setDisableReset,
    errorText,
    setErrorText,
    errors,
    seterrors,
    opendel,
    setopendel,
    bothPassErr,
    setbothPassErr,
    passErr,
    setpassErr,
    FormSubmit,
    setFormSubmit,
    role,
    handleSubmit,
    handleSkip,
    history,
  ];
};
