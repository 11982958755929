import "./style.css";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import {
  getAddress,
  roleNames,
  showToast,
  timeConversionUTC,
  truncate,
  utcDate,
} from "../../shared/helpers";
import { updateInspectionbyId } from "../../../services/InspectionService";

import { useHistory } from "react-router-dom";

export const InspectionList = (props) => {
  const { inspection, role, setinspectionList } = props;
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const inspectionLink = (id) => {
    if (role === roleNames.superAdmin || role === roleNames.buAdmin) {
      history.push(`/inspections/` + id + "/details?tab=generalInfo");
    } else {
      history.push(`/inspections/` + id);
    }
  };

  const deactivatedInspection = (id, checked) => {
    setButtonDisable(true);
    const data = {
      deactivate: checked === false ? "true" : "false",
    };
    updateInspectionbyId(id, data, role)
      .then((resp) => {
        showToast("Inspection activated successfully.", true);
      })
      .catch((err) => {
        showToast("Inspection deactivated successfully.");
      })
      .finally(() => {
        // setloader(false);
        setButtonDisable(false);
      });
  };

  return Object.keys(inspection).length !== 0 &&
    inspection?.data?.length !== 0 ? (
    inspection.data?.map((item, i) => (
      <tr key={`inspection-${i}`} style={{ cursor: "pointer" }}>
        <td className="" onClick={() => inspectionLink(item.id)}>
          <span>{utcDate(item?.created_at)}</span>
          <br />
          <span>{timeConversionUTC(item?.created_at)}</span>
        </td>
        <td onClick={() => inspectionLink(item.id)}>
          <span className="property-span">
            {getAddress(item?.property?.address)}
          </span>
        </td>
        <td onClick={() => inspectionLink(item.id)}>
          <span className="maxWidthName">
            {" "}
            {truncate(
              item?.customer?.first_name + " " + item?.customer?.last_name
            )}
          </span>
        </td>
        {/* // TODO : "will remove when clients says open inspector mode"  
        <td onClick={() => inspectionLink(item.id)}>
          {item?.inspectors[0]?.first_name && item?.inspectors[0]?.last_name ? (
            <span className="maxWidthName">
              {truncate(
                item?.inspectors[0]?.first_name +
                  " " +
                  item?.inspectors[0]?.last_name
              )}
            </span>
          ) : (
            <span style={{ paddingInline: "37px" }}>--</span>
          )}
        </td> */}
        <td className="" onClick={() => inspectionLink(item.id)}>
          <span>
            {" "}
            {!item?.inspectors.length
              ? " Not Assigned"
              : item?.status === "completed"
              ? "Completed"
              : item?.status === "pending"
              ? "Pending"
              : item?.status === "in_progress"
              ? "In Progress"
              : null}
          </span>
        </td>
        <td className="" onClick={() => inspectionLink(item.id)}>
          <span style={item?.paid_status === "un_paid" ? { color: "red" } : {}}>
            {" "}
            {item?.paid_status === "paid" ? "Paid" : "Unpaid"}
          </span>
        </td>

        {/* // TODO : "will remove when clients says open inspector mode" 
         <td className="" onClick={() => inspectionLink(item.id)}>
          <span>
            {" "}
            {item?.type === "self_inspect" ? "Self Inspect" : "WIT Inspect"}
          </span> 
        </td> */}
        {role !== roleNames.bu_homeowner && (
          <td style={{ minWidth: "86px", textAlign: "center" }}>
            <label className="switches">
              <input
                type="checkbox"
                disabled={buttonDisable}
                id={i}
                checked={item.deleted_at === null}
                onChange={(event) => {
                  deactivatedInspection(item.id, event.target.checked);
                  event.target.checked === false
                    ? setinspectionList(
                        { ...inspection },
                        (inspection.data[event.target.id].deleted_at = "")
                      )
                    : setinspectionList(
                        { ...inspection },
                        (inspection.data[event.target.id].deleted_at = null)
                      );
                }}
              />
              <span
                className="sliders rounds"
                style={
                  buttonDisable === true
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              ></span>
            </label>
          </td>
        )}
        <td onClick={() => inspectionLink(item.id)}>
          <div>
            <button className="next-btn ripple">
              <img
                alt="arrow"
                src={require("../../../assets/icons/arrow@2x.png").default}
              />
            </button>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No record found</td>
    </tr>
  );
};
