export default function ImageBoxInstructional({
  image_data,
  style,
  selectedTaskIndex,
  selectedTaskNumber,
  image_number,
  setselectedInstructImageModalView,
  setselectedInstructImageNumber,
  setselectedInstructImageIndex,
  setselectedSubmittedImageModal,
  setselectedSubmittedImageIndex,
  setselectedSubmittedImageNumber,
  setConditionalViewer,
  selectedTask,
  inspectionDetails,
  user_role,
}) {
  const modalConditions = () => {
    if (inspectionDetails?.status === "completed") {
      return false;
    } else {
      return (
        selectedTask?.status !== "pending" &&
        image_data?.status !== "rejected" &&
        image_data?.status !== "approved" &&
        image_data?.status !== "submitted"
      );
    }
  };
  return (
    <div className="d-flex flex-column mb-3">
      <div
        style={{
          height: "120px",
          width: "120px",
          border: "2px solid #DEE2DF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderRadius: "8px",
          margin: "5px 28px 10px 1px",
          transition: "0.5s",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "18px",
          ...style,
        }}
      >
        <div style={{ position: "absolute" }}>
          <img
            alt="info"
            src={require("../../../assets/icons/Group 6812@2x.png").default}
            width="18px"
            style={{
              position: "absolute",
              right: "9px",
              top: "6px",
              width: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setselectedInstructImageIndex(image_number);
              setselectedInstructImageModalView(true);
              setselectedInstructImageNumber(
                `${selectedTaskNumber}${String.fromCharCode(65 + image_number)}`
              );
            }}
          />

          <img
            src={image_data?.ph_thumb_url}
            key={image_data?.ph_thumb_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                require("../../../assets/icons/dummy-image.png").default;
              currentTarget.style =
                "height: 120px;width: 120px;object-fit: contain;background: #FFFFFF 0% 0% no-repeat padding-box;border-radius: 18px; box-shadow:4px 2px 13px 0px #00000029;";
            }}
            onClick={() => {
              if (modalConditions()) {
                setselectedSubmittedImageModal(true);
                setselectedSubmittedImageIndex(image_number);
                setselectedSubmittedImageNumber(
                  `${selectedTaskIndex + 1}${String.fromCharCode(
                    65 + image_number
                  )}`
                );
                setConditionalViewer("instructional");
              }
            }}
            alt="test"
            style={{
              height: "120px",
              width: "120px",
              objectFit: "contain",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "18px",
              boxShadow: "4px 2px 13px 0px #00000029",
              cursor: modalConditions() && "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}
