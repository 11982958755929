import "../style.css";

import { useHistory, useLocation } from "react-router-dom";
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { forgetPassword } from "../../../../services/auth-service";
import { regexes } from "../../../shared/regexes";

export const useForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disableReset, setDisableReset] = useState(false);
  const [FormSubmit, setFormSubmit] = useState(false);
  const [errors, seterrors] = useState({});
  const [opendel, setopendel] = useState(false);
  const [role, setrole] = useState("");
  const [SuccessMsg, setSuccessMsg] = useState("");
  let history = useHistory();
  const location = useLocation();

  function handleSubmit(event) {
    setErrorText("");
    seterrors({});
    setFormSubmit(true);
    if (email && !regexes.email.test(email)) {
      setErrorText("Please enter a valid email address");
    } else if (email && regexes.email.test(email)) {
      setDisableReset(true);
      forgetPassword({ email })
        .then((resp) => {
          if (resp) {
            setrole(resp.data.role);
            setSuccessMsg(
              "Please check your email for instructions on resetting your password."
            );
            setopendel(true);
          }
        })
        .catch((err) => {
          setopendel(false);
          setErrorText(err?.response?.data?.errors?.email);
        })
        .finally(() => {
          setDisableReset(false);
        });
    }
    event.preventDefault();
  }
  return [
    email,
    setEmail,
    errorText,
    setErrorText,
    errors,
    seterrors,
    FormSubmit,
    setFormSubmit,
    location,
    handleSubmit,
    disableReset,
    opendel,
    role,
    SuccessMsg,
    history,
    setopendel,
  ];
};
