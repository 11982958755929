import "./style.css";

import React, { useState } from "react";
import { getErrorMsg, showToast } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";

import CustomField from "../../components/CustomField";
import ImageIcon from "../../../assets/icons/camera@2x.png";
import { LOG_IN } from "../../../store/actions";
import PhoneInput from "react-phone-input-2";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { fileSizeLimits } from "../../shared/validations";
import { profileRequest } from "../../../services/auth-service";
import { updateSettings } from "../../../services/SettingServices";
import { useHistory } from "react-router-dom";
import { uploadFile } from "../../../services/UploadFile";


const Settingspay = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [dis, setdis] = useState(false);
  const userInfo = useSelector((state) => state.user.data);
  // console.log(userInfo, "userInfo");
  const [isSubmit, setIsSubmit] = useState(false);

  // image
  const defaultImage = require("../../../assets/default_profile.png").default;

  const [preview, setPreview] = useState(
    userInfo?.avatar ? userInfo.avatar : defaultImage
  );
  const [file, setFile] = useState(null);
  const [file_e, setFile_e] = useState("");

  const [user, setUser] = useState({
    first_name: userInfo?.first_name,
    last_name: userInfo?.last_name,
    email: userInfo?.email,
    avatar: userInfo?.avatar,
    id: userInfo?.id,
    phone_number: userInfo?.phone_number,
  });

  const [errors, seterrors] = useState({});
  const [bothPassErr, setbothPassErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [editEnabled, seteditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const EditSettings = () => {
    setIsSubmit(true);

    const formData = new FormData();
    formData.append("first_name", user?.first_name);
    formData.append("last_name", user?.last_name);
    formData.append("_method", "PATCH");
    formData.append("phone_number", user?.phone_number);

    if (user?.avatar !== userInfo?.avatar) {
      formData.append("avatar", user?.avatar);
    }

    if (
      user.first_name &&
      user.first_name.trim() &&
      user.last_name &&
      user.last_name.trim() &&
      user?.phone_number &&
      user?.phone_number.length > 10
    ) {
      setdis(true);
      updateSettings(userInfo?.role, formData, user.id)
        .then((resp) => {
          // history.push("/properties/32");
          if (resp) {
            seterrors({});
            seteditEnabled(false);
            showToast("Your information has been updated!", true);
            dispatch({
              type: LOG_IN,
              payload: resp.data,
            });
          }
          // history.push("/properties");
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
            // showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => {
          setpassErr(false);
          setbothPassErr(false);
          setdis(false);
        });
    }
  };

  const getSettings = () => {
    setIsLoading(true);

    profileRequest()
      .then((res) => {
        console.log(res, "first_name");
        setUser({
          first_name: res?.data?.first_name,
          last_name: res?.data?.last_name,
          email: res?.data?.email,
          phone_number: res?.data?.phone_number,
          avatar: res?.data?.avatar ? res.data.avatar : defaultImage,
        });
        setPreview(res?.data?.avatar ? res.data.avatar : defaultImage);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          seterrors(err?.response?.data?.errors);
          // showToast(err?.response?.data?.message);
        } else {
          showToast(getErrorMsg(err));
        }
      })
      .finally(() => {
        setpassErr(false);
        setbothPassErr(false);
        setIsLoading(false);
        setdis(false);
      });
  };

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.name.includes(".jfif")) {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else {
        setdis(true)
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
        const namefile = file.name.split(".").pop();
        const filename = `${new Date().getTime()}.${namefile}`;
        uploadmagetoS3(file, filename);
      }
    }
  };


  const uploadmagetoS3 = (file, filename) => {
    uploadFile(file, filename)
      .then((url) => {
        setUser({ ...user, avatar: url });
        setdis(false)
      })
      .catch((er) => {
        console.log(er, "");
      });
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        {isLoading ? (
          <div className="spinner-div">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <div className="main-content-area">
            <div className="d-flex align-items-center">
              <>
                {editEnabled ? (
                  <>
                    {" "}
                    <button
                      className="btn btn-primary ml-auto"
                      disabled={dis}
                      style={{ paddingInline: "55px" }}
                      onClick={EditSettings}
                      id="save_settings"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-primary"
                      disabled={dis}
                      style={{
                        background: "transparent",
                        borderColor: "#14444d",
                        paddingInline: "47px",
                        marginLeft: "16px",
                      }}
                      onClick={() => {
                        getSettings();
                        seteditEnabled(false);
                      }}
                      id="cancel_edit_settings"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-primary ml-auto"
                    disabled={dis}
                    onClick={() => seteditEnabled(true)}
                    id="edit_settings"
                    style={{ paddingInline: "30px" }}
                  >
                    <img
                      alt="Edit settings"
                      src={require(`../../../assets/icons/edit@2x.png`).default}
                    />
                    Edit Info
                  </button>
                )}
              </>
            </div>
            <div>
              <h4 style={{ marginBottom: "2%" }}>
                User Details
              </h4>
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <CustomField
                        name="first"
                        label="First Name"
                        type="text"
                        value={user?.first_name}
                        disabled={editEnabled ? false : true}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^A-Za-z ]/gi, "");
                          setUser({ ...user, first_name: value });
                        }}
                        id="first_name"
                        errors={
                          isSubmit &&
                          (!user.first_name ||
                            !user.first_name.trim() ||
                            (errors?.first_name && errors?.first_name[0]))
                        }
                        inputProps={{
                          maxLength: "40",
                        }}
                      />
                      <small className="text-danger ml-1">
                        {isSubmit &&
                        editEnabled &&
                        user?.first_name.length === 0
                          ? "Please enter first name"
                          : isSubmit &&
                            editEnabled &&
                            user?.first_name.length !== 0 &&
                            !user?.first_name.trim()
                          ? "Required field cannot be left blank"
                          : errors?.first_name
                          ? errors?.first_name[0]
                          : ""}
                      </small>
                    </div>
                    <div className="col-md-6">
                      <CustomField
                        name="last"
                        label="Last Name"
                        disabled={editEnabled ? false : true}
                        type="text"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^A-Za-z ]/gi, "");
                          setUser({ ...user, last_name: value });
                        }}
                        id="last_name"
                        value={user?.last_name}
                        errors={
                          isSubmit && errors?.last_name && errors?.last_name[0]
                        }
                        inputProps={{
                          maxLength: "40",
                        }}
                      />
                      <small className="text-danger ml-1">
                        {isSubmit && editEnabled && user?.last_name.length === 0
                          ? "Please enter first name"
                          : isSubmit &&
                            editEnabled &&
                            user?.last_name.length !== 0 &&
                            !user?.last_name.trim()
                          ? "Required field cannot be left blank"
                          : errors?.last_name
                          ? errors?.last_name[0]
                          : ""}
                      </small>
                    </div>

                    <div className="col-md-6">
                      <CustomField
                        name="email"
                        label="Email"
                        type="text"
                        disabled={true}
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                        id="email"
                        value={user?.email}
                      />
                      <small className="text-danger ml-1"></small>
                    </div>
                    <div className="col-md-6">
                      <PhoneInput
                        country={"us"}
                        disabled={editEnabled ? false : true}
                        buttonClass={!editEnabled ? "flag-dis" : "flag-enable"}
                        value={user?.phone_number}
                        onChange={(e) => {
                          let value = e;

                          setUser({
                            ...user,
                            phone_number: value,
                          });
                        }}
                      />
                      <small className="text-danger ml-1">
                        {isSubmit &&
                        editEnabled &&
                        user?.phone_number?.length === 0
                          ? "Please enter a phone number"
                          : isSubmit &&
                            editEnabled &&
                            user?.phone_number?.length !== 0 &&
                            !user?.phone_number?.trim()
                          ? "Required field cannot be left blank"
                          : isSubmit && user?.phone_number?.length < 11
                          ? "The phone number at least have 11 digits"
                          : errors?.phone_number
                          ? errors?.phone_number[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 d-flex justify-content-end">
                  <div className="upload-avatar">
                    <div
                      className="image-area"
                      disabled={editEnabled ? false : true}
                    >
                      {!preview ? (
                        <span
                          className="upload-icon"
                          style={
                            preview
                              ? { backgroundColor: "transparent" }
                              : { backgroundColor: "#f3f3f3" }
                          }
                        >
                          <img src={`${ImageIcon}`} alt="" />
                        </span>
                      ) : null}
                      <img
                        width="155"
                        className="avatar-lg"
                        src={`${
                          preview?.includes("blob:htt") ? preview : `${preview}`
                        }`}
                        alt=""
                      />
                      <input
                        type="file"
                        className="upload-image"
                        accept="image/jpeg,image/png"
                        onChange={(e) => {
                          fileChangedHandler(e);
                        }}
                        disabled={editEnabled ? false : true}
                      />
                    </div>
                    <p>Upload Photo (Optional)</p>
                    <small className="text-danger ml-1">{file_e}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ScrollBar>
    </div>
  );
};

export default Settingspay;
