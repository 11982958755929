import axios from "../axios-defaults";

const loginRequest = (userCredetials) => {
  return axios.post("v1/tokens/create", userCredetials);
};
const signupRequest = (userCredetials) => {
  return axios.post("v1/account", userCredetials);
};

const refreshTokenRequest = (data) => {
  return axios.post("/oauth/token", data, { _retry: true });
};

const profileRequest = () => {
  return axios.get("v1/account");
};

const forgetPassword = (data) => {
  return axios.post("v1/forgot-password/request", data);
};

const resetPassword = (data) => {
  return axios.post("v1/forgot-password/reset", data);
};

const updateProfilePassword = (data) => {
  return axios.post("v1/account", data);
};

const contentRequest = (param) => {
  return axios.get(`v1/contents?type=${param}`);
};

const checkResetTokenRequest = (token) => {
  return axios.get(`v1/forgot-password/reset?token=${token}`);
};

const logoutRequest = (body) => {
  return axios.post(`v1/account/logout`, body);
};

export {
  loginRequest,
  signupRequest,
  profileRequest,
  forgetPassword,
  updateProfilePassword,
  resetPassword,
  refreshTokenRequest,
  contentRequest,
  checkResetTokenRequest,
  logoutRequest,
};
