/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import ReactStars from "react-rating-stars-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserReviews } from "../../../../services/UserService";
import Paginator from "../../../components/Paginator/Paginator";
import {
  getAddress,
  utcDate,
  timeConversionUTC,
} from "../../../shared/helpers";
import "./style.css";

const UserReviewsListComponent = (props) => {
  const { inspection, role } = props;
  const history = useHistory();
  const inspectionLink = (id) => {
    if (role === "customer") {
      history.push(`/inspections/${id}`);
    } else {
      history.push(`/inspections/${id}/details?tab=generalInfo`);
    }
  };
  return Object.keys(inspection).length !== 0 &&
    inspection?.data?.length !== 0 ? (
    inspection.data?.map((item, i) => (
      <tr
        key={`inspection-${i}`}
        style={{ cursor: "pointer" }}
        onClick={() => inspectionLink(item.inspection_id)}
      >
        <td className="">
          <span>{utcDate(item?.created_at)}</span>
          <br />
          <span>{timeConversionUTC(item?.created_at)}</span>
        </td>

        <td className="">
          <span>
            {getAddress(
              item?.inspections?.property?.address &&
                item.inspections?.property?.address
            )}
          </span>
        </td>
        <td>
          <div>
            <ReactStars
              size={20}
              count={5}
              color="black"
              value={item?.rating}
              a11y={true}
              isHalf={true}
              edit={false}
              emptyIcon={
                <img
                  src={
                    require(`../../../../assets/icons/big-star-gray@2x.png`)
                      .default
                  }
                  alt="empty"
                  width="30px"
                  style={{ paddingRight: "5px", paddingBottom: "5px" }}
                />
              }
              halfIcon={
                <img
                  src={
                    require(`../../../../assets/icons/half-star@2x.png`).default
                  }
                  alt="empty_3"
                  width="30px"
                  style={{ paddingRight: "5px", paddingBottom: "5px" }}
                />
              }
              filledIcon={
                <img
                  src={
                    require(`../../../../assets/icons/big-star-yellow@2x.png`)
                      .default
                  }
                  alt="empty_2"
                  style={{ paddingRight: "5px", paddingBottom: "5px" }}
                  width="30px"
                />
              }
            />
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No record found</td>
    </tr>
  );
};

const UserReviews = ({ userId, role }) => {
  const [userInfoList, setuserInfoList] = useState({});
  const userInfo = useSelector((state) => state.user.data);

  const [loader, setloader] = useState(true);
  const [page, setpage] = useState(1);

  useEffect(() => {
    setloader(true);
    getUserReviews(userInfo?.role, page, userId)
      .then((x) => {
        console.log(x);
        setuserInfoList(x.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setloader(false));
  }, [page]);

  return (
    <>
      <div className="main-content-area">
        <div className="">
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "calc(100vh - 202px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    height: "calc(100vh - 270px)",
                    paddingRight: "10px",
                  }
            }
          >
            <div>
              {loader ? (
                <>
                  <div>
                    <Spinner animation="border" variant="success" />
                  </div>
                </>
              ) : (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th> Date/Time Submitted</th>
                      <th>Street/City/Zip</th>

                      <th width="220"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserReviewsListComponent
                      role={userInfo?.role}
                      inspection={userInfoList}
                    />
                  </tbody>
                </table>
              )}
            </div>
          </ScrollBar>
          {!loader &&
          Object.keys(userInfoList).length !== 0 &&
          userInfoList?.data?.length !== 0 ? (
            <nav className="mt-4 mb-3">
              <span className="pagination justify-content-center mb-0">
                <Paginator
                  activePage={userInfoList?.current_page}
                  itemsCountPerPage={userInfoList?.per_page}
                  totalItemsCount={userInfoList?.total}
                  onChange={(page) => {
                    setpage(page);
                  }}
                />
              </span>
              <span className="total-align" style={{ marginTop: "-41px" }}>
                Showing {userInfoList?.from} to {userInfoList?.to} of{" "}
                {userInfoList?.total} entries{" "}
              </span>
            </nav>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UserReviews;
