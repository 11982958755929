import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const getInspectionList = (page, type, keyword, role) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections?${
      type?.self_inspect?.val ? "self_inspect=self_inspect&" : ""
    }${type?.wit_inspect?.val ? "inspector_assigned=inspector_assigned&" : ""}${
      type?.un_assigned?.val ? "un_assigned=un_assigned&" : ""
    }${type?.pending?.val ? "pending=pending&" : ""}${
      type?.in_progress?.val ? "in_progress=in_progress&" : ""
    }${type?.completed?.val ? "completed=completed&" : ""}${
      type?.paid?.val ? "paid=true&" : ""
    }${type?.un_paid?.val ? "un_paid=true&" : ""}${
      type?.active?.val ? "active=active&" : ""
    }${type?.deactivate?.val ? "deactivate=deactivate&" : ""}${
      keyword && `search=` + keyword + `&`
    }page=${page}`
  );
};

// self_inspect
// wit_inspect
// assigned
// un_assigned
// pending
// in_progress
// completed
const getInspectionbyId = (id, role) => {
  console.log(id, "insp_idinsp_idinsp_id");
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${id}?device_name=react`
  );
};

const DeleteRoom = (insp_id, id, role) => {
  return axios.delete(
    `/v1/${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${insp_id}/room/${id}`
  );
};

const updateInspectionbyId = (id, data, role) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections/${id}`,
    data
  );
};

const updateInspectionForCalendly = (id, data) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(`/v1/inspections/${id}`, data);
};
const deleteInspectionbyId = (id, data) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(`/api/v1/jobs/${id}`, data);
};
const getInspectors = (role, page = 1, keyword) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspectors?${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const getInspectionByUserID = (page, role, id) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections?inspector_id=${id}&page=${page}`
  );
};

const getInspectionByPropertyID = (page, role, id) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections?property_id=${id}&page=${page}`
  );
};

const createInspection = (role, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections
    `,
    data
  );
};
const createReview = (role, data, id) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections/${id}/reviews
    `,
    data
  );
};
const getReviewByInspectionID = (role, id) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections/${id}/reviews`
  );
};

const addInspectionRoom = (data) => {
  return axios.post(`/api/v1/room`, data);
};

export {
  getInspectionList,
  getInspectionbyId,
  updateInspectionbyId,
  deleteInspectionbyId,
  getInspectors,
  DeleteRoom,
  createInspection,
  addInspectionRoom,
  getInspectionByUserID,
  createReview,
  getReviewByInspectionID,
  updateInspectionForCalendly,
  getInspectionByPropertyID,
};
