import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import * as Actions from "../actions";
import appReducer from "./app.reducer";
import headerReducer from "./header.reducer";
import inspectionDisable from "./inspectionDisable.reducer";
import sidenavReducer from "./sidenav.reducer";
import userReducer from "./user.reducer";
import {
  instructionalCustomTaskSelectionReducers,
  instructionalStoreTaskSelectionReducers,
} from "./select_instructional_task/selectInstructionalTaskReducer";
import {
  featureSelectionReducers,
  featureDetailReducers,
  submitFeatureReducers,
} from "./select_feature/selectFeatureReducer";

const reducer = combineReducers({
  user: userReducer,
  header: headerReducer,
  sidenav: sidenavReducer,
  app: appReducer,
  inspectionDisable: inspectionDisable,
  instructionalCustomTaskSelection: instructionalCustomTaskSelectionReducers,
  instructionalStoreTaskSelection: instructionalStoreTaskSelectionReducers,
  featureSelection: featureSelectionReducers,
  featureDetail: featureDetailReducers,
  submitFeature: submitFeatureReducers,
});

const initialState = {};
const exportReducer = (state, action) =>
  reducer(action.type === Actions.LOGOUT_USER ? undefined : state, action);

const middleware = [thunk];

const Store = createStore(
  exportReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
