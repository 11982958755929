import "./style.css";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { SET_SEARCH_FILTER_API } from "../../../store/actions";
import { roleNames, useDidUpdateEffect } from "../../shared/helpers";
import { getInspectionList } from "../../../services/InspectionService";
import { useDispatch, useSelector } from "react-redux";
import { InspectionList as InspectionListComponent } from "./InspectionList";
import Paginator from "../../components/Paginator/Paginator";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Inspection = () => {
  const [inspectionList, setinspectionList] = useState({});
  const userInfo = useSelector((state) => state.user.data);
  const history = useHistory();
  const headerState = useSelector((state) => state?.header.searchFilter);
  const [loader, setloader] = useState(true);
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const pageNumber = query.get("page");

  const [page, setpage] = useState(pageNumber);
  console.log(inspectionList, "inspectionList");
  const searchField = useSelector((state) => state.header.searchField);

  const [skip, setSkip] = useState(false);
  const dispatch = useDispatch();
  const searchWord = useMemo(
    () => query.getAll("filter").length,
    [headerState]
  );

  useDidUpdateEffect(() => {
    setSkip(true);
    setloader(true);
    if (headerState || searchField || pageNumber) {
      dispatch({ type: SET_SEARCH_FILTER_API, payload: true });
      getInspectionList(1, headerState, searchField, userInfo?.role)
        .then((x) => {
          console.log("0.7rem0.7rem0.7rem0.7rem0.7rem0.7rem");

          setinspectionList(x.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setloader(false);
          setloader(false);
          dispatch({ type: SET_SEARCH_FILTER_API, payload: false });
        });
    }
  }, [searchField]);

  useEffect(() => {
    let didMountRef = false;

    if (pageNumber) {
      setloader(true);

      getInspectionList(pageNumber, headerState, searchField, userInfo?.role)
        .then((x) => {
          if (!didMountRef) {
            setloader(false);
            setinspectionList(x.data);
          }
        })
        .catch((err) => {
          if (!didMountRef) {
            setloader(false);
            console.log(err);
          }
        });
    }

    return () => {
      didMountRef = true;
    };
  }, [pageNumber, searchWord]);

  useEffect(() => {
    console.log(headerState, "hello world");
  }, [searchWord]);

  console.log(headerState, "headerState");
  return (
    <>
      <div className="main">
        <div className="" style={{ padding: "25px 20px 25px 20px" }}>
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "calc(100vh - 200px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : {
                    height: "calc(100vh - 200px)",
                    paddingRight: "10px",
                  }
            }
          >
            <div>
              {loader ? (
                <>
                  <div>
                    <Spinner animation="border" variant="success" />
                  </div>
                </>
              ) : (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th
                        style={{
                          minWidth: "59px",
                        }}
                      >
                        Date/Time Created
                      </th>
                      <th>Address</th>
                      <th
                        style={{
                          minWidth: "80px",
                        }}
                      >
                        Client Name
                      </th>
                     {/* // TODO : "will remove when clients says open inspector mode"
                       <th
                        style={{
                          minWidth: "100px",
                        }}
                      >
                        Inspector Name
                      </th> */}
                      <th
                        style={{
                          minWidth: "97px",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          minWidth: "76px",
                        }}
                      >
                        Payment Status
                      </th>
                      {/*  {/* // TODO : "will remove when clients says open inspector mode" 
                      <th
                        style={{
                          minWidth: "70px",
                        }}
                      >
                        Type
                      </th> */}
                      {userInfo.role !== roleNames.bu_homeowner && (
                        <th
                          style={{
                            zIndex: "2",
                            textAlign: "left",
                            padding: "0px 0px 0px 35px",
                          }}
                        >
                          Active
                        </th>
                      )}
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <InspectionListComponent
                      role={userInfo?.role}
                      inspection={inspectionList}
                      setloader={setloader}
                      setinspectionList={setinspectionList}
                    />
                  </tbody>
                </table>
              )}
            </div>
          </ScrollBar>
          {!loader &&
            Object.keys(inspectionList).length !== 0 &&
            inspectionList?.data?.length !== 0 && (
              <nav className="mt-4 mb-3">
                <span className="pagination justify-content-center mb-0 paginator">
                  <Paginator
                    activePage={inspectionList?.current_page}
                    itemsCountPerPage={inspectionList?.per_page}
                    totalItemsCount={inspectionList?.total}
                    onChange={(pages) => {
                      setSkip(false);
                      if (headerState.length !== 0) {
                        history.push(
                          `/inspections?page=${pages}${
                            headerState?.self_inspect.val
                              ? "&filter=self_inspect"
                              : ""
                          }${
                            headerState?.wit_inspect.val
                              ? "&filter=inspector_assigned"
                              : ""
                          }${
                            headerState?.un_assigned.val
                              ? "&filter=un_assigned"
                              : ""
                          }${
                            headerState?.pending.val ? "&filter=pending" : ""
                          }${
                            headerState?.in_progress.val
                              ? "&filter=in_progress"
                              : ""
                          }${
                            headerState?.completed.val
                              ? "&filter=completed"
                              : ""
                          }${headerState?.paid.val ? "&filter=paid" : ""}${
                            headerState?.un_paid.val ? "&filter=un_paid" : ""
                          }${headerState?.active.val ? "&filter=active" : ""}${
                            headerState?.deactivate.val
                              ? "&filter=deactivate"
                              : ""
                          }`
                        );
                      } else {
                        history.push(`/inspections?page=${pages}`);
                      }
                    }}
                  />
                </span>
                <span className="total-align">
                  Showing {inspectionList?.from} to {inspectionList?.to} of{" "}
                  {inspectionList?.total} entries{" "}
                </span>
              </nav>
            )}
        </div>
      </div>
    </>
  );
};

export default Inspection;
