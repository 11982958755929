import { useEffect, useRef, useState } from "react";

import { Spinner } from "react-bootstrap";
import passActiveIcon from "../../../assets/icons/eye@2x.png";
import passCloseIcon from "../../../assets/icons/eye lock@2x.png";
import submitIcon from "../../../assets/icons/submit-icon.png";

export default function CustomField({
  inputProps,
  label,
  value,
  fieldType = "input",
  id,
  name,
  type,
  onChange,
  errors,
  isSubmit,
  CustomErrors,
  parentClassName,
  errorText,
  minWidth,
  icon,
  password,
  disabled,
  customCSS,
  defaultValue,
  isEditing = false,
  setOnSubmitIcon,
  notesApiLoader,
  autocomplete,
}) {
  const [passActive, setpassActive] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  });

  return (
    <div>
      <div
        className={`float-label 
      } ${parentClassName || ""} ${errors && "error-border"}`}
        style={{
          minWidth: minWidth && minWidth,
        }}
        autoFocus={false}
      >
        {icon && (
          <span className="placeholder-icon">
            <img alt="input-icon" src={icon} />
          </span>
        )}
        {fieldType === "input" ? (
          <input
            value={value ? value : ""}
            autoComplete={autocomplete ? autocomplete : "off"}
            className={`${
              icon ? "padding-placeholder" : "padding-without-placeholder"
            } ${customCSS}`}
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={label}
            id={id}
            name={name}
            ref={inputRef}
            type={password ? (!passActive ? "password" : "text") : type}
            onChange={onChange}
            // autoFocus={true}
            {...inputProps}
          />
        ) : (
          <textarea
            value={value ? value : ""}
            className={`${customCSS} ${errors && "error-border-textarea"}`}
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={label}
            id={id}
            name={name}
            ref={inputRef}
            type={password ? (!passActive ? "password" : "text") : type}
            onChange={onChange}
            autoFocus={true}
            {...inputProps}
          />
        )}

        {setOnSubmitIcon && (
          <span className="placeholder-icon-submit">
            {notesApiLoader ? (
              <Spinner
                animation="border"
                variant="success"
                className="app-spinner notes-spinner"
              />
            ) : (
              <img
                id={`${id}-onSubmit`}
                alt="on-submit-logo"
                src={submitIcon}
                onClick={() => {
                  setOnSubmitIcon(true);
                  // setpassActive(false);
                }}
              />
            )}
          </span>
        )}

        {password &&
          (passActive ? (
            <span className="placeholder-icon-password">
              <img
                id={`${id}-passClose`}
                alt="wit-Logo"
                src={passActiveIcon}
                onClick={() => {
                  setpassActive(false);
                }}
              />
            </span>
          ) : (
            <span className="placeholder-icon-password">
              <img
                alt="pass"
                id={`${id}-passActice`}
                src={passCloseIcon}
                onClick={() => {
                  setpassActive(true);
                }}
              />
            </span>
          ))}
      </div>
    </div>
  );
}
