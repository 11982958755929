import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const createUserCustomer = (role, data) => {
  return axios.post(
    `/v1${role === roleNames.buAdmin ? "/am" : ""}/customers`,
    data
  );
};

const createUserInspector = (role, data) => {
  return axios.post(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspector`,
    data
  );
};

const createUserManager = (role, data) => {
  return axios.post(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}/manager`,
    data
  );
};

const getUsers = (role, page = 1, keyword) => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.buAdmin ? "/am" : ""
    }/users?${keyword ? `search=${keyword}&` : ""}page=${page}`
  );
};

const getUserReviews = (role, page = 1, id) => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.buAdmin ? "/am" : ""
    }/users/${id}/reviews?page=${page}`
  );
};

const getUserByID = (role, id) => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.buAdmin ? "/am" : ""
    }/users/${id}`
  );
};

const updateUserByID = (role, id, data) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/users/${id}`,
    data
  );
};
export {
  createUserCustomer,
  createUserInspector,
  createUserManager,
  getUsers,
  getUserReviews,
  getUserByID,
  updateUserByID,
};
