/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { LOG_IN, SET_NOTIFICATIONS } from "./store/actions";
import { getLocalUser, showToast } from "./main/shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import AppRouter from "./navigation/router";
import { Spinner } from "react-bootstrap";
import { profileRequest } from "./services/auth-service";
import { requestFirebaseNotificationPermission } from "./services/firebaseSetup/firebase";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const useSelectorLoader = useSelector(
    (state) => state.header.notificationsList
  );

  const [msgConnected, setmsgConnected] = useState(false);

  const [status, setStatus] = useState(true);
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof window["safari"] !== "undefined" &&
          window["safari"].pushNotification)
    );

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);
  useEffect(() => {
    if (!status) {
      showToast("You're offline right now. Please Check your connection.");
      setmsgConnected(true);
    }
  }, [status]);

  useEffect(() => {
    if (status && msgConnected) {
      showToast("You're online right now.", true);
      setmsgConnected(false);
    }
  }, [msgConnected, status]);

  useEffect(() => {
    let localUser = null;
    try {
      localUser = getLocalUser();
    } catch (e) {
      console.log("get local user failed", e);
    }
    if (localUser !== null) {
      dispatch({
        type: LOG_IN,
        payload: localUser,
      });
      setIsLoading(false);
    } else if (localStorage.getItem("access_token")) {
      profileRequest()
        .then((res) => {
          dispatch({
            type: LOG_IN,
            payload: res.data,
          });
        })
        .catch((err) => console.log(err.response))
        .finally(() => {
          setIsLoading(false);
        });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    console.log(
      localStorage.getItem("access_token") &&
        !localStorage.getItem("fcm_token") &&
        !isSafari,
      "perrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr"
    );
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state !== "granted")
              localStorage.removeItem("fcm_token");
            console.log(
              "User decided to change his seettings. New permission: " +
                notificationPerm.state
            );
          };
        });
    }
    if (
      localStorage.getItem("access_token") &&
      !localStorage.getItem("fcm_token") &&
      !isSafari
    ) {
      requestFirebaseNotificationPermission()
        .then((firebaseTok) => {
          dispatch({
            type: SET_NOTIFICATIONS,
            payload: true,
          });
        })
        .catch((errToken) => {
          showToast(
            "Please grant notification permission to receive notifications"
          );
        });
    }
  }, []);

  if (!isSafari) {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", function (event) {
        if (!useSelectorLoader) {
          // console.log(useSelectorLoader, "event listener", event.data);

          dispatch({
            type: SET_NOTIFICATIONS,
            payload: true,
          });
        }
      });
    }
  }
  return isLoading ? (
    <Spinner animation="border" variant="success" className="app-spinner" />
  ) : (
    <AppRouter />
  );
}

export default App;
