import * as Actions from "../actions";

const initialState = {
  disable: false,
};

export default function inspectionDisable(state = initialState, action) {
  switch (action.type) {
    case Actions.INSPECTION_DISABLE:
      return action.payload;
    default:
      return state;
  }
}
