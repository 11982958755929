import "./style.css";

import { OverlayTrigger, Popover } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  SET_SEARCH_FILTER,
  SET_SEARCH_FILTER_API,
} from "../../../store/actions";
import { filtersObj } from "../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const PopoverFilter = () => {
  const SEARCH_FILTER_DATA = useSelector((state) => state.header.searchFilter);
  const [bool, setBool] = useState(false);
  const [liSelected, setliSelected] = useState({ ...SEARCH_FILTER_DATA });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const pageNumber = query.get("page") ?? 1;
  console.log(pageNumber, "pageNumberpageNumberpageNumber");

  const SEARCH_FILTER_API = useSelector(
    (state) => state.header.searchFilterAPI
  );
  const userInfo = useSelector((state) => state.user.data);

  useEffect(() => {
    console.log(
      ["Aziendale", "Nuovo"]?.join(","),
      JSON.stringify(["Aziendale", "Nuovo"]),
      query.getAll("filter"),
      "liSelectedliSelectedliSelected",
      liSelected,
      // filter_true,
      location.search,
      bool
    );

    if (bool) {
      setBool(false);
      let filter_true = Object.keys(liSelected).filter(
        (i) => liSelected[i].val === true
      );
      let id;
      console.log(filter_true, "liSelected");
      if (filter_true.length != 0) {
        dispatch({ type: SET_SEARCH_FILTER, payload: liSelected });
        dispatch({ type: SET_SEARCH_FILTER_API, payload: true });
        history.push(
          `/inspections?page=1${
            liSelected?.self_inspect.val ? "&filter=self_inspect" : ""
          }${liSelected?.wit_inspect.val ? "&filter=wit_inspect" : ""}${
            liSelected?.un_assigned.val ? "&filter=un_assigned" : ""
          }${liSelected?.pending.val ? "&filter=pending" : ""}${
            liSelected?.in_progress.val ? "&filter=in_progress" : ""
          }${liSelected?.completed.val ? "&filter=completed" : ""}${
            liSelected?.paid.val ? "&filter=paid" : ""
          }${liSelected?.un_paid.val ? "&filter=un_paid" : ""}${
            liSelected?.active.val ? "&filter=active" : ""
          }${liSelected?.deactivate.val ? "&filter=deactivate" : ""}`
        );
      } else {
        // console.log("loll");
        dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });
        history.push(`/inspections?page=1`);
      }
    } else {
      let filter_true = Object.keys(liSelected).filter(
        (i) => liSelected[i].val === true
      );

      if (filter_true.length !== 0) {
        history.push(
          `/inspections?page=${pageNumber}${
            liSelected?.self_inspect.val ? "&filter=self_inspect" : ""
          }${liSelected?.wit_inspect.val ? "&filter=wit_inspect" : ""}${
            liSelected?.un_assigned.val ? "&filter=un_assigned" : ""
          }${liSelected?.pending.val ? "&filter=pending" : ""}${
            liSelected?.in_progress.val ? "&filter=in_progress" : ""
          }${liSelected?.completed.val ? "&filter=completed" : ""}${
            liSelected?.paid.val ? "&filter=paid" : ""
          }${liSelected?.un_paid.val ? "&filter=un_paid" : ""}${
            liSelected?.active.val ? "&filter=active" : ""
          }${liSelected?.deactivate.val ? "&filter=deactivate" : ""}`
        );
      } else if (query.getAll("filter").length !== 0) {
        console.log("null");
      } else {
        dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });
        history.push(`/inspections?page=${pageNumber}`);
        // console.log("hihihihih");
      }
    }
    console.log("hihihihihhihihihihhihihihihhihihihih");
  }, [liSelected]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content className="popover-filters">
        {/* // TODO : "will remove "REMOVE TAG" when clients says open inspector mode" */}
        <ul>
          {Object.entries(liSelected).map(
            ([key, value]) =>
              value?.ex_role !== userInfo.role &&
              !value?.remove && (
                <li
                  tabIndex="1"
                  className={`liItems ${value?.val && "selected"}`}
                  id={key}
                  key={key}
                  onClick={() => {
                    setBool(!bool);
                    setliSelected({
                      ...liSelected,
                      [key]: { ...value, val: !value.val },
                    });
                  }}
                >
                  <div>{value?.name}</div>
                </li>
              )
          )}
        </ul>
      </Popover.Content>
    </Popover>
  );
  return (
    <div className="mr-4 " style={{ cursor: "pointer", marginLeft: "-10px" }}>
      {" "}
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
      >
        <img
          src={require(`../../../assets/icons/filter@2x.png`).default}
          width="90%"
        />
      </OverlayTrigger>
    </div>
  );
};

export default PopoverFilter;
