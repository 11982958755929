import React from "react";
import WITLogo from "../../../assets/logo/web_logo.png";

export default function ServerDownPage() {
  return (
    <div className="login-page">
      <div className="text-center">
        <img
          alt="WIT-logo"
          className="WIT-logo"
          style={{ width: "35%" }}
          src={WITLogo}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Unfortunately the site is down for a bit of maintainance right now.
          <br />
          Please try again later.
        </p>
      </div>
    </div>
  );
}
