// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import "../Settings/style.css";
import Compressor from "compressorjs";
import { useEffect, useState } from "react";

import CustomField from "../../components/CustomField";
import ImageIcon from "../../../assets/icons/camera@2x.png";
import PhoneInput from "react-phone-input-2";
import { fileSizeLimits } from "../../shared/validations";
import { regexes } from "../../shared/regexes";
import { roleToText } from "../../shared/helpers";
import { uploadFile } from "../../../services/UploadFile";

const AddInspectorNAccountManager = ({
  userDetails,
  setuserDetails,
  errors,
  emailDisabled,
  isSubmit,
  role,
  showRole,
  disabled,
  setDisableSave,
  addUser,
  setdisbutton,
}) => {
  // image add

  const [preview, setPreview] = useState("");

  const [file_e, setFile_e] = useState("");

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("File size cannot be larger than 2MB");
      } else if (file.name.includes(".jfif")) {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG,JPEG or PNG");
      } else {
        if (setdisbutton) {
          setdisbutton(true);
        } else {
          setDisableSave(true);
        }
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        compressImage(file);
      }
    }
  };
  // role== 'account_manager'
  const compressImage = (file) => {
    new Compressor(file, {
      quality: 0.5,
      convertSize: 500000,
      convertTypes: ["image/png", "image/jpeg", "image/jpg"],
      success: (compressedResult) => {
        const namefile = compressedResult.name.split(".").pop();
        let filename =
          role === "account_manager"
            ? `images/manager/${new Date().getTime()}.${namefile}`
            : role === "inspector"
            ? `images/inspector/${new Date().getTime()}.${namefile}`
            : undefined;

        uploadImagetoS3(compressedResult, filename);
      },
    });
  };

  const uploadImagetoS3 = (file, filename) => {
    uploadFile(file, filename)
      .then((url) => {
        setuserDetails({ ...userDetails, avatar: url });
        if (setdisbutton) {
          setdisbutton(false);
        } else {
          setDisableSave(false);
        }
      })
      .catch((er) => {
        console.log(er, "");
        if (setdisbutton) {
          setdisbutton(false);
        } else {
          setDisableSave(false);
        }
      });
  };

  useEffect(() => {
    console.log(userDetails, "userDetails_userDetails");
    // console.log(preview,"preview_preview_preview Own")
    console.log(preview, "previewpreviewpreviewpreview preview");

    // console.log("my", userDetails.avatar, "hello there");
    if (!userDetails.avatar) {
      setPreview("");
    } else if (userDetails?.avatar?.type) {
      setPreview(URL.createObjectURL(userDetails.avatar));
    } else {
      setPreview(userDetails.avatar);
    }
  }, [userDetails.avatar]);

  return (
    <div>
      <h4>
        {role === "inspector" ? "Inspector Details" : "Account Manager Details"}
      </h4>
      <div className="row">
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <CustomField
                  name="first_name"
                  label="First Name"
                  type="text"
                  value={userDetails?.first_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z ]/gi, "");
                    setuserDetails({
                      ...userDetails,
                      first_name: value,
                    });
                  }}
                  disabled={disabled}
                  id="first_name"
                  errors={
                    isSubmit &&
                    !disabled &&
                    (!userDetails.first_name ||
                      !userDetails.first_name.trim() ||
                      (errors?.first_name && errors?.first_name[0]))
                  }
                  inputProps={{
                    maxLength: "40",
                  }}
                />
                <small className="text-danger ml-1">
                  {isSubmit && !disabled && userDetails?.first_name.length === 0
                    ? "Please enter a first name"
                    : isSubmit &&
                      !disabled &&
                      userDetails?.first_name.length !== 0 &&
                      !userDetails?.first_name.trim()
                    ? "Required field cannot be left blank"
                    : errors?.first_name
                    ? errors?.first_name[0]
                    : ""}
                </small>
              </div>
            </div>
            <div className="col-md-6">
              <CustomField
                name="last_name"
                label="Last Name"
                type="text"
                value={userDetails?.last_name}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^A-Za-z ]/gi, "");
                  setuserDetails({
                    ...userDetails,
                    last_name: value,
                  });
                }}
                disabled={disabled}
                id="last_name"
                errors={
                  isSubmit &&
                  !disabled &&
                  (!userDetails.last_name ||
                    !userDetails.last_name.trim() ||
                    (errors?.last_name && errors?.last_name[0]))
                }
                inputProps={{
                  maxLength: "40",
                }}
              />
              <small className="text-danger ml-1">
                {isSubmit && !disabled && userDetails?.last_name.length === 0
                  ? "Please enter last name"
                  : isSubmit &&
                    !disabled &&
                    userDetails?.last_name.length !== 0 &&
                    !userDetails?.last_name.trim()
                  ? "Required field cannot be left blank"
                  : errors?.last_name
                  ? errors?.last_name[0]
                  : ""}
              </small>
            </div>

            <div className="col-md-6">
              <CustomField
                name="email"
                label="Email "
                type="text"
                disabled={emailDisabled ? emailDisabled : disabled}
                value={userDetails?.email}
                onChange={(e) => {
                  setuserDetails({
                    ...userDetails,
                    email: e.target.value,
                  });
                }}
                id="email"
                errors={
                  isSubmit &&
                  !disabled &&
                  (!userDetails.email ||
                    !userDetails.email.trim() ||
                    (errors?.email && errors?.email[0]))
                }
                inputProps={{
                  maxLength: "60",
                }}
              />

              <small className="text-danger ml-1">
                {isSubmit && !disabled && userDetails?.email.length === 0
                  ? "Please enter an email address"
                  : isSubmit &&
                    !disabled &&
                    userDetails?.email.length !== 0 &&
                    !userDetails?.email.trim()
                  ? "Required field cannot be left blank"
                  : isSubmit &&
                    !disabled &&
                    userDetails?.email.length !== 0 &&
                    !regexes.email.test(userDetails?.email)
                  ? "Please enter a valid email address"
                  : errors?.email
                  ? errors?.email[0]
                  : ""}
              </small>
            </div>
            {console.log(
              "!disabled && !disabled && !disabled && !disabled && ",
              disabled
            )}
            <div className="col-md-6">
              <PhoneInput
                country={"us"}
                value={
                  userDetails?.phone_number ? userDetails.phone_number : ""
                }
                disabled={disabled}
                buttonClass={disabled ? "flag-dis" : "flag-enable"}
                onChange={(e) => {
                  let value = e;
                  setuserDetails({
                    ...userDetails,
                    phone_number: value,
                  });
                }}
              />

              <small className="text-danger ml-1">
                {isSubmit && !disabled && !userDetails?.phone_number
                  ? "Please enter a phone number"
                  : isSubmit &&
                    !disabled &&
                    userDetails?.phone_number?.length !== 0 &&
                    !userDetails?.phone_number?.trim()
                  ? "Required field cannot be left blank"
                  : isSubmit &&
                    !disabled &&
                    userDetails?.phone_number.length < 11
                  ? "The phone number at least have 11 digits"
                  : errors?.phone_number
                  ? errors?.phone_number[0]
                  : ""}
              </small>
            </div>
          </div>
        </div>

        <div
          className="col-md-3 d-flex justify-content-end"
          style={{ marginTop: addUser && "-26px" }}
        >
          <div className="upload-avatar ">
            <div className="image-area" disabled={disabled}>
              {!preview ? (
                <span
                  className="upload-icon"
                  style={
                    preview
                      ? { backgroundColor: "transparent" }
                      : { backgroundColor: "#f3f3f3" }
                  }
                >
                  <img src={ImageIcon} alt="" />
                </span>
              ) : null}

              <img
                width="155"
                className="avatar-lg"
                src={`${
                  preview?.includes("blob:htt")
                    ? preview
                    : `${preview}?${new Date().getTime()}`
                }`}
                alt=""
              />

              <input
                type="file"
                className="upload-image"
                accept="image/jpeg,image/png"
                onChange={(e) => {
                  fileChangedHandler(e);
                }}
                disabled={disabled}
              />
            </div>
            <p>Upload Photo (Optional)</p>
            <small className="text-danger ml-1">{!disabled && file_e}</small>
          </div>
        </div>
      </div>
      {console.log(showRole, "showRoleshowRoleshowRole")}
      {showRole && (
        <div className="row mb-4" style={{ marginTop: "-40px" }}>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <h4>Role</h4>
                {console.log(
                  roleToText(role),
                  "roleToText(role)roleToText(role)"
                )}
                <CustomField
                  name="role"
                  label="role "
                  type="text"
                  value={roleToText(role)}
                  id="role"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-md-9">
          <h4>Notes</h4>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="float-label">
                  <textarea
                    id="additional_remarks"
                    className={`padding-without-placeholder `}
                    disabled={disabled}
                    type="text"
                    maxLength="200"
                    placeholder="Optional"
                    value={userDetails?.notes ? userDetails.notes : ""}
                    onChange={(e) =>
                      setuserDetails({
                        ...userDetails,
                        notes: e.target.value,
                      })
                    }
                    style={{ minHeight: "120px" }}
                  />

                  <small className="text-danger ml-1">
                    {isSubmit && !disabled && errors?.notes
                      ? errors?.notes[0]
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInspectorNAccountManager;
