export const SELECT_INSTRUCTIONAL_TASK_REQUEST = "SELECT_INSTRUCTIONAL_TASK_REQUEST";
export const SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS = "SELECT_INSTRUCTIONAL_TASK_ADD_SUCCESS";
export const SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS = "SELECT_INSTRUCTIONAL_TASK_REMOVE_SUCCESS";
export const SELECT_INSTRUCTIONAL_TASK_FAIL = "SELECT_INSTRUCTIONAL_TASK_FAIL";
export const SELECT_INSTRUCTIONAL_TASK_RESET = "SELECT_INSTRUCTIONAL_TASK_RESET";

export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST = "SELECT_INSTRUCTIONAL_CUSTOM_TASK_REQUEST";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS = "SELECT_INSTRUCTIONAL_CUSTOM_TASK_ADD_SUCCESS";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS = "SELECT_INSTRUCTIONAL_CUSTOM_TASK_REMOVE_SUCCESS";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_FAIL = "SELECT_INSTRUCTIONAL_CUSTOM_TASK_FAIL";
export const SELECT_INSTRUCTIONAL_CUSTOM_TASK_RESET = "SELECT_INSTRUCTIONAL_CUSTOM_TASK_RESET";

export const SELECT_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK = "SELECTION_INSTRUCTIONAL_CUSTOM_UPLOAD_TASK";

