import axios from "../axios-defaults";

const getNotificationList = (key, page, timezone) => {
  return axios.get(
    `v1/notifications?date=${key}&cursor=${page}&current_date=${new Date()}&timezone=${timezone}`
  );
};

const postFCMToken = (body) => {
  return axios.post("v1/notifications", body);
};

const readAPI = (id) => {
  return axios.post(`v1/notifications/${id}/read`, {
    _method: "patch",
  });
};

export { getNotificationList, postFCMToken, readAPI };
