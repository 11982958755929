import "./searchBox.css";

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import { getAddress } from "../../shared/helpers";

const { Option } = components;
 function SearchDropdownRadioIcon(props) {
  const {
    apiCall,
    saveProp,
    showProp,
    placeHolder,
    onSelect,
    complicated,
    disabled,
    RadioIcon,
    user_role,
    optionsData,
    defaultOptionValue,
    customer_id,
    customHeight,
  } = props;
  const [value, onChange] = useState(props?.value ? props.value : null);
  const [defaultValue, setDefaultValue] = useState();
  const [inputValueSearch, setinputValueSearch] = useState("");
  useEffect(() => {
    if (complicated) {
      // console.log("force update");
    }
  }, [props.value]);

  useEffect(() => {
    if (defaultOptionValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    var val = null;
    let filteredOptions;
    let hasMore;

    if (apiCall) {
      var totalpage = { current_page: null, last_page: null };
      // console.log(page, "llllll", search);

      if (customer_id) {
        console.log("1111111111111111");
        await apiCall(
          user_role,
          page,
          search ? search : "",
          customer_id ? customer_id : ""
        )
          .then((x) => {
            // console.log(x.data);
            totalpage = {
              current_page: x.data.current_page,
              last_page: x.data.last_page,
            };

            // console.log(x.data.last_page);
            val = x.data.data.map((d) => {
              let temp = "";
              if (!complicated) {
                showProp.forEach((element) => {
                  temp = temp + d[element] + " ";
                });
              } else {
                temp = getAddress(d?.address);
                return {
                  value: d[saveProp],
                  label: temp,
                  id: d?.customer_id,
                  customer:
                    d?.customer?.first_name + " " + d?.customer?.last_name,
                };
              }
              return {
                value: d[saveProp],
                label: temp,
              };
            });
          })
          .catch((err) => console.log(err));
      } else {
        console.log("2222222222222");
        await apiCall(user_role, page, search)
          .then((x) => {
            // console.log(x.data);
            totalpage = {
              current_page: x.data.current_page,
              last_page: x.data.last_page,
            };

            // console.log(x.data.last_page);
            val = x.data.data.map((d) => {
              let temp = "";
              if (!complicated) {
                showProp.forEach((element) => {
                  temp = temp + d[element] + " ";
                });
              } else {
                temp = getAddress(d?.address);
                return {
                  value: d[saveProp],
                  label: temp,
                  id: d?.customer_id,
                  customer:
                    d?.customer?.first_name + " " + d?.customer?.last_name,
                };
              }
              return {
                value: d[saveProp],
                label: temp,
              };
            });
          })
          .catch((err) => console.log(err));
      }
      // console.log(val, "defaultOptionValue", defaultOptionValue);
      !defaultValue &&
        setDefaultValue(val.find((o) => o.value === defaultOptionValue));

      if (!search) {
        filteredOptions = val;
      } else {
        const searchLower = search.toLowerCase();
        filteredOptions = val.filter(({ label }) =>
          label.toLowerCase().includes(searchLower)
        );
      }

      hasMore = totalpage?.current_page >= totalpage?.last_page ? false : true;
    } else {
      !defaultValue &&
        setDefaultValue(
          optionsData.find((o) => o.value.includes(defaultOptionValue))
        );

      filteredOptions = optionsData;
    }

    return {
      options: filteredOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };
  const boldQuery = (str) => {
    const n = str?.toUpperCase();
    const q = inputValueSearch?.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    const l = q.length;
    return (
      str.substr(0, x) + "<b>" + str.substr(x, l) + "</b>" + str.substr(x + l)
    );
  };
  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex align-items-center">
        {" "}
        <span>
          {" "}
          <img
            src={
              props?.data?.value === value?.value
                ? require("../../../assets/icons/radio@2x.png").default
                : require("../../../assets/icons/radio1@2x.png").default
            }
            style={{ paddingRight: "15px" }}
            alt={props.data.label}
          />
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: boldQuery(props.data.label),
          }}
        ></span>
      </div>
    </Option>
  );

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={require("../../../assets/icons/search-1@2x.png").default}
          style={{ paddingRight: "13px", width: "33px" }}
        />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setinputValueSearch("");
  }, [onSelect]);

  return (
    <div disabled={disabled ? true : false}>
      <AsyncPaginate
        isSearchable={true}
        components={{
          Option: IconOption,
          DropdownIndicator,
        }}
        // inputValue={inputValueSearch}
        onInputChange={(inputValue, { action }) => {
          console.log(action, "---------------------------", inputValue);
          if (action === "input-change") {
            setinputValueSearch(inputValue);
          }
        }}
        // menuIsOpen={true}
        debounceTimeout={1500}
        value={value}
        isClearable={true}
        loadOptions={loadOptions}
        onChange={(val) => {
          onChange(val);
          console.log("sssssssssssssssssssssssssssssssss");
          onSelect(val);
          // console.log(val, "customer_idcustomer_idcustomer_idcustomer_id");
        }}
        isDisabled={disabled ? true : false}
        defaultOptions
        placeholder={placeHolder}
        additional={{
          page: 1,
        }}
        maxMenuHeight={customHeight ? customHeight : 180}
        isDisabled={disabled}
      />
    </div>
  );
}

export default  SearchDropdownRadioIcon