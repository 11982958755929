import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const renderTooltip = (props) => <Tooltip {...props}>{props.text}</Tooltip>;

export default function CustomTooltip(props) {
  const { text } = props;
  return (
    <OverlayTrigger
      placement={props?.placement || "right"}
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => renderTooltip({ ...props, text })}
    >
      {props.children}
    </OverlayTrigger>
  );
}
