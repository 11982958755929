import React from "react";

import { Button, Modal } from "react-bootstrap";

export default function Delete(props) {
  const { msg, confirmDelete, deleteId, deleteRoomID } = props;

  return (
    <Modal
      show={props.show}
      onHide={props.onHideDelete}
      size="md"
      aria-labelledby="contained-modal-title"
      centered
      className="custom-modal"
      backdropClassName="foo-modal-backdrop"
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title">
          <div className="dialogheader">
            <div className="d-flex"></div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/Cross1@2x.png").default}
              alt="icon"
              onClick={props.onHideDelete}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <div className="d-center">
            <img
              src={require("../../../assets/icons/popup-icon@2x.png").default}
              alt="icon"
            />
          </div>
          <div className="custom-modal-text">
            <span>{msg}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          // variant="light"
          onClick={() => {
            confirmDelete({ deleteId, deleteRoomID });
            props.onHideDelete();
          }}
          style={{
            minWidth: "154px",
            height: "46px",
          }}
          id="delete"
        >
          Yes
        </Button>
        <Button
          style={{
            minWidth: "154px",
            height: "46px",
            marginLeft: 22,
            background: "#1A1A1A",
            borderColor: "#1A1A1A",
            color: "white",
          }}
          onClick={props.onHideDelete}
          id="cancel_bu_sub"
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
