import {
  createReview,
  getReviewByInspectionID,
} from "../../../../services/InspectionService";
import { getErrorMsg, showToast } from "../../../shared/helpers";
import { useEffect, useState } from "react";

import ReactStars from "react-rating-stars-component";
import { Spinner } from "react-bootstrap";
import { getInspectionbyId } from "../../../../services/InspectionService";

const Reviews = ({ userInfo, insp_id }) => {
  const [review, setReview] = useState({
    rating: 0,
    comment: "",
  });
  const [generalInfo, setGeneralInfo] = useState({});
  const [inspectionReview, setinspectionReview] = useState();

  const [loader, setloader] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, seterrors] = useState({});
  const [disButton, setdisButton] = useState(false);
  const [BtnHide, setBtnHide] = useState(true);
  useEffect(() => {
    getInspectionbyId(insp_id, userInfo?.role)
      .then((x) => {
        console.log(x?.data);
        setGeneralInfo(x?.data);
        setinspectionReview(
          x?.data?.type === "wit_inspect"
            ? x.data?.inspectors[0]
            : x.data?.customer
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const submitReview = () => {
    setIsSubmit(true);
    if (
      review?.rating > 0 &&
      review?.comment &&
      review?.comment?.trim() &&
      review?.comment?.length <= 1000
    ) {
      let data = {
        rating: review?.rating,
        comment: review?.comment,
      };

      postReview(data);
    } else if (review.rating === 0) {
      showToast("Please fill the rating");
    } else if (!review?.comment) {
      postReview({ rating: review?.rating });
    }
  };

  useEffect(() => {
    if (Object.keys(generalInfo).length !== 0) {
      getReviewByInspectionID(userInfo.role, insp_id)
        .then((resp) => {
          if (resp.data.reviews.length > 0) {
            setReview({ ...resp.data.reviews[0] });
            setBtnHide(true);
          } else if (resp.data.observations_status !== "completed") {
            setBtnHide(true);
          } else {
            setBtnHide(false);
          }

          setloader(false);
        })
        .catch((err) => {
          console.log("object", err);
        });
    }
  }, [generalInfo]);

  const postReview = (reviewData) => {
    setdisButton(true);
    createReview(userInfo?.role, { ...reviewData }, insp_id)
      .then((resp) => {
        if (resp) {
          setloader(true);
          setReview({
            rating: resp?.data.rating,
            comment: resp?.data?.comment,
          });

          setBtnHide(true);
          seterrors({});
          showToast("Review has been submitted", true);
        }
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 422) {
          showToast(err?.response?.data.errors.rating[0]);
        } else {
          showToast(getErrorMsg(err));
        }
      })
      .finally(() => {
        setdisButton(false);
        setTimeout(() => {
          setloader(false);
        }, 200);
      });
  };

  return (
    <>
      {loader ? (
        <div className="spinner-div">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <>
          <div className="main-content-area">
            {" "}
            <h3
              className="  one-line-ellipsis"
              style={{
                fontFamily: "Basis-Grotesque-Pro-Medium",
                fontWeight: "normal",
              }}
            >
              Inspection Review
            </h3>
            <p
              className="my-4"
              style={{
                fontSize: "18px",
                fontFamily: "Basis-Grotesque-Pro-Medium",
              }}
            >
              Rating
            </p>
            <div>
              {review?.rating >= 0 &&
                review?.rating !== false &&
                review?.rating !== "" && (
                  <ReactStars
                    size={20}
                    count={5}
                    color="black"
                    a11y={true}
                    value={review?.rating}
                    isHalf={true}
                    onChange={(newRating) => {
                      setReview({ ...review, rating: newRating });
                    }}
                    edit={
                      generalInfo.deleted_at !== null || BtnHide ? false : true
                    }
                    emptyIcon={
                      <img
                        src={
                          require(`../../../../assets/icons/big-star-gray@2x.png`)
                            .default
                        }
                        alt="empty"
                        width="30px"
                        style={{ marginRight: "11px" }}
                      />
                    }
                    halfIcon={
                      <img
                        src={
                          require(`../../../../assets/icons/half-star@2x.png`)
                            .default
                        }
                        alt="empty_3"
                        width="30px"
                        style={{ marginRight: "11px" }}
                      />
                    }
                    filledIcon={
                      <img
                        src={
                          require(`../../../../assets/icons/big-star-yellow@2x.png`)
                            .default
                        }
                        alt="empty_2"
                        style={{ marginRight: "11px" }}
                        width="30px"
                      />
                    }
                  />
                )}
            </div>
            <div className="form-group mt-4 mb-3">
              <div className="float-label">
                <textarea
                  id="additional_remarks"
                  className={`padding-without-placeholder ${
                    isSubmit &&
                    ((review?.comment &&
                      review?.comment?.length !== 0 &&
                      !review?.comment?.trim()) ||
                      (errors?.comment && errors?.comment[0])) &&
                    "error-border-textarea"
                  }`}
                  type="text"
                  maxLength="1000"
                  placeholder="Trainee performed was about average (Optional)"
                  disabled={generalInfo.deleted_at !== null || BtnHide}
                  value={review?.comment}
                  onChange={(e) =>
                    setReview({ ...review, comment: e.target.value })
                  }
                  style={{ minHeight: "120px", width: "533px" }}
                />
              </div>

              <small className="text-danger ml-1">
                {isSubmit &&
                review?.comment &&
                review?.comment?.length !== 0 &&
                !review?.comment?.trim()
                  ? "Required field cannot be left blank"
                  : errors?.comment
                  ? errors?.comment[0]
                  : ""}
              </small>
            </div>
            {console.log(
              generalInfo,
              "generalInfo.deleted_at",
              generalInfo.deleted_at
            )}
            {!BtnHide && (
              <button
                className="btn btn-primary"
                disabled={generalInfo.deleted_at !== null || disButton}
                style={{ paddingInline: "55px" }}
                onClick={() => submitReview()}
                id="submit_review"
              >
                Submit
              </button>
            )}
          </div>
        </>
      )}{" "}
    </>
  );
};
export default Reviews;
