import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const getRoomByInspection = (page, role, id) => {
  // console.log(page, role, id, "apiCallPropsOne");
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${id}/rooms?page=${page}`
  );
};

const getFromPhotos = (id) => {
  // console.log(page, role, id, "apiCallPropsOne");
  return axios.get(`/v1/inspections/${id}/instructional-images/urls`);
};

const updateImages = (role, imageId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/images/${imageId}`,
    data
  );
};

const getUrlfromAWS = (name) => {
  return axios.get(
    `https://backend.weinspecttogether.com/qa/v1/s3-presigned-upload-url?key=${name}`
  );
};

const getRoomByInspectionTest = (page, role, id) => {
  // console.log(page, role, id, "apiCallPropsOne");
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${id}/rooms?tests=true&page=${page}`
  );
};

const getRoomByTest = (page, role) => {
  return axios.get(`/v1/tests?page=${page}`);
};

const getObservationByRoom = (page, role, id, observation_id) => {
  // console.log(page, role, id, "apiCallPropsOne", observation_id);
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${id}/rooms/${observation_id}/observations?page=${page}&is_under_review=true&is_tests_req=true`
  );
};

const getObservationsById = (role, id, roomId) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/observations/${id}/images?with_observation=true&room=${roomId}`
  );
};

const updateObservationImage = (role, observationId, ImageId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/observations/${observationId}/images/${ImageId}`,

    data
  );
};

const addNotesOnObservationImage = (role, observationId, ImageId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/observations/${observationId}/notes_image/${ImageId}`,

    data
  );
};

const addNewWholeTask = (role, inspectionId, ImageId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${inspectionId}/rooms/${ImageId}/observations`,
    data
  );
};

const addNewInstructional = (role, observationId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/observations/${observationId}/images`,
    data
  );
};

// const  = (role,) => {
//   // console.log(page, role, id, "apiCallPropsOne");
//   return axios.get(`/v1/tests?page=${page}`);
// };

const updateAllObservationImage = (role, observationId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/observations/${observationId}`,
    data
  );
};
const getInspectionRooms = (inspectionId, page = 1) => {
  return axios.get(`/api/v1/room?inspection_id=${inspectionId}&page=${page}`);
};

const getRoomObservation = (roomId, page = 1, data) => {
  return axios.get(`/api/v1/observation?room_id=${roomId}&page=${page}`, data);
};

const createObservation = (data) => {
  return axios.post(`/api/v1/observation`, data);
};

const getObservationTests = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/test?observation_id=${observationId}&page=${page}`
  );
};

const getObservationComments = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/comments?observation_id=${observationId}&page=${page}`
  );
};

const getObservationImages = (observationId, page = 1) => {
  return axios.get(
    `/api/v1/observation/images?observation_id=${observationId}&page=${page}`
  );
};

const addObservationTest = (data) => {
  return axios.post("/api/v1/observation/test", data);
};

const checkTaskName = (data) => {
  return axios.post(`/v1/validate/taskName`, { ...data });
};

const getObservationTestTypes = (keyword, page = 1) => {
  return axios.get(
    `/api/v1/observation/test-sample-info?${
      keyword ? `search=${keyword}&` : ""
    }page=${page}`
  );
};

const updateObservationComment = (id, data) => {
  return axios.post(`/api/v1/observation/comments/${id}`, data);
};

const getInvoices = (inspectionId, page = 1) => {
  return axios.get(
    `/api/v1/inspection/transaction?inspection_id=${inspectionId}&page=${page}`
  );
};

const getInvoiceTest = (inspectionId, page = 1) => {
  return axios.get(
    `/api/v1/inspection/transaction/test?inspection_id=${inspectionId}&page=${page}`
  );
};

const getInvoiceExtraFees = () => {
  return axios.get(`/api/v1/inspection/transaction/extra-fee`);
};

const addInvoice = (data) => {
  return axios.post("/api/v1/inspection/transaction", data);
};

const deleteObservation = (role, inspectionId, data) => {
  return axios.delete(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/inspections/${inspectionId}/observations`,
    {
      data: {
        observation_ids: data,
      },
    }
  );
};

export {
  getInspectionRooms,
  getRoomObservation,
  createObservation,
  getObservationTests,
  getObservationImages,
  getObservationComments,
  addObservationTest,
  getObservationTestTypes,
  updateObservationComment,
  getInvoices,
  getInvoiceTest,
  getInvoiceExtraFees,
  addInvoice,
  getRoomByInspection,
  getRoomByInspectionTest,
  getRoomByTest,
  getObservationByRoom,
  updateObservationImage,
  getUrlfromAWS,
  updateAllObservationImage,
  addNewWholeTask,
  addNewInstructional,
  getFromPhotos,
  updateImages,
  checkTaskName,
  getObservationsById,
  deleteObservation,
  addNotesOnObservationImage,
};
