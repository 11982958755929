import "../style.css";
import { Link } from "react-router-dom";
import React from "react";
import { useLogin } from "./useLogin";
import CustomField from "../../../components/CustomField";
import EmailIcon from "../../../../assets/icons/email@2x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WITLogo from "../../../../assets/logo/web_logo.png";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import passIcon from "../../../../assets/icons/lock@2x.png";
import { regexes } from "../../../shared/regexes";

const Login = () => {
  const [
    email,
    setEmail,
    password,
    setPassword,
    disableLogin,
    setDisableLogin,
    errorText,
    setErrorText,
    errors,
    seterrors,
    FormSubmit,
    setFormSubmit,
    location,
    handleSubmit,
  ] = useLogin();

  return (
    <div className="login-page">
      <div className="text-center">
        <img
          alt="WIT-logo"
          height="202px"
          width="410px"
          className="WIT-logo"
          src={WITLogo}
        />
      </div>

      <div
        style={{
          display: errorText !== "" ? "block" : "none",
        }}
      >
        <div className="email-pass-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <span className="ml-4 font-bold">{errorText}</span>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <CustomField
            name="email"
            value={email}
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            id="login_email"
            type="text"
            icon={EmailIcon}
            minWidth="410px"
            errors={
              FormSubmit &&
              (!email ||
                (!regexes.email.test(email) && errorText) ||
                (errors?.email && errors?.email[0]))
            }
            autoFocus
            inputProps={{
              autoFocus: "autoFocus",
            }}
          />
          <small
            className="text-danger ml-1"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && email === ""
              ? "Please enter an email address"
              : errors?.email
              ? errors?.email[0]
              : ""}
          </small>
        </div>

        <div className="margin-top-field">
          <CustomField
            name="password"
            label="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            password={true}
            icon={passIcon}
            id="login_password"
            className={`auth-input-field`}
            errors={
              FormSubmit &&
              (!password || (errors?.password && errors?.password[0]))
            }
            type="password"
            inputProps={{
              maxLength: "16",
            }}
          />
          <small
            className="text-danger ml-1"
            style={{
              display: FormSubmit ? "block" : "none",
            }}
          >
            {FormSubmit && password === ""
              ? "Please enter a password"
              : errors?.password
              ? errors?.password[0]
              : ""}
          </small>
        </div>
        <div className="margin-top-field text-align-end">
          <button
            id="signin_button"
            disabled={disableLogin}
            variant="primary"
            className={
              disableLogin
                ? "login-btn disabled-bg-color"
                : "login-btn bg-light20"
            }
            type="submit"
          >
            Login
          </button>
        </div>

        <div className="forgot-password mt-0">
          <Link
            to={
              (location.pathname === "/login/home-owner" &&
                `/forget-password`) ||
              (location.pathname === "/login/account-manager" &&
                `/forget-password/account-manager`) ||
              (location.pathname === "/login/product-owner" &&
                `/forget-password/product-owner`)
            }
            className=" margin-top-field new-tab-link"
          >
            Forgot Password?
          </Link>
        </div>
      </form>

      {/* temporary comment out the code according to client requirements */}
      {/* {location.pathname === "/login/home-owner" && (
        <div className="d-flex justify-content-center mt-3 black-text ">
          <div className="mt-3">
            <>
              I'm a new user{" "}
              <Link to="/signup" className="new-tab-link" id="terms">
                Sign Up
              </Link>{" "}
            </>
          </div>
        </div>
      )} */}

      <div className=" margin-top-field black-text  font-16">
        <div className="mt-4">
          By logging in I agree to the{" "}
          <Link
            target="_blank"
            to="/terms-of-use"
            className="new-tab-link"
            id="terms"
          >
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link
            to="/privacy-policy"
            target="_blank"
            className="new-tab-link"
            id="policy"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
