import React from "react";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { getRoomByInspection } from "../../../../../services/ObservationService";
import CustomTooltip from "../../../../components/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Paginator from "../../../../components/Paginator/Paginator";
import SearchDropdown from "../../../../components/SelectDropdown";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import TaskListing from "./TaskListing";
import { showToast } from "main/shared/helpers";

export const ObservationLeftSide = (props) => {
  const {
    taskIdParams,
    RoomName,
    selectedRoomId,
    loader,
    tasks,
    selectedTask,
    setselectedTask,
    selectedTaskIndex,
    setselectedTaskIndex,
    settasksPage,
    taskpageIndex,
    setselectedTaskNumber,
    activePagetasks,
    itemsCountPerPagetasks,
    totalItemsCounttasks,
    refreshlinkTasks,
    setrefreshlinkTasks,
    setCheckedData,
    setCheckedCount,
    setOpenAddFeatureModal,
    setloadingImg,
    counterImg,
    setcounterImg,
    refreshIcon,
    currentRefresh,
    setcurrentRefresh,
    setConfirmModals,
    showCheckbox,
    setShowCheckbox,
    isChecked,
    setisChecked,
    checkDisable,
    userInfo,
    insp_id,
    roomId,
    setRoomName,
    setSelectedRoomId,
  } = props;

  const searchField = () => {
    return (
      <SearchDropdown
        placeHolder="Select Room / Item Type"
        apiCall={getRoomByInspection}
        pivotID={true}
        showStatus={true}
        showErrors="No rooms found"
        value={
          RoomName
            ? {
                value: roomId,
                label: RoomName,
              }
            : null
        }
        saveProp={"id"}
        user_role={userInfo?.role}
        apiCallPropsOne={insp_id}
        showProp={["name"]}
        onSelect={(ev) => {
          setRoomName(ev?.label);
          setShowCheckbox(false);
          setcurrentRefresh(true);
          setSelectedRoomId(ev.value);
        }}
      />
    );
  };

  const openAddAFeatureModal = () => {
    if (!checkDisable) {
      setOpenAddFeatureModal(true);
    } else {
      showToast("Inspection is already completed");
    }
  };
  return (
    <div
      className="leftside"
      style={{
        padding: "15px 5px 5px 20px",
      }}
    >
      <div className="leftside-header">
        <div className="d-flex justify-content-between">
          <h4 className="mb-0">Rooms / Items</h4>
          {selectedRoomId && (
            <CustomTooltip
              placement="right"
              delay={{
                show: 250,
                hide: 400,
              }}
              text={"Refresh"}
            >
              <div disabled={refreshIcon}>
                <FontAwesomeIcon
                  className={`${
                    (refreshlinkTasks || currentRefresh) &&
                    "refreshing-observation"
                  }`}
                  icon={faSync}
                  onClick={() => {
                    !refreshIcon && setrefreshlinkTasks(true);
                    setcurrentRefresh(true);
                  }}
                  style={{
                    marginLeft: "0px",
                    cursor: !refreshIcon && "pointer",
                    marginRight: "8px",
                    fontSize: "20px",
                  }}
                />
              </div>
            </CustomTooltip>
          )}
        </div>
        <br />
        <div>
          {selectedRoomId ? (
            RoomName ? (
              searchField()
            ) : (
              <div className="d-center-div">
                <Spinner animation="border" variant="success" />
              </div>
            )
          ) : (
            searchField()
          )}
          <small className="text-danger ml-1"></small>
        </div>
        {selectedRoomId && (
          <div className="d-flex">
            <h4
              className="center-div"
              style={{
                height: "unset",
                fontFamily: "Basis-Grotesque-Pro-Medium' !important",
                fontWeight: "normal",
              }}
            >
              Feature List
            </h4>
            <div className="ml-auto d-flex">
              <img
                className="iconstyle crossicon delete-btn"
                src={
                  require("../../../../../assets/icons/bin-icon.png").default
                }
                alt="icon"
                style={
                  !checkDisable
                    ? {
                        cursor: "pointer",
                      }
                    : {
                        cursor: "not-allowed",
                      }
                }
                onClick={() => {
                  if (checkDisable === false) {
                    if (isChecked === false && showCheckbox === false) {
                      setShowCheckbox(true);
                    } else if (isChecked === true && showCheckbox === true) {
                      setConfirmModals(true);
                    } else {
                      setShowCheckbox(false);
                    }
                  }
                }}
              />
              <CustomTooltip
                placement="top"
                delay={{ show: 250, hide: 400 }}
                text={"Add a Feature"}
              >
                <img
                  className={`constyle crossicon delete-btn ${
                    checkDisable ? "disable-icon" : ""
                  } `}
                  onClick={openAddAFeatureModal}
                  src={
                    require("../../../../../assets/icons/add_feature.png")
                      .default
                  }
                  alt="add_feat_btn"
                />
              </CustomTooltip>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          marginTop: "8px",
        }}
      >
        <ScrollBar
          style={{
            height: "auto",
          }}
        >
          <ul
            className={loader ? "ul-style list-spinner" : "ul-style"}
            style={{
              height: "calc(100vh - 410px)",
              padding: "0px 15px 0px 10px",
            }}
          >
            {loader ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <TaskListing
                tasks={tasks}
                selectedTaskIndex={selectedTaskIndex}
                taskpageIndex={taskpageIndex}
                setselectedTaskNumber={setselectedTaskNumber}
                setselectedTask={setselectedTask}
                setloadingImg={setloadingImg}
                selectedTask={selectedTask}
                counterImg={counterImg}
                setcounterImg={setcounterImg}
                setselectedTaskIndex={setselectedTaskIndex}
                setShowCheckbox={setShowCheckbox}
                showCheckbox={showCheckbox}
                setisChecked={setisChecked}
                setCheckedData={setCheckedData}
                setCheckedCount={setCheckedCount}
              />
            )}
          </ul>
        </ScrollBar>
        <div>
          {tasks &&
            tasks?.data?.length !== 0 &&
            !loader &&
            totalItemsCounttasks > 0 &&
            !taskIdParams && (
              <Paginator
                activePage={activePagetasks}
                itemsCountPerPage={itemsCountPerPagetasks}
                totalItemsCount={totalItemsCounttasks}
                onChange={(page) => {
                  settasksPage(page);
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
};
