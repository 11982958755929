import "./style.css";

import { LOGOUT_USER, LOG_OUT } from "../../../store/actions";
import React, { useEffect, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_SEARCH_FILTER } from "../../../store/actions/header.actions";
import { filtersObj } from "../../shared/helpers";
import { logoutRequest } from "../../../services/auth-service";
import { showToast } from "../../shared/helpers";
import { useHistory } from "react-router-dom";

export default function ProfileIcon(props) {
  const [open, setOpen] = useState(true);
  const [isLoggingout, setIsLoggingout] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.data);
  const defaultImage = require("../../../assets/default_profile.png").default;

  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleLogout = () => {
    setIsLoggingout(true);
    Logout(dispatch, setIsLoggingout);
  };

  const Logout = (dispatch, disableSideNavLogoutBtn) => {
    const fcm_token = atob(localStorage.getItem("fcm_token"));
    console.log(fcm_token, "fcm_tokenfcm_tokenfcm_token");
    let body = {};
    if (fcm_token && fcm_token !== "ée") {
      body = { fcm_token };
    }
    logoutRequest(body)
      .then(() => {
        localStorage.clear();

        dispatch({
          type: LOG_OUT,
          payload: {},
        });
        dispatch({
          type: LOGOUT_USER,
          payload: {
            logout: true,
          },
        });

        dispatch({ type: SET_SEARCH_FILTER, payload: filtersObj() });

        if (user.role === "super_admin") {
          history.replace("/login/product-owner");
        } else if (user.role === "admin") {
          history.replace("/login/account-manager");
        }
      })
      .catch(() => {
        disableSideNavLogoutBtn(false);
        showToast("Something went wrong");
      });
  };

  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    setImage(user?.avatar);
    refreshCachedImage("profile-image");
  }, [user]);

  function refreshCachedImage(img_id) {
    var img = document.getElementById(img_id);
    img.src = img.src; // trick browser into reload
  }

  return (
    <div
      className="d-flex flex-column align-items-center mt-3"
      style={{
        width: "100%",
        padding: "15px 0px 37px",

        // ...(user.role === authRoles.superAdmin
        //   ? {  }
        //   : {}),
        ...props.style,
      }}
    >
      <div>
        <img
          // key={Math.floor(Math.random() * 10)}
          src={`${image}`}
          className="avatar bg-light80"
          alt="profile_image"
          style={{
            width: "64px",
            height: "64px",
            border: "1px solid lightgray",
            objectFit: "cover",
            borderRadius: "50%",
          }}
          id="profile-image"
          onError={(ev) =>
            (ev.target.src =
              require("../../../assets/default_profile.png").default)
          }
        />
      </div>
      <div className="d-flex flex-row align-items-center mt-1">
        <Dropdown
          drop="down"
          // onToggle={(isOpen) => {
          //   setOpen(isOpen);
          // }}
        >
          <Dropdown.Toggle
            variant="light"
            id="profile_ations_dropdown"
            disabled={isLoggingout}
          >
            {user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : user.business_unit?.company_name}
            <FontAwesomeIcon
              className="ml-2"
              id="dropdown_icon"
              icon={open ? faCaretUp : faCaretDown}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu id="dropdown_menu" align="right">
            <Dropdown.Item
              className="dropdown_btn"
              as="button"
              onClick={() => {
                setConfirmationModal(true);
              }}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ConfirmationModal
        show={confirmationModal}
        onHide={() => setConfirmationModal(false)}
        disabled="false"
        imgSrc={require("../../../assets/icons/delete.png").default}
        helperText="Are you sure you want to logout?"
        onSave={() => {
          handleLogout();
        }}
      />
    </div>
  );
}
