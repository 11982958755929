import * as Actions from "../actions";

const initialState = {
  showResetSuccess: false,
  logout: false,
  showGoback: false,
  apiState: false,
  loader_state: true,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_PASS_REST:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.LOGOUT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.SHOW_GOBACK:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.API_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.LOADER_STATE:
      return {
        ...state,
        loader_state: action.payload,
      };

    default:
      return state;
  }
}
