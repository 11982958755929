import axios from "../axios-defaults";
import { roleNames } from "../main/shared/helpers";

const createProperty = (role, data) => {
  return axios.post(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/properties`,
    data
  );
};

const getProperty = (role, page = 1, keyword = "") => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.bu_homeowner ? "/cust" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/properties?${
      keyword && `search=` + keyword + "&"
    }${page && `page=` + page}`
  );
};
const getPropertyByUserId = (role, page = 1, id) => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.bu_homeowner ? "/cust" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/properties?${
      `customer_id=` + id + "&"
    }${page && `page=` + page}`
  );
};

const getPropertyByCustomer = (role, page = 1, search, customerId = "") => {
  return axios.get(
    `/v1${role === roleNames.superAdmin ? "/po" : ""}${
      role === roleNames.bu_homeowner ? "/cust" : ""
    }${
      role === roleNames.buAdmin ? "/am" : ""
    }/properties?customer_id=${customerId}&in_inspection=true&search=${search}&page=${page}`
  );
};

// const getInspectionsByProperty = (propertyId, page = 1) => {
//   return axios.get(`api/v1/bu/jobs?property_id=${propertyId}&page=${page}`);
// };

const getPropertyDetails = (role, propertyId) => {
  return axios.get(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/properties/${propertyId}`
  );
};

const getUsStates = (page = 1, role, data, keyword) => {
  return axios.get(
    `v1/usstates?${keyword ? `search=${keyword}&` : `page=${page}`}`
  );
};

const updateProperty = (role, propertyId, data) => {
  return axios.post(
    `v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/properties/${propertyId}`,
    {
      ...data,
      _method: "patch",
    }
  );
};

const getInspectionsByProperty = (role, propertyId, page) => {
  return axios.get(
    `/v1${role === roleNames.bu_homeowner ? "/cust" : ""}${
      role === roleNames.superAdmin ? "/po" : ""
    }${role === roleNames.buAdmin ? "/am" : ""}/inspections?property_id=${
      propertyId + "&"
    }page=${page}`
  );
};

export {
  createProperty,
  getProperty,
  getPropertyByCustomer,
  getInspectionsByProperty,
  getPropertyDetails,
  updateProperty,
  getPropertyByUserId,
  getUsStates,
};
