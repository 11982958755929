import "./style.css";

import { getAddress, timeConversionUTC, utcDate } from "../../shared/helpers";

import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { getInspectionsByProperty } from "../../../services/PropertyService";
import ScrollBar from "react-perfect-scrollbar";
import { Spinner } from "react-bootstrap";
import Paginator from "../../components/Paginator/Paginator";

const PropertyInspections = (props) => {
  const {
    history,
    propertyId,
    userInfo,
    property,
    editEnabled,
    listLoader,
    setListLoader,
  } = props;

  const [inspectionData, setInspectionData] = useState([]);
  const [inspPage, setInspPage] = useState(1);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    listApi(propertyId, userInfo, inspPage);
  }, [propertyId, userInfo, inspPage]);

  useEffect(() => {
    if (listLoader) {
      listApi(propertyId, userInfo, inspPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLoader]);

  const listApi = (propertyId, userInfo, inspPage) => {
    setLoader(true);
    getInspectionsByProperty(userInfo?.role, propertyId, inspPage)
      .then((res) => {
        setListLoader(false);
        setLoader(false);
        setInspectionData(res.data);
      })
      .catch((err) => {
        console.log(err, "=====");
      });
  };
  return (
    <div>
      {!editEnabled && propertyId && (
        <div className="row mt-4">
          <div className="col-md-6 d-flex align-items-center height-insp-text">
            {" "}
            <h4>Inspections</h4>
          </div>
          <div className="col-md-6 d-flex">
            <Link
              className="ml-auto"
              style={{
                textDecoration: "none",
              }}
              to={{
                pathname: "/inspections/new",
                state: {
                  property: { ...property, id: propertyId },
                },
              }}
              id="create_inspection"
            >
              <button className="btn btn-primary">
                <img
                  alt="create_inspection"
                  src={require(`../../../assets/icons/add@2x.png`).default}
                />
                Add Inspection
              </button>
            </Link>
          </div>
        </div>
      )}
      <ScrollBar
        className="mt-2"
        style={
          loader
            ? {
                height: "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
            : inspectionData?.data?.length === 0
            ? { height: "", paddingRight: "10px" }
            : inspectionData?.data?.length < 3
            ? { height: "calc(100vh - 384px)", paddingRight: "10px" }
            : { height: "calc(100vh - 207px)", paddingRight: "10px" }
        }
      >
        <div>
          {loader ? (
            <>
              <div>
                <Spinner animation="border" variant="success" />
              </div>
            </>
          ) : (
            <React.Fragment>
              <div></div>
              {!editEnabled && propertyId !== undefined ? (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Date/Time Created</th>
                      <th>Last Modified</th>
                      <th
                        style={{
                          minWidth: "64px",
                        }}
                      >
                        Street / City / Zip
                      </th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspectionData?.data?.length !== 0 ? (
                      inspectionData?.data?.map((item, i) => (
                        <tr
                          onClick={() => {
                            if (userInfo?.role === "customer") {
                              history.replace(`/inspections/${item.id}`);
                            } else {
                              history.replace(
                                `/inspections/${item.id}/details?tab=generalInfo`
                              );
                            }
                          }}
                          /*key={`inspection-${i}`}*/
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <td
                            className=""
                            style={{
                              width: "15%",
                            }}
                          >
                            <span>{utcDate(item?.created_at)}</span>
                            <br />
                            <span>{timeConversionUTC(item?.created_at)}</span>
                          </td>
                          <td
                            className=""
                            style={{
                              width: "15%",
                            }}
                          >
                            <span>{utcDate(item?.updated_at)}</span>
                            <br />
                            <span>{timeConversionUTC(item?.updated_at)}</span>
                          </td>
                          <td
                            style={{
                              width: "70%",
                            }}
                          >
                            <span className="">
                              {getAddress(item?.property?.address)}
                            </span>
                          </td>

                          <td>
                            <div>
                              <button className="next-btn ripple">
                                <img
                                  alt="arrow"
                                  src={
                                    require("../../../assets/icons/arrow@2x.png")
                                      .default
                                  }
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9">No record found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>
      </ScrollBar>
      {!loader &&
      Object.keys(inspectionData).length !== 0 &&
      inspectionData?.data?.length !== 0 ? (
        <nav className="mt-4 mb-3">
          <span className="pagination justify-content-center mb-0">
            <Paginator
              activePage={inspectionData?.current_page}
              itemsCountPerPage={inspectionData?.per_page}
              totalItemsCount={inspectionData?.total}
              onChange={(page) => {
                setInspPage(page);
              }}
            />
          </span>
          <span className="total-align" style={{ marginTop: "-41px" }}>
            Showing {inspectionData?.from} to {inspectionData?.to} of{" "}
            {inspectionData?.total} entries{" "}
          </span>
        </nav>
      ) : null}
    </div>
  );
};

export default PropertyInspections;
