import "./style.css";

import {
  checkCustomer,
  getErrorMsg,
  inputNumberKeyPress,
  showToast,
  valueExists,
} from "../../shared/helpers";
import { useHistory, useLocation, useParams } from "react-router-dom";

import PropertyForm from "./PropertyForm";
import ScrollBar from "react-perfect-scrollbar";
import { createProperty } from "../../../services/PropertyService";
import { useSelector } from "react-redux";
import { useState } from "react";

const PropertyAdd = () => {
  const history = useHistory();
  const location = useLocation();
  const propertyData = location?.state;
  const { propertyId } = useParams();

  const [dis, setdis] = useState(false);
  const userInfo = useSelector((state) => state.user.data);
  const [isSubmit, setIsSubmit] = useState(false);

  const [property, setProperty] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    customer_id: checkCustomer(userInfo, propertyData),
    customer_first_name: propertyData?.first_name ?? "",
    customer_last_name: propertyData?.last_name ?? "",
  });

  const [errors, seterrors] = useState({});

  const submit = () => {
    setIsSubmit(true);
    if (valueExists(property)) {
      setdis(true);

      createProperty(userInfo?.role, { ...property })
        .then((resp) => {
          if (resp) {
            console.log(resp.data);
            seterrors({});
            showToast("Property has been created", true);
            history.replace(`/property/${resp?.data?.id}`);
          }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data?.errors);
            showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => setdis(false));
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <PropertyForm
          history={history}
          propertyId={propertyId}
          dis={dis}
          userInfo={userInfo}
          isSubmit={isSubmit}
          property={property}
          setProperty={setProperty}
          errors={errors}
          editEnabled={false}
          inputNumberKeyPress={inputNumberKeyPress}
          submit={submit}
        />
      </ScrollBar>
    </div>
  );
};

export default PropertyAdd;
