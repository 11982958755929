import React from "react";
import { checkTasks } from "../../../../shared/helpers";

const TaskListing = ({
  tasks,
  setselectedTask,
  setselectedTaskIndex,
  selectedTaskIndex,
  setselectedTaskNumber,
  taskpageIndex,
  testMode,
}) => {
  return (
    <>
      {tasks && tasks?.data?.length !== 0 && checkTasks(tasks).length > 0 && (
        <div className="tasklist">
          {tasks?.data?.map((task, i) => (
            <React.Fragment key={task}>
              <li
                className="d-flex justify-content-between"
                onClick={() => {
                  setselectedTaskIndex(i);
                  setselectedTaskNumber(taskpageIndex + i);
                  setselectedTask(task);
                }}
                style={{
                  cursor: "pointer",
                  fontFamily: selectedTaskIndex === i ? "Basis-Grotesque-Pro-Medium" : "Basis-Grotesque-Pro-Regular",
                  color:
                    !testMode &&
                    (task.status === "approved"
                      ? "#64BC51"
                      : task.status === "rejected"
                      ? "#FF1A1A"
                      : task.status === "pending_2"
                      ? "#FFAA00"
                      : "black"),
                }}
                key={task}
              >
                Feature {taskpageIndex + i}: {task?.name}
                <span>
                  {" "}
                  {!testMode && task?.status === "submitted" && (
                    <img
                      alt="status"
                      src={
                        require("../../../../../assets/icons/under-review-icon.png")
                          .default
                      }
                    />
                  )}
                </span>
              </li>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default TaskListing;
