import { Button, Modal } from "react-bootstrap";
// import { useSelector } from "react-redux";

import "./style.css";
export default function ConfirmationModal(props) {
  // const checkDisable = useSelector((state) => state.inspectionDisable.disable);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-40w"
      aria-labelledby="contained-modal-title"
      centered
      className="custom-modal confirmation"
      backdropClassName="foo-modal-backdrop-photos"
    >
      <Modal.Header
        style={{ padding: "1rem 1rem 0rem 1rem" }}
        className="no-border"
      >
        <Modal.Title id="contained-modal-title">
          {props.hideIcon === undefined ? (
            <div className="dialogheader">
              <div className="d-flex"></div>
              <img
                className="iconstyle crossicon"
                src={require("../../../assets/icons/Cross1@2x.png").default}
                alt="icon"
                onClick={() => {
                  props.onHide();
                }}
              />
            </div>
          ) : (
            ""
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0rem 1rem 0rem 1rem" }}>
        <div
          className="dialogbody"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="d-block" style={{ textAlign: "center" }}>
            {props.imgSrc ? (
              <img
                alt="edit_room"
                className="img-confirmation"
                src={props.imgSrc}
              />
            ) : (
              <span>
                <h5>
                  <b>{props.headingText}</b>
                </h5>
              </span>
            )}

            <br />
            <span className="sub-heading">{props.helperText}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ padding: "1rem 0px 1.15rem" }}
        className="no-border"
      >
        <Button
          disabled={props.disable}
          onClick={() => {
            props.onSave(props);
            props.onHide();
          }}
          className="yes"
          id="yes-confirmation"
        >
          Yes
        </Button>

        <Button
          disabled={props.disable}
          className="no"
          onClick={() => {
            props.onHide();
          }}
          id="no-confirmation"
        >
          No{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
