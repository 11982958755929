import { roleNames } from "../../shared/helpers";

export const addRooms = (inspection, rID, rName, pivotIDs, pivotName) => {
  return new Promise((resolve, reject) => {
    inspection?.rooms?.forEach((e, index, array) => {
      if (e.pivot?.id) {
        pivotIDs.push(e.pivot?.id);
        pivotName.push(
          e?.pivot?.description ? e?.pivot?.description : e?.description
        );
      } else {
        rID.push(e.id);
        rName.push(
          e?.pivot?.description ? e?.pivot?.description : e?.description
        );
      }
      if (index === array.length - 1) resolve();
    });
  });
};

export const checkValuesSubmit = (inspection, rID, rName) => {
  if (
    inspection.type &&
    inspection.property &&
    inspection.notes &&
    rName.length > 0 &&
    rID.length > 0
  ) {
    return true;
  }

  return false;
};

export const checkValuesEdit = (inspection, rID, rName, pivotIDs) => {
  if (
    inspection.type &&
    inspection.property &&
    inspection.notes &&
    ((rName?.length > 0 && rID?.length > 0) ||
      (pivotIDs?.length > 0 && pivotIDs?.length > 0))
  ) {
    return true;
  }
};

export const redirectLinks = (data, userInfo, location, history) => {
  if (!data.calenly_id) {
    if (
      userInfo?.role !== roleNames.buAdmin &&
      userInfo?.role !== roleNames.superAdmin
    ) {
      history.push({
        pathname: "/calendly",
        state: { inspection_id: data.id, location },
      });
    } else {
      // history.push({
      //   pathname: "/inspections",
      // });
    }
  }
};

export const initialState = (location, newdata) => {
  return location.state
    ? {
        notes: "",
        property: "",
        type: "",
        rooms: [],
        inspector_id: location.state.customer_id
          ? location.state.customer_id
          : null,
        inspectors: newdata,
      }
    : {
        notes: "",
        property: "",
        type: "",
        rooms: [],
        inspector_id: null,
      };
};
