import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { deleteBU } from "../../../services/bu-admin-service";
import { LOGOUT_USER, LOG_OUT } from "../../../store/actions";
import { getErrorMsg, showToast } from "../../shared/helpers";
import { useHistory } from "react-router-dom";

const SuccessModal = (props) => {
  const [BUCompanyName, setBUCompanyName] = useState("");
  const [disableBtns, setDisableBtns] = useState(false);
  let history = useHistory();

  const dispatch = useDispatch();

  const DeleteFunctionCall = () => {
    if (BUCompanyName === props.company_name) {
      if (props.delfunc) {
        props.delfunc(setDisableBtns);
      } else {
        deleteBU()
          .then(() => {
            localStorage.clear();
            dispatch({
              type: LOG_OUT,
              payload: {},
            });
            dispatch({
              type: LOGOUT_USER,
              payload: {
                logout: true,
              },
            });
          })
          .catch((err) => {
            showToast(getErrorMsg(err) || "Unable to delete Business Unit.");
            close();
          });
      }
    } else {
      showToast("Company name doesn't match");
    }
  };

  const close = () => {
    setBUCompanyName("");
    props.onHide();
  };

  const doneBtn = () => {
    if (props?.redirectLink !== "" && props?.redirectLink) {
      history.replace(props.redirectLink);
    } else {
      props.onHide();
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={close}
      size="md"
      aria-labelledby="contained-modal-title"
      centered
      keyboard={false}
      backdrop={props.closeOutside && "static"}
      className="custom-modal"
      backdropClassName="foo-modal-backdrop"
    >
      <Modal.Header className="no-border">
        <Modal.Title id="contained-modal-title">
          <div className="dialogheader">
            <div className="d-flex"></div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/Cross1@2x.png").default}
              alt="icon"
              onClick={close}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dialogbody">
          <div className="d-center">
            <img
              src={require("../../../assets/icons/check-img@2x.png").default}
              alt="icon"
            />
          </div>
          <div className="custom-modal-text">
            <span>{props.displayText}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="no-border">
        <Button
          onClick={doneBtn}
          id="cancel_success_modal"
          disabled={disableBtns}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
