/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { contentRequest } from "../../../services/auth-service";
import "./terms.css";
export default function TermsAndPolicy() {
  const [html, setHtml] = useState("<div />");
  const location = useLocation();

  useEffect(() => {
    contentRequest(location.pathname.substring(1))
      .then((res) => {
        setHtml(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div
        style={{
          backgroundColor: "rgb(244, 234, 223)",
          height: "100vh",
          /* text-align: center; */
          paddingTop: "20px",
          paddingInline: "49px",
          overflow: "scroll",
        }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
        className="terms-policy"
      ></div>
    </div>
  );
}
