/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getInspectionByUserID } from "../../../../services/InspectionService";
import { getPropertyByUserId } from "../../../../services/PropertyService";
import Paginator from "../../../components/Paginator/Paginator";
import {
  getAddress,
  getErrorMsg,
  showToast,
  utcDate,
  timeConversionUTC,
} from "../../../shared/helpers";
import "./style.css";

const UserInfoTableuserInfoListComponent = (props) => {
  const { inspection, role } = props;
  const history = useHistory();
  const inspectionLink = (id) => {
    if (role === "customer") {
      history.push(`/property/${id}`);
    } else {
      history.push(`/inspections/${id}/details?tab=generalInfo`);
    }
  };
  return Object.keys(inspection).length !== 0 &&
    inspection?.data?.length !== 0 ? (
    inspection.data?.map((item, i) => (
      <tr
        key={`inspection-${i}`}
        style={{ cursor: "pointer" }}
        onClick={() => inspectionLink(item.id)}
      >
        {console.log(item, "items----------------items--------------")}
        <td className="">
          <span>{utcDate(item?.created_at)}</span>
          <br />
          {/* {console.log(timeConversionUTC(item?.created_at), "time-------------------")} */}
          <span>{timeConversionUTC(item?.created_at)}</span>
        </td>
        <td>
          <span>{utcDate(item?.updated_at)}</span>
          <br />
          <span>{timeConversionUTC(item?.updated_at)}</span>
        </td>

        <td className="">
          <span>
            {getAddress(
              item?.property?.address ? item.property.address : item?.address
            )}
          </span>
        </td>
        <td>
          <div>
            <button className="next-btn ripple">
              <img
                alt="arrow"
                src={require("../../../../assets/icons/arrow@2x.png").default}
              />
            </button>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9">No record found</td>
    </tr>
  );
};

const UserInfoTable = ({ userId, role }) => {
  const [userInfoList, setuserInfoList] = useState({});
  const userInfo = useSelector((state) => state.user.data);

  const [loader, setloader] = useState(true);
  const [page, setpage] = useState(1);

  useEffect(() => {
    if (role === "inspector") {
      setloader(true);
      getInspectionByUserID(page, userInfo?.role, userId)
        .then((x) => {
          setuserInfoList(x.data);
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => setloader(false));
    } else if (role === "customer") {
      setloader(true);
      getPropertyByUserId(userInfo?.role, page, userId)
        .then((x) => {
          setuserInfoList(x.data);
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            showToast(err?.response?.data?.message);
          } else {
            showToast(getErrorMsg(err));
          }
        })
        .finally(() => setloader(false));
    }
  }, [page]);

  return (
    <>
      <div className="">
        <div className="">
          <ScrollBar
            className="mt-2"
            style={
              loader
                ? {
                    height: "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                : userInfoList?.data?.length === 0
                ? { height: "", paddingRight: "10px" }
                : userInfoList?.data?.length < 3
                ? { height: "calc(100vh - 384px)", paddingRight: "10px" }
                : { height: "calc(100vh - 207px)", paddingRight: "10px" }
            }
          >
            <div>
              {loader ? (
                <>
                  <div>
                    <Spinner animation="border" variant="success" />
                  </div>
                </>
              ) : (
                <table className="table table-responsive-md">
                  <thead>
                    <tr>
                      <th>Date/Time Added</th>
                      <th>Last Modified</th>
                      <th>Street/City/Zip</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <UserInfoTableuserInfoListComponent
                      role={role}
                      inspection={userInfoList}
                    />
                  </tbody>
                </table>
              )}
            </div>
          </ScrollBar>
          {!loader &&
          Object.keys(userInfoList).length !== 0 &&
          userInfoList?.data?.length !== 0 ? (
            <nav className="mt-4 mb-3">
              <span className="pagination justify-content-center mb-0">
                <Paginator
                  activePage={userInfoList?.current_page}
                  itemsCountPerPage={userInfoList?.per_page}
                  totalItemsCount={userInfoList?.total}
                  onChange={(page) => {
                    setpage(page);
                  }}
                />
              </span>
              <span className="total-align" style={{ marginTop: "-41px" }}>
                Showing {userInfoList?.from} to {userInfoList?.to} of{" "}
                {userInfoList?.total} entries{" "}
              </span>
            </nav>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default UserInfoTable;
