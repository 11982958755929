/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Accordion, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { setHeader } from "../../../main/shared/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Submenu(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, onUpdateSize } = props;
  const accordion = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [newIsOpen, setnewIsOpen] = useState(false);
  useEffect(() => {
    if (
      location.pathname.startsWith("/settings/") &&
      accordion?.current &&
      !isOpen
    ) {
      accordion?.current?.click();
    } else if (!location?.pathname?.startsWith("/settings/") && isOpen) {
      accordion?.current?.click();
    }
    if (!location.pathname.startsWith("/settings")) {
      setnewIsOpen(false);
    }
  }, [location.pathname]);

  return (
    <Accordion>
      {/* {console.log(accordion, "accordionaccordionaccordionaccordion")} */}
      <Accordion.Toggle
        as={Button}
        variant="link"
        style={{ textDecoration: "none", padding: "0px", width: "100%" }}
        eventKey="0"
        ref={accordion?.current}
        onClick={() => {
          setIsOpen((state) => !state);
          setnewIsOpen(true);
        }}
      >
        <Link
          className="nav-link"
          style={{
            color: "#ffffff",
            background: newIsOpen ? "#333333" : "transparent",
          }}
          to={"/settings/user-details"}
          id="tools_route"
        >
          {data.icon ? (
            <img
              src={require(`../../../assets/icons/${data.icon}`).default}
              alt="icon"
            />
          ) : null}
          {data.name}
          {!isOpen ? (
            <FontAwesomeIcon
              style={{ position: "absolute", right: "20px" }}
              icon={faChevronRight}
            />
          ) : (
            <FontAwesomeIcon
              style={{ position: "absolute", right: "20px", marginTop: "4px" }}
              icon={faChevronDown}
            />
          )}
        </Link>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <div>
          {data.childrens &&
            data.childrens.map((child, i) => (
              <NavLink
                key={`sub-menu-${i}`}
                id={child.name}
                to={data.path + child.to}
                className="nav-link"
                style={{ paddingLeft: 50, color: "#ffffff", marginTop: "4px" }}
                activeClassName="active"
                activeStyle={{ color: "#ffffff" }}
                isActive={(match) => {
                  if (match) {
                    setHeader(dispatch, {
                      title: child.name,
                      previousPath: "",
                      icon: data.icon,
                    });
                    return true;
                  }
                  return false;
                }}
              >
                {child.name}
              </NavLink>
            ))}
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
}

export default Submenu;
