import * as markerjs2 from "markerjs2";

import { Button, Modal, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  dataUrlToFile1,
  getBase64,
  markersArray,
  replaceByStorageURL,
  showToast,
} from "../../shared/helpers";

import { updateImages } from "../../../services/ObservationService";
import { uploadFile } from "../../../services/UploadFile";

const TasksApproveEditorModal = (props) => {
  const {
    role,
    selectedSubmittedImage,
    setrefreshlinkTasks,
    setnotRootLevelRefresh,
  } = props;
  const [imgState, setimgState] = useState(
    replaceByStorageURL(selectedSubmittedImage?.image_url)
  );
  const [loader, setloader] = useState(false);
  let imgRef = React.useRef(null);
  const [editorView, setEditorView] = useState(true);

  const showMarkerArea = () => {
    console.log(imgRef?.current, "this.imgRef.current");
    if (imgRef?.current !== null) {
      const markerArea = new markerjs2.MarkerArea(imgRef?.current);
      // attach an event handler to assign annotated image back to our image element
      markerArea.addRenderEventListener((dataUrl, state) => {
        if (imgRef?.current) {
          imgRef.current.src = dataUrl;
        }
      });
      markerArea.renderAtNaturalSize = true;
      markerArea.renderImageType = "image/jpeg";
      markerArea.renderImageQuality = 0.7;
      markerArea.addEventListener("beforeclose", (event) => {
        setEditorView(true);
      });

      markerArea.addEventListener("render", (event) => {
        setEditorView(true);
      });

      markerArea.addEventListener("show", (event) => {
        setEditorView(false);
      });
      markerArea.availableMarkerTypes = markersArray(markerjs2);

      markerArea.settings.displayMode = "popup";

      // launch marker.js
      markerArea.show();
    }
  };

  useEffect(() => {
    if (props.show) {
      setloader(true);

      getBase64(replaceByStorageURL(selectedSubmittedImage?.image_url)).then(
        (res) => {
          // console.log(selectedSubmittedImage?.image_url, "----------i am here");
          // console.log("data:image/jpeg;base64,", res);
          setloader(false);
          setimgState(
            `data:image/${selectedSubmittedImage?.image_url
              .split(".")
              .pop()};base64,${res}`
          );
        }
      );
    }
  }, [props.show]);

  const updateEditorImage = (dataUrlImage) => {
    let data = {};

    dataUrlToFile1(
      dataUrlImage,
      `mobile/images/observation_images/${new Date().getTime()}.${selectedSubmittedImage?.image_url
        .split(".")
        .pop()}`
    )
      .then((imageFile) => {
        setloader(true);
        uploadFile(imageFile, imageFile.name)
          .then((url) => {
            data = {
              image_url: url,
            };

            data = { ...data, _method: "PATCH" };
            // rejectImageAPI(data);
            console.log(data, "datadatadatadatadata");
            // formImageUpdate.append("image_url", imageFile);
            // formImageUpdate.append("_method", "PATCH");
            // // setBitImageBool(true);
            imageUpdateAPI(data);
          })
          .catch((er) => {
            showToast("Image is not uploading");

            console.log(er, "");
          });
      })
      .catch((er) => {
        showToast("Image is not converted", er);
      });
  };

  const imageUpdateAPI = (data) => {
    updateImages(role, selectedSubmittedImage?.id, data)
      .then((imageRes) => {
        setrefreshlinkTasks(true);
        if (setnotRootLevelRefresh) {
          setnotRootLevelRefresh(true);
        }
        setimgState("");

        console.log(imageRes);
        showToast("Task Updated Successfully", true);
        setTimeout(() => {
          props.onHide();
        }, 1000);
      })
      .catch((errorFile) => {
        console.log(errorFile);
      })
      .finally(() => {
        // settestRecommendationModal(true);
        // setrefreshlinkTasks(true);
        // setnotRootLevelRefresh(true);
        // // setimgState("");
        // // setapicallDis(false);
        // // setBitImageBool(false);
        // setloader(false);
      });
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title"
      centered
      className={`custom-modal ${!editorView ? "notShow" : "show-this-modal"}`}
      backdropClassName="foo-modal-backdrop"
    >
      <Modal.Header
        style={{ padding: "1rem 1rem 0rem 1rem" }}
        className="no-border"
      >
        <Modal.Title id="contained-modal-title">
          <div className="dialogheader">
            <div className="d-flex"></div>
            <img
              className="iconstyle crossicon"
              src={require("../../../assets/icons/Cross1@2x.png").default}
              alt="icon"
              onClick={() => {
                setimgState("");
                props.onHide();
              }}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0rem 1rem 0rem 1rem" }}>
        <div
          className={`dialogbody ${loader && "d-center-div"}`}
          style={{ height: loader && "400px" }}
        >
          <div className="image-observation-modal-text">
            {!loader && <span>Click task to view editor mode</span>}
          </div>

          {loader ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <div className="d-center">
              <div>
                <img
                  className="image-approved-reject"
                  src={replaceByStorageURL(imgState)}
                  onClick={() => showMarkerArea()}
                  ref={imgRef}
                  alt="sample"
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ padding: "0rem 0px 1.15rem" }}
        className="no-border"
      >
        <Button
          // variant="light"
          onClick={() => {
            // console.log(object)
            // setBitImage();

            updateEditorImage(imgRef?.current?.src);
          }}
          style={{
            minWidth: "154px",
            height: "46px",
            background: "#FFD502",
            borderColor: "#FFD502",
          }}
          disabled={loader}
          id="save_edited"
        >
          Done{" "}
        </Button>
        {/* <Button
          style={{
            minWidth: "154px",
            height: "46px",
            marginLeft: 22,
            background: "#1A1A1A",
            borderColor: "#1A1A1A",
            color: "white",
          }}
          disabled={loader}
          onClick={() => {
            setimgState("");
            props.onHide();
          }}
          id="dont_delete"
        >
          No
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default TasksApproveEditorModal;
